import React, {
    useState,
    useContext,
  } from 'react';
  import { Select, Checkbox, Radio } from "../../../../../../../styleguide";
import { SitesContext, FirstParentSettingsContext } from '../../../../../contexts';

const { Option } = Select;

const CategorySelector = ({ 
  siteId,
  updateSetting,
  settingKey,
  value,
  preferencesLists,
  components,
  path,
}) => {
  const { sites, isSiteGroup } = useContext(SitesContext);
  const siteList = isNaN(siteId) ? [] : [+siteId]; 
  const siteIds = isSiteGroup ? sites.map(({ id }) => id).toJS() : siteList;
  const activeConfiguration = preferencesLists?.filter(c => c.propertyIds.some(id => siteIds.includes(id)) && c.isActive)[0];
  const initialTyple = value.ids.length ? activeConfiguration?.categories.find(cat => Number(cat.categoryId) === value.ids[0]).type : 'MARKETING';
  const [categoryType, setCategoryType] = useState(initialTyple);

  function onHideChange(e) {
    const updatedValue = { ...value, hide: e.target.checked };
    updateSetting(settingKey, updatedValue);
  }

  function onCategoriesChange(cats) {
    if (typeof cats === 'string') { cats = [cats]; };
    const updatedValue = { ...value, ids: cats.map(Number) };
    updateSetting(settingKey, updatedValue);
  }

  function onCategoryTypeChange(type) {
    updateSetting(settingKey, { ...value, ids: [], hide: false });
    setCategoryType(type);
  }

  let usedCategories = [];

  function collectUsedCategories(component, componentPath) {
    if (JSON.stringify(componentPath) === JSON.stringify(path.map(entry => entry.index))) {
      return;
    }
    if (component.type === "PreferencesCenter") {
      usedCategories = [...usedCategories, ...component.settings.categories.ids];
    }
    if (Array.isArray(component.children)) {
      component.children.forEach((child, index) => {
        collectUsedCategories(child, [...componentPath, index]);
      });
    }
  }
  collectUsedCategories(components[0], [0]);

  const categories = activeConfiguration?.categories.filter(cat => !usedCategories.includes(Number(cat.categoryId)));

  return (
    <div>
      <Radio.Group value={categoryType} onChange={e => onCategoryTypeChange((e).target.value)} className="cat-type-select">
        <Radio value='MARKETING'>Marketing</Radio>
        <Radio value='LEGAL-TRANSACTION'>Legal</Radio>
      </Radio.Group>
      <div className="option-inputs">
        <Select
          style={{ width: '100%' }}
          value={categoryType === 'LEGAL-TRANSACTION' ? value.ids.map(String) : value.ids[0]?.toString() }
          onChange={onCategoriesChange}
          disabled={!categories.length}
          mode={categoryType === 'LEGAL-TRANSACTION' ? 'multiple' : 'default'}
          placeholder="Select"
        >
          {(categories.filter(({ type }) => type === categoryType).map((cat) => (
            <Option key={cat.categoryId} value={cat.categoryId}>
              {cat.name}
            </Option>
          )))}
        </Select>
      </div>
      <Checkbox className='cat-select-checkbox' onChange={onHideChange} checked={value.hide}>Hide category component</Checkbox>
    </div>
  )
}

export default CategorySelector;