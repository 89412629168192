import React from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { Select, Button } from '../../../../../styleguide'
import { styles } from '../styles';
import { StringValueKVGate } from '../../../../../components/dialogue/scenarios/versionTwo/components/scenario_records_2.js';
import { SCENARIO_STEPS } from '../../../scenarios/versionTwo/components/steps';

const { Option } = Select;

class PreferenceGate extends React.Component {
  onSelectFilter = (inputString, option) => {
    return (
      inputString.toLowerCase() ===
      option.props.children.toLowerCase().slice(0, inputString.length)
    );
  };

  componentWillMount() {
    const className = this.props.selectedCondition.get('className');
    const getSavedData = this.props.scenarioEdited
      .get('steps')
      .get(this.props.activeStep)
      .get('rowData')
      .filter((r) => r.get('className') == className);
    let data = List();
    if (getSavedData && getSavedData.size > 0 && this.props.isUpdate) {
      data = getSavedData.map((r, i) => {
        return Map({
          allow_on_satisfy: r.get('data').get('allow_on_satisfy'),
          type: r.get('data').get('type'),
          v: List(
            r.get('data').get('v')
              ? r
                  .get('data')
                  .get('v')
                  .split(',')
              : []
          ),
          k: r.get('data').get('k'),
        });
      });
    }
    this.setState({
      selectedOption: this.props.selectedOption,
      rows: getSavedData && data && data.size > 0 ? data : List([ new SCENARIO_STEPS[className].Record().data ])
    });
  }

  onAddEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    let rowData = this.props.scenarioEdited.steps.get(this.props.activeStep)
      .rowData;
    this.state.rows.map((row) => {
      stepBlockData = stepBlockData.set(
        'data',
        new StringValueKVGate({
          k: row.get('k'),
          v: row
            .get('v')
            .toJS()
            .toString(),
          type: row.get('type'),
          allow_on_satisfy: row.get('allow_on_satisfy'),
        })
      );
      rowData = rowData.unshift(stepBlockData);
    });
    this.props.onChange(this.props.activeStep, rowData);
  };

  onUpdateEvent = () => {
    const className = this.props.selectedCondition.get('className');
    let stepBlockData = new SCENARIO_STEPS[className].Record();
    let rowData = this.props.scenarioEdited.steps
      .get(this.props.activeStep)
      .rowData.filterNot((r) => r.className == className);
    this.state.rows.map((row) => {
      stepBlockData = stepBlockData.set(
        'data',
        new StringValueKVGate({
          k: row.get('k'),
          v: row
            .get('v')
            .toJS()
            .toString(),
          type: row.get('type'),
          allow_on_satisfy: row.get('allow_on_satisfy'),
        })
      );
      rowData = rowData.unshift(stepBlockData);
    });
    this.props.onChange(this.props.activeStep, rowData);
  };

  onSelectOption = (option, data) => {
    let rows = this.state.rows.setIn([0, 'allow_on_satisfy'],true);
    rows = rows.setIn([0, 'v'], List([data['data-value']]));
    rows = rows.setIn([0,'k'], `_sp_lt_${data['value']}`)
    this.setState({ rows, selectedOption: option });
  };

  cancelCondition = () => {
    this.props.closePanel();
  };

  updateRow = (index, sName, newVal) => {
    const rows = this.state.rows.setIn([index, sName], newVal);
    this.setState({ rows });
  };
  render() {
  const typeOptions = this.props.campaignTypeList?.map(obj => ({
    ...obj, 
    "value": true
  }));

    const options = typeOptions.map((o) => (
      <Option key={o.type} value={`${o.type}`} data-value={o.value}>
        {o.displayName}
      </Option>
    ));
    return (
      <div style={styles.scenarioConditionPanelTwo}>
        <div style={styles.scenarioInnerWrapper}>
          <span className="condition-label">Condition: </span>
          <span className="condition-title"> {this.props.conditionLabel}</span>
          <div id="condition-dropdown">
            <label>{`Value: `}</label>
            <Select
              placeholder="Please select"
              onChange={this.onSelectOption}
              filterOption={this.onSelectFilter}
              value={this.state.selectedOption}
              showArrow={true}
            >
              {options}
            </Select>
          </div>
        </div>
        <div style={styles.scenarioButtonWrapper}>
          <Button
            type="primary"
            size='small'
            onClick={
              this.props.buttontitle == 'Add'
                ? this.onAddEvent
                : this.onUpdateEvent
            }
          >
            {this.props.buttontitle}
          </Button>

          <Button
            style={styles.scenarioMarginLeft}
            onClick={this.cancelCondition}
            type="secondary"
            font="oswald"
            size='small'
            ghost
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (store) {
  return {
    campaignTypeList: store.preferencesState.getIn(['campaignTypeList', 'value']),
  };
};

export default connect(mapStateToProps, null)(PreferenceGate);
