import React, { useEffect, useState, useMemo } from "react";
import { Tree} from "../../../styleguide";
import {GEO_LIST_TREE_STRUCTURE, LEGACY_EU_COUNTRY_CODES } from "../../../constants";
import _ from "lodash";
import { findCommonElementsInTwoArrays, arraySubtraction } from "../../utils";
import { validateAndGetStateStringIcon } from "../../consent/us_privacy_regulation/helper";

const region_key_index_map = GEO_LIST_TREE_STRUCTURE.reduce((map, item) => {
  item.children.forEach(child => {
    if (!map[child.code]) {
      map[child.code] = [child.key];
    } else if (Array.isArray(map[child.code])) {
      map[child.code].push(child.key);
    } else {
      map[child.code] = [map[child.code], child.key];
    }
  });
  return map;
}, {});


const RegionSelectorTree = (props) => {
  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const isStateFramework = props.onlyUsStates;
  const REGIONS = GEO_LIST_TREE_STRUCTURE.filter((region) =>
    isStateFramework
      ? region.code == "US"
      : props.filterEEA
      ? region.code !== "EEA"
      : true
  );

  useEffect(()=>{
    let selKeys = [];
    props.selectedCodes.forEach( code => {
      const keys = region_key_index_map[code];
      if(keys) selKeys = [...new Set([...selKeys, ...keys])];
    })
    setSelectedKeys(selKeys);

    if(props.isIncludeExcludeStructure){
      //geos
      let geoScopesCopy = {
        appliesGeos :  [],
        exclusionGeos :  []
      }
      REGIONS.forEach( (option) => {
        const childCodes = option.children.map( c => c.code);
        const selectedChildCodes = findCommonElementsInTwoArrays(childCodes, props.selectedCodes);
        if(selectedChildCodes.length > (childCodes.length / 2)) {
          geoScopesCopy.appliesGeos = geoScopesCopy.appliesGeos.filter(geo => !selectedChildCodes.includes(geo))
          geoScopesCopy.appliesGeos.push(option.key)
          geoScopesCopy.exclusionGeos = [...new Set(geoScopesCopy.exclusionGeos.concat(arraySubtraction(childCodes,props.selectedCodes)))]
        } else {
          geoScopesCopy.appliesGeos = [...new Set(geoScopesCopy.appliesGeos.concat(selectedChildCodes))]
          geoScopesCopy.exclusionGeos = geoScopesCopy.exclusionGeos.filter(geo => !selectedChildCodes.includes(geo))
        }
      })
      props.setGeoScope(geoScopesCopy);
    } else {
      props.updatedCodes(props.selectedCodes)
    }
  },[props.selectedCodes]);

  useEffect(()=>{
    let selectedCodes = [];
    if(props.isIncludeExcludeStructure){
      //to support existing VLs with ALL_EU applies scope
      if(props.appliesGeos.includes("ALL_EU")){
        selectedCodes = LEGACY_EU_COUNTRY_CODES.filter( code => !props.exclusionGeos.includes(code));
      }

      REGIONS.forEach((option) => {
        if (props.appliesGeos.includes(option.key)) {
          const childCodes = option.children.map(c => c.code);
          const filteredChildCodes = childCodes.filter(code => !props.exclusionGeos.includes(code));
          selectedCodes = selectedCodes.concat(filteredChildCodes);
        } else {
          const childCodes = option.children.map(c => c.code);
          const filteredChildCodes = childCodes.filter(code => props.appliesGeos.includes(code) && !props.exclusionGeos.includes(code));
          selectedCodes = selectedCodes.concat(filteredChildCodes)
        }
      });

      //removed buggy logic - DIA-4925
    } else {
      if(props.codes.includes('CA')){
        //include canadian provinces
        let updatedCodesWithProvices = props.codes.filter(c => c !== 'CA')
        updatedCodesWithProvices = updatedCodesWithProvices.concat(REGIONS.find(o => o.code == 'CA')?.children.map(child => child.code))
        selectedCodes = updatedCodesWithProvices;
      } else {
        selectedCodes = props.codes;
      }
    }
    props.setSelectedCodes([...new Set([...selectedCodes])]);
  },[])

  let treeOptions = props.treeOptions ?? REGIONS;

  if(props.showStateString){
    treeOptions = useMemo(() => treeOptions.map((region) => {
      region.children = region.children.map((option) => {
          option.icon = validateAndGetStateStringIcon(option.code);
        return option;
      });
      return region;
    }),[props.treeOptions]);
  } else {
    treeOptions = useMemo(() => treeOptions.map((region) => {
      region.children = region.children.map((option) => {
        if(option.icon) delete option.icon
        return option;
      });
      return region;
    }),[props.treeOptions]);
  }

  return (
    <Tree
      treeOptions={treeOptions}
      selectedKeys={selectedKeys}
      selectedCodes={props.selectedCodes}
      setSelectedCodes={props.setSelectedCodes}
      isNested={true}
      all={"Select All " + props.entity}
    />
  );
}
// export default RegionSelectorTree;
export default React.memo(RegionSelectorTree, (prevProps, nextProps) => _.isEqual(prevProps.selectedCodes, nextProps.selectedCodes) && _.isEqual(prevProps.treeOptions, nextProps.treeOptions))
