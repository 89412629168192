import { MessageVariables } from "./records/message_records"
import { subcategoryMap } from "./constants"
import { getLanguageTypes } from "./components/v2/builder/Builder/Sidebar/Settings/SettingsMap"

export const log = (...args) => {
  process.env.NODE_ENV !== 'production' && console.log(...args)
}

export const accountHasPrivacyLens = (features) => {
  const privacyLensAccountFeatures = ['privacy_lens', 'privacy_lens_read_only', 'privacy_lens_scoring_only'];
  let hasPrivacyLens = false;
  features.every(f => {
    if (privacyLensAccountFeatures.includes(f)) {
      hasPrivacyLens = true;
      return false;
    }
    return true;
  });
  return hasPrivacyLens;
}

// Wraps functions in a try catch to consume errors
export const safeFunction = (callback) => (...args) => {
  try {
    return callback(...args)
  } catch (err) {
    log('error calling changelog function: ', err)
  }
}

export const replaceWithVariables = (components, variables) => {
  let stringifiedComponents = JSON.stringify(components);
  // Accounts for messages that don't contain variables
  variables = variables || new MessageVariables;

  const escapeJSONCharacters = (value) => {
    return value.replace(/\\/g, '\\\\') // Escape backslashes first
                .replace(/"/g, '\\"') // Escape double quotes
                .replace(/\n/g, '\\n') // Escape newlines
                .replace(/\r/g, '\\r') // Escape carriage returns
                .replace(/\t/g, '\\t'); // Escape tabs
  };

  variables.defaultVariables.forEach((defaultVariable) => {
    const escapedName = defaultVariable.get('name').replace(/\$\$/g, '\\$\\$');
    const escapedValue = escapeJSONCharacters(defaultVariable.get('value'));
    stringifiedComponents = stringifiedComponents.replace(new RegExp(escapedName, 'g'), escapedValue);
  });

  return JSON.parse(stringifiedComponents);
}

const matchAgainst = (child, requiredComponent) => {
  const keyPairs = Object.entries(requiredComponent);
  let match = true
  for (let i = 0; i < keyPairs.length; i++) {
    const [key, val] = keyPairs[i];
    if (typeof val === 'object') {
      match = matchAgainst(child[key], val);
    } else {
      if (val !== child[key]) {
        return false;
      }
    }
  }
  return match;
};

export const scanMessages = (message, components) => {
  return components.every(requiredComponent => {
    return matchAgainst(message, requiredComponent)
      || message.children.some(child => {
        return matchAgainst(child, requiredComponent)
          || scanMessages(child, [requiredComponent]);
      });
  });
};

export const checkForRequiredComponents = (messages, allRequiredComponents) => {
  return messages.every(message => {
    return allRequiredComponents[message.id]
      ? scanMessages(message, allRequiredComponents[message.id].map(msg => msg.schema))
      : true;
  });
}

export const getViolations = (
  component,
  targetTypes,
  rules,
  list = [],
  location = [0],
) => {
  component.children.forEach((c, i) => {
    getViolations(c, targetTypes, rules, list, [...location, i]);
  });

  if (targetTypes.includes(component.type)) {
    const violatedRules = rules.reduce((violatedRules, rule) => {
      const settingValue = component.settings[rule.field];
      const ruleValue = rule.value;

      const invalid = (typeof ruleValue === 'function')
        ? !ruleValue(settingValue)
        : ruleValue !== settingValue;

      if (invalid) {
        violatedRules.push({
          field: rule.field,
          expected: ruleValue,
          received: settingValue,
          msg: rule.message,
        });
      };
      return violatedRules;
    }, []);

    if (violatedRules.length) {
      list.push({
        name: component.name,
        type: component.type,
        violatedRules,
        location
      });
    };
  };

  return list;
}

export const clearStartFocus = (item) => {
  let toggledOff = false;
  helper(item);

  function helper(item) {
    if (item.settings && item.settings.startFocus === true) {
      item.settings.startFocus = false;
      toggledOff = true;

      return;
    } else {
      item.children.forEach(helper);
    }
  }

  return toggledOff;
};

export function getSubCategory(subCategoryId, categoryId) {
  if (categoryId === 6) return 'us_pm';
  if (categoryId === 7) return 'preferences-app';
  return Object.keys(subcategoryMap).find(
    (key) => subcategoryMap[key] === subCategoryId
  );
}

export const checkSupportedLanguages = (supportedLanguages, components) => {
  // XXX
  return [];
  // const languageTypes = getLanguageTypes()
  // let actualLanguages = supportedLanguages ? [...supportedLanguages] : []
  // if (actualLanguages.includes('EN')) actualLanguages.splice(actualLanguages.indexOf('EN'), 1)
  // if (actualLanguages.length <= 0) return []

  // /* VALIDATE THAT EACH COMPONENT HAS EACH LANGUAGE */
  // let missing = []
  // const validateLang = (data, uniqueId) => {
  //   const hasLanguages = languageTypes[data.type] && languageTypes[data.type].length > 0
  //   if (hasLanguages) {
  //     const checkTypes = languageTypes[data.type]
  //     const checkTitles = languageTypes[data.type + 'Titles']
  //     actualLanguages.forEach(l => {
  //       checkTypes.forEach(c => {
  //         if (!(data.settings && data.settings.languages && data.settings.languages[l] && data.settings.languages[l][c])) {
  //           missing.push({ id: uniqueId, name: data.name, type: data.type, language: l, setting: c, title: checkTitles[c] })
  //         }
  //       })
  //     })
  //   }
  //   if (data.children && data.children.length > 0) data.children.forEach((c, i) => validateLang(c, uniqueId + i.toString()))
  // }
  // validateLang(components, '0')
  // return missing || []
}

export const filterElements = (subcategory) => (elements) => {
  return subcategory === 'native-ott' ? null : elements
}

export const includesComponent = (typeToFind, component) => {
  return (component.type === typeToFind) || component.children.some(child => includesComponent(typeToFind, child));
};

// Function to remove duplicates from Immutable List based on the [key] property
export const removeDuplicates = (list, key) => {
  const seenIds = new Set();
  return list.filter(item => {
    const id = item[key];
    if (!seenIds.has(id)) {
      seenIds.add(id);
      return true;
    }
    return false;
  });
};

export const getPortalOriginBasedOnEnv = () => {
  let targetOrigin = null;
  if(process.env.REACT_APP_PUB_PORTAL_IS_PREPROD) {
    targetOrigin = "https://preprod-portal.sourcepoint.com"
  } else if(process.env.REACT_APP_PUB_PORTAL_ENV === "prod") {
    targetOrigin = "https://portal.sourcepoint.com"
  } else if(process.env.REACT_APP_PUB_PORTAL_ENV === "stage") {
    targetOrigin = "https://portal.sp-stage.net"
  }
  return targetOrigin;
}
