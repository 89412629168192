import { List} from 'immutable';
import { checkStatus, isFetched, addToFetched } from './helper.js';
import { FETCHED } from '../constants.js';
import { RegulationListError } from '../records/us_privacy_regulation_records.js';
import { VendorConnection, SystemVendor, Configuration, ComfigurationError, VendorConnectionError } from '../records/preferences_records.js';

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const getBaseUrl = () => {
    return `${permissionsSvcBaseUrl}/preferences/`;
};

async function checkVendorConnectionError(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    const errors = await response.json();
    throw  new VendorConnectionError(errors);
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

async function checkPreferenceConfigError(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    const errors = await response.json();
    throw new ComfigurationError(errors);
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}


export function getAllVendorConnectionsAjax() {
  const url = 'vendor-connection/all';
  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  })
    .then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map(s => new VendorConnection(s)));
    });
}


export function createPreferenceConfigurationAjax(prefConfigRecord) {

  const prefConfigObj = generatePreferenceConfigurationObj(prefConfigRecord, false);

  return fetch(getBaseUrl() + 'configuration', {
    method: 'POST',
    body: JSON.stringify(prefConfigObj),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkPreferenceConfigError)
    .then(resp => resp.json())
    .then(resp => {
      return new Configuration(resp);
    });
}

export function updatePreferenceConfigurationAjax(prefConfigRecord) {

  const prefConfigObj = generatePreferenceConfigurationObj(prefConfigRecord, true);

  return fetch(getBaseUrl() + 'configuration', {
    method: 'PUT',
    body: JSON.stringify(prefConfigObj),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkPreferenceConfigError)
    .then(resp => resp.json())
    .then(resp => {
      return new Configuration(resp);
    });
}

export function activateDeactivatePreferenceConfigurationAjax(prefConfig) {
  const updatedPrefConfig = {...prefConfig, isActive: !prefConfig.isActive}; //here prefConfig is a plain JS object as we are using the general RegulationLists component
  return updatePreferenceConfigurationAjax(updatedPrefConfig)
}

export function clonePreferenceConfigurationAjax(accountId, prefConfgId, cloneName) {
  return fetch(getBaseUrl() + `configuration/clone?accountId=${accountId}&configurationId=${prefConfgId}`, {
    method: 'POST',
    body: JSON.stringify({name : cloneName}),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkPreferenceConfigError)
    .then(resp => resp.json())
    .then(resp => {
      return new Configuration(resp);
    });
}

export function getPreferenceConfigurationAjax(prefConfigId, accountId) {
  const url = `configuration?configurationId=${prefConfigId}&accountId=${accountId}`;

  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      "Content-Type": 'application/json',
    },
    method: 'GET',
  })
    .then(checkPreferenceConfigError)
    .then(resp => resp.json())
    .then(resp => {
      return new Configuration(resp);
    })
}


export function getAllPreferenceConfigurationAjax(accountId) {
  const url = `configuration/all?accountId=${accountId}`;

  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      "Content-Type": 'application/json',
    },
    method: 'GET',
  })
    .then(checkPreferenceConfigError)
    .then(resp => resp.json())
    .then(resp => {
      return List(resp.map ( prefConfig => new Configuration(prefConfig)));
    })
}

export function deletePreferenceConfigurationAjax(accountId, prefConfigId) {
  const url = `configuration?configurationId=${prefConfigId}&accountId=${accountId}`;

  return fetch(getBaseUrl() + url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      "Content-Type": 'application/json',
    },
    method: 'DELETE',
  })
    .then(checkPreferenceConfigError)
    .then(resp => resp.json())
    .then(resp => {
      return new Configuration(resp);
    })
}

export function getAllVendorsAjax() {
  const url = 'vendor/all';
  if (isFetched(url)) {
    return Promise.resolve(FETCHED);
  } else {
    return fetch(getBaseUrl() + url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then(checkStatus)
      .then(resp => resp.json())
      .then(resp => {
        addToFetched(url);
        return List(resp.map(s => new SystemVendor(s)));
      });
  }
}
 
 
export function createVendorConnectionAjax(data) {
  return fetch(getBaseUrl() + 'vendor-connection', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      const updatedResp = Object.assign({}, resp);
      return new VendorConnection(updatedResp);
    });
}

export function updateVendorConnectionAjax(data) {
  return fetch(getBaseUrl() + 'vendor-connection', {
    method: 'PUT',
    body: JSON.stringify(data),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      const updatedResp = Object.assign({}, resp);
      return new VendorConnection(updatedResp);
    });
}
 
export function deleteVendorConnectionAjax(accountId, id) {
  return fetch(getBaseUrl() + `vendor-connection?accountId=${accountId}&connectionId=${id}`, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      return new VendorConnection(resp);
    });
}

export function testVendorConnection(vendorConnection) {
  return fetch(getBaseUrl() + 'vendor-connection/test', {
    method: 'POST',
    body: JSON.stringify(vendorConnection),
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })//.then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      return Object.assign({}, resp);
    });
}

export function redirectForOAuth(vendorId, accountId, clientId) {
  return fetch(getBaseUrl() + `vendor/oauth/${vendorId}/authorization-url?accountId=${accountId}&clientId=${clientId}`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      return Object.assign({}, resp);
    });
}

export function saveOauthCodeWithVendorConnectionId(vendorId, accountId, code, vendorConnectionId) {
  const url = `${getBaseUrl()}vendor/oauth/${vendorId}?accountId=${accountId}&code=${code}&vendorConnectionId=${vendorConnectionId}`;

  return fetch(url, {
    credentials: 'include',
    mode: 'cors',
    headers: {
      "Content-Type": 'application/json',
    },
    method: 'GET',
  }).then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      return Object.assign({}, resp);
    });
}


export function getVendorConnectionCategories(connectionId) {
  return fetch(getBaseUrl() + 'vendor-connection/subscription-groups/' + connectionId, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkVendorConnectionError)
    .then(resp => resp.json())
    .then(resp => {
      return resp;
    })
}


export function getPreferenceCampaignTypesAjax() {
  const url = `${getBaseUrl()}configuration/categories/sub-types`;
  return fetch(url,
      {
          method: 'GET',
          credentials: 'include',
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json',
          },
      },
  )
  .then(checkStatus)
  .then((resp) => resp.json())
}

export function getAllLegalDocuments() {
  return fetch(getBaseUrl() + "docs", {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return resp;
    })
}

export function getAllLatestLegalDocumentsAjax() {
  return fetch(getBaseUrl() + "docs/latest", {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkStatus)
    .then(resp => resp.json())
    .then(resp => {
      return resp;
    })
}

export function downloadLegalDocument(documentId) {
  const url = `${getBaseUrl()}docs/${documentId}/download`;
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => {
    if (resp.status === 200) {
      return resp.blob(); // Convert response to Blob
    } else {
      return resp.json().then((error) => {
        throw new Error(error.message || 'Failed to download document');
      });
    }
  });
}

export function postLegalDocument(document) {
  debugger;
  return fetch(getBaseUrl() + "docs", {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    body: document
  }).then((resp) => {
    if(resp.status === 200) {
      return resp;
    } else {
      throw new Error(resp);
    }
  }).then(resp => resp.json())
    .then(resp => {
      debugger;
      return resp;
    })
}

export function updateLegalDocumentVersion(document) {
  debugger;
  const url = `${getBaseUrl()}docs/${document.get("documentName")}/version`;
  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    body: document
  }).then((resp) => {
    if(resp.status === 200) {
      return resp;
    } else {
      throw new Error(resp);
    }
  }).then(resp => resp.json())
    .then(resp => {
      debugger;
      return resp;
    })
}

export function updateLegalDocumentValues(docObject, documentId) {
  debugger;
  const url = `${getBaseUrl()}docs/${documentId}`;
  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify(docObject),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => {
    if(resp.status === 200) {
      return resp;
    } else {
      throw new Error(resp);
    }
  }).then(resp => resp.json())
    .then(resp => {
      debugger;
      return resp;
    })
}

export function updateLegalDocumentName(docObject, orgDocName) {
  debugger;
  const url = `${getBaseUrl()}docs/${orgDocName}/edit`;
  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify(docObject),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => {
    if(resp.status === 200) {
      return resp;
    } else {
      throw new Error(resp);
    }
  }).then(resp => resp.json())
    .then(resp => {
      debugger;
      return resp;
    })
}

function generatePreferenceConfigurationObj(prefConfigRecord, isEditFlow) {
  let updatedPrefConfigRecord = null;
  if(prefConfigRecord instanceof Configuration){
    updatedPrefConfigRecord = prefConfigRecord
  } else {
    updatedPrefConfigRecord = new Configuration(prefConfigRecord)
  }

  let nextCategoryIndex = updatedPrefConfigRecord.get('nextCategoryIndex');

  if(isEditFlow){
    const configurationId = updatedPrefConfigRecord.get('id');
    if(configurationId){
      updatedPrefConfigRecord = updatedPrefConfigRecord.set('configurationId', configurationId);
    }
  } else {
    updatedPrefConfigRecord = updatedPrefConfigRecord.removeFields(['configurationId']);
  }

  updatedPrefConfigRecord = updatedPrefConfigRecord.removeFields(["nextCategoryIndex", "id", "siteIds", "storageGeo", "dateCreated", "dateUpdated", "dateDeleted", "version", "isLatestVersion"]);

  updatedPrefConfigRecord = updatedPrefConfigRecord.update('categories', (categories) => {
    return categories.map((category, index) => {
      const catId = category.get('categoryId')?.toString();
      let updatedCategory = category;
      if(isEditFlow){
        if(catId?.indexOf('temp_id_') === 0) {
          updatedCategory = category.set('categoryId', nextCategoryIndex++)
        } else {
          updatedCategory = category.set('categoryId', Number(catId));
        }
      } else {
        updatedCategory = category.delete('categoryId')
      }
      if(category.get("subType") === "none") {
        updatedCategory = updatedCategory.set("subType", undefined);
      }
      updatedCategory = updatedCategory.update('vendorConnection', (vendorConnection) => {
        return vendorConnection.map(venConn => {
          let updatedVenConn = venConn.set("connectionRef", venConn.get("id"))
          updatedVenConn = updatedVenConn.delete("id").delete("name");
          return updatedVenConn;
        })
      })
      
      // updatedCategory = removeNullFields(updatedCategory);
      return updatedCategory?.toJS();
    })
  })

  return updatedPrefConfigRecord?.toJS()
}
