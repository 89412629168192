import { Record, List, fromJS } from 'immutable';

import SessionSearchReferrerGate from './SessionSearchReferrerGate';
import SessionSocialReferrerGate from './SessionSocialReferrerGate';
import SearchReferrerGate from './SearchReferrerGate';
import SocialReferrerGate from './SocialReferrerGate';
import CustomCompGate from './CustomCompGate';
import OptInRecoveryGate from './OptInRecoveryGate';
import Countries from './Countries';
import USStates from './USStates';
import ConsentGate from './ConsentGate';
import ConsentGateV2 from './ConsentGateV2';
import ConsentGateCCPA from './ConsentGateCCPA';
import ConsentGateUSNAT from './ConsentGateUSNAT';
import ConsentGatePreferences from './ConsentGatePreferences';
import AdblockGate from './AdblockGate';
import DesktopGate from './DesktopGate';
import AltpayGate from './AltpayGate';
import SambaGate from './SambaGate';
import WelectGate from './WelectGate';
import ClientIPGate from './ClientIPGate';
import RegexMatchGate from './RegexMatchGate';
import MatchIntRange from './MatchIntRange';
import MatchStringValue from './MatchStringValue';

import ShowMessageOnce from './ShowMessageOnce';
import ShowMessageAlways from './ShowMessageAlways';
import MessageFreqCap from './MessageFreqCap';
import MessageShowNumTimes from './MessageShowNumTimes';
import MessageSessionNumLimit from './MessageSessionNumLimit';

import DelayNumPageviews from './DelayNumPageviews';
import DelayCircularRepeat from './DelayCircularRepeat';
import DelaySeconds from './DelaySeconds';
import { Scenario } from './scenario_records_2';
import { assembleAllRows } from '../parseAndSerializeRows.js';

import {
  CountriesRecord,
  USStatesRecord,
  ConsentGateRecord,
  ConsentGateRecordV2,
  ConsentGateRecordCCPA,
  ConsentGateRecordUSNAT,
  ConsentGateRecordPreferences,
  AdblockGateRecord,
  DesktopGateRecord,
  AltpayGateRecord,
  SambaGateRecord,
  WelectGateRecord,
  SessionSearchReferrerGateRecord,
  SessionSocialReferrerGateRecord,
  SearchReferrerGateRecord,
  SocialReferrerGateRecord,
  CustomCompGateRecord,
  OptInRecoveryGateRecord,
  ShowMessageAlwaysRecord,
  ShowMessageOnceRecord,
  MessageFreqCapRecord,
  MessageShowNumTimesRecord,
  MessageSessionNumLimitRecord,
  DelayNumPageviewsRecord,
  DelayCircularRepeatRecord,
  DelaySecondsRecord,
  ClientIPGateRecord,
  RegexMatchGateRecord,
  IntRangeKVGateRecord,
  matchStringValueRecordClassFactory,
  matchStringValueRecordDefaults,
  systemKeyVals,
  StringValueKVRecord,
} from '../../../../../scenario_constants';


export const COUNTRIES = {
  label: 'Country',
  component: Countries,
  Record: CountriesRecord,
};

export const STATES = {
  label: 'US State',
  component: USStates,
  Record: USStatesRecord,
};

export const CONSENT_GATE = {
  label: 'Consent Gate',
  component: ConsentGate,
  Record: ConsentGateRecord,
};

export const CONSENT_GATE_V2 = {
  label: 'Consent Gate (TCF v2)',
  component: ConsentGateV2,
  Record: ConsentGateRecordV2,
};

export const CONSENT_GATE_CCPA = {
  label: 'Consent Gate (U.S.Privacy)',
  component: ConsentGateCCPA,
  Record: ConsentGateRecordCCPA,
};

export const CONSENT_GATE_USNAT = {
  label: 'Consent Gate U.S. MultiState Privacy',
  component: ConsentGateUSNAT,
  Record: ConsentGateRecordUSNAT,
};

export const CONSENT_GATE_PREFERENCES = {
  label: 'Consent Gate (Universal Consent and Preferences)',
  component: ConsentGatePreferences,
  Record: ConsentGateRecordPreferences,
};

export const ADBLOCK_GATE = {
  label: 'Adblock',
  component: AdblockGate,
  Record: AdblockGateRecord,
};

export const DESKTOP_GATE = {
  label: 'Device',
  component: DesktopGate,
  Record: DesktopGateRecord,
};

export const ALTPAY_GATE = {
  label: 'Altpay',
  component: AltpayGate,
  Record: AltpayGateRecord,
};

export const SAMBA_GATE = {
  label: 'Samba',
  component: SambaGate,
  Record: SambaGateRecord,
};

export const WELECT_GATE = {
  label: 'Welect',
  component: WelectGate,
  Record: WelectGateRecord,
};

export const SESSION_SEARCH_REFERRER_GATE = {
  label: 'Session Search Referrer Gate',
  component: SessionSearchReferrerGate,
  Record: SessionSearchReferrerGateRecord,
};

export const SESSION_SOCIAL_REFERRER_GATE = {
  label: 'Session Social Referrer Gate',
  component: SessionSocialReferrerGate,
  Record: SessionSocialReferrerGateRecord,
};

export const SEARCH_REFERRER_GATE = {
  label: 'Search Referrer Gate',
  component: SearchReferrerGate,
  Record: SearchReferrerGateRecord,
};

export const SOCIAL_REFERRER_GATE = {
  label: 'Social Referrer Gate',
  component: SocialReferrerGate,
  Record: SocialReferrerGateRecord,
};

export const CUSTOM_COMP_GATE = {
  label: 'Custom Comp Gate',
  component: CustomCompGate,
  Record: CustomCompGateRecord,
};

export const OPT_IN_RECOVERY_GATE = {
  label: 'Opt In Recovery Gate',
  component: OptInRecoveryGate,
  Record: OptInRecoveryGateRecord,
};

export const MESSAGE_ALWAYS = {
  label: 'Show Message Always',
  component: ShowMessageAlways,
  Record: ShowMessageAlwaysRecord,
};

export const MESSAGE_ONCE = {
  label: 'Show Message Once',
  component: ShowMessageOnce,
  Record: ShowMessageOnceRecord,
};

export const MESSAGE_FREQ_CAP = {
  label: 'Message Frequency Cap',
  component: MessageFreqCap,
  Record: MessageFreqCapRecord,
};

export const MESSAGE_SHOW_NUM_TIMES = {
  label: 'Show Message Num Times',
  component: MessageShowNumTimes,
  Record: MessageShowNumTimesRecord,
};

export const MESSAGE_SESSION_NUM_LIMIT = {
  label: 'Session Message Number Limit',
  component: MessageSessionNumLimit,
  Record: MessageSessionNumLimitRecord,
};

export const DELAY_NUM = {
  label: 'Number of Pageviews Delay',
  component: DelayNumPageviews,
  Record: DelayNumPageviewsRecord,
};

export const DELAY_CIRCULAR_REPEAT = {
  label: 'Number of Times Repeat',
  component: DelayCircularRepeat,
  Record: DelayCircularRepeatRecord,
};

export const DELAY_TIME = {
  label: 'Time Delay',
  component: DelaySeconds,
  Record: DelaySecondsRecord,
};

export const CLIENT_IP_GATE = {
  label: 'Client IP List',
  component: ClientIPGate,
  Record: ClientIPGateRecord,
};

export const URL_REGEX_MATCH = {
  label: 'Page URL Regex Match',
  component: RegexMatchGate,
  Record: RegexMatchGateRecord,
};

export const INT_RANGE_KV_GATE = {
  label: 'Integer In Range',
  component: MatchIntRange,
  Record: IntRangeKVGateRecord,
};


export const STRING_MATCH_KV_GATE = {
  label: 'String Match',
  component: MatchStringValue,
  Record: matchStringValueRecordClassFactory(matchStringValueRecordDefaults),
};

export const SCENARIO_STEPS = {
  'com.sourcepoint.msgsvc.scenario.steps.GeoGate': COUNTRIES,
  'com.sourcepoint.msgsvc.scenario.steps.USStateGate': STATES,
  'com.sourcepoint.msgsvc.scenario.steps.IsAdblockedGate': ADBLOCK_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.DesktopUserAgentGate': DESKTOP_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.AltPayCompGate': ALTPAY_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SambaCompGate': SAMBA_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.WelectCompGate': WELECT_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGate': CONSENT_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateV2': CONSENT_GATE_V2,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateCCPA': CONSENT_GATE_CCPA,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGateUSNAT': CONSENT_GATE_USNAT,
  'com.sourcepoint.msgsvc.scenario.steps.ConsentGatePreferences': CONSENT_GATE_PREFERENCES,
  'com.sourcepoint.msgsvc.scenario.steps.SessionSearchReferrerGate': SESSION_SEARCH_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SessionSocialReferrerGate': SESSION_SOCIAL_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SearchReferrerGate': SEARCH_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.SocialReferrerGate': SOCIAL_REFERRER_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.CustomCompGate': CUSTOM_COMP_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ChoiceOptionOIRGate': OPT_IN_RECOVERY_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ClientIPGate': CLIENT_IP_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ReferrerRegexMatch': URL_REGEX_MATCH,
  'com.sourcepoint.msgsvc.scenario.steps.IntRangeKVGate': INT_RANGE_KV_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate': STRING_MATCH_KV_GATE,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageAlways': MESSAGE_ALWAYS,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageOnce': MESSAGE_ONCE,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageNTimes': MESSAGE_SHOW_NUM_TIMES,
  'com.sourcepoint.msgsvc.scenario.steps.ShowMessageFreqCap': MESSAGE_FREQ_CAP,
  'com.sourcepoint.msgsvc.scenario.steps.SessionMessageNLimit': MESSAGE_SESSION_NUM_LIMIT,
  'com.sourcepoint.msgsvc.scenario.steps.DelayNPageviews': DELAY_NUM,
  'com.sourcepoint.msgsvc.scenario.steps.ModNDelay': DELAY_CIRCULAR_REPEAT,
  'com.sourcepoint.msgsvc.scenario.steps.TimeDelay': DELAY_TIME
};



export class ScenarioRowRecord extends Record({
  rowData: List([]),
}) {
  constructor(row) {
    let steps = List([]);
    try {
      if (row) {
        steps = fromJS(row.map(step => {
          const Record = SCENARIO_STEPS[getScenarioStepClass(step)].Record;
          return new Record(step);
        }));
      }
    }
    catch (error) {
      console.log('Error assembling scenario rows: ',error);
    }

    super({
      rowData: steps,
    });
  }
}

export class ScenarioV2 extends Scenario {
  constructor(scenario){
    let rows = List([]);
    if (scenario.scenario_json) {
      const steps = JSON.parse(scenario.scenario_json).steps;
      const parsedSteps = assembleAllRows(steps);
      rows = fromJS(parsedSteps.map(row => {
        return new ScenarioRowRecord(row);
      }));
    }

    super(Object.assign({ schema_version: 2, steps: rows}, scenario));
  }
}

/** Translate mms classes to our pseudoclasses */
function getScenarioStepClass(step) {
  if (step.class === 'com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate') {
    let key = step.data.k;
    if (systemKeyVals[key]) {
      return `${step.class}:${key}`;
    }
    if(key.includes('_sp_lt_')){
      return `${step.class}:${'sp_lt_'}`;
    }
  }

  return step.class;
}


// For every system key val, create a scenario step where the user chooses from our options
Object.entries(systemKeyVals).forEach(([key, { label }]) => {
  let className = `com.sourcepoint.msgsvc.scenario.steps.StringValueKVGate:${key}`;
  SCENARIO_STEPS[className] = {
    label: label,
    component: MatchStringValue,
    Record: matchStringValueRecordClassFactory({
      ...matchStringValueRecordDefaults,
      data: StringValueKVRecord({k: key}),
      className,
    }),
  }
});

export const LIST_OF_STEP_TYPES = fromJS(Object.keys(SCENARIO_STEPS));