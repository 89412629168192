import {
  Alert,
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import CSVLink from "../../common/CSVLink.js";
import { List } from "immutable";
import { uploadBulkScanCsv } from "../../../api/diagnose_dashboard/scan_setup.js";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { Text } = Typography;

const exampleData = [
  [
    "Property (Display Name)",
    "Sourcepoint account ID",
    "Scan URL",
    "Region",
    "Consent Types",
    "Frequency(weekly/monthly)",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
  [
    "example.com",
    "22",
    "nowtv.com/home",
    "EEA:UK;EEA:DE;US:CA;US:US",
    "accept;reject",
    "weekly",
  ],
];

const requiredFields = [
  "Property (Display Name)",
  "Sourcepoint account ID",
  "Scan URL",
  "Region",
  "Consent Types",
  "Frequency(weekly/monthly)",
];

function UploadCsvFile({
  accountId,
  uploadCsvModalType,
  onClose,
  refreshBulkAccountScans,
}) {
  const [errors, setErrors] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const validateCsvContent = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv = e.target.result;
        const rows = csv.split(/\r\n|\n/);
        const header = rows[0].split(",");

        const errors = {
          "Property (Display Name)": 0,
          "Sourcepoint Account ID": 0,
          "Scan URL": 0,
          Region: 0,
          "Consent Types": 0,
          "Frequency(weekly/monthly)": 0,
        };

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].split(",");

          requiredFields.forEach((field, index) => {
            if (!row[index]) {
              errors[field] += 1;
            }
          });
        }

        const errorMessage = [];
        if (errors["Property/Website Name"] > 0) {
          errorMessage.push(
            `${errors["Property/Website Name"]} Property/Website Name is missing.`
          );
        }
        if (errors["Sourcepoint Account ID"] > 0) {
          errorMessage.push(
            `${errors["Sourcepoint Account ID"]} Sourcepoint Account ID is missing.`
          );
        }
        if (errors["Scan URL"] > 0) {
          errorMessage.push(
            `${errors["Scan URL"]} Scan URLs are missing, or please verify that the URLs are in the correct format.`
          );
        }
        resolve(errorMessage);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUploadCsv = async (file) => {
    const validationErrors = await validateCsvContent(file);
    setErrors(validationErrors);
    setUploadedFile(file);
    return false;
  };

  const handleSave = async () => {
    if (!uploadedFile) {
      message.error("Please upload a CSV file before saving.");
      return;
    }

    if (errors.length > 0) {
      message.error("Please fix the errors in your file and re-upload.");
      return;
    }

    try {
      setIsUploading(true);
      const response = await uploadBulkScanCsv(uploadedFile);
      message.success("File uploaded successfully!");
      refreshBulkAccountScans();
      onClose();
    } catch (error) {
      console.error("Upload failed:", error);
      message.error("Failed to upload the file.");
    } finally {
      setIsUploading(false);
    }
  };

  const uploadProps = {
    name: "file",
    accept: ".csv",
    maxCount: 1,
    beforeUpload: beforeUploadCsv,
  };

  return (
    <Modal
      title="Upload CSV File"
      open={!!uploadCsvModalType}
      okText="Save"
      onOk={handleSave}
      onCancel={onClose}
      confirmLoading={isUploading}
      okButtonProps={{
        disabled: errors.length > 0,
      }}
    >
      <div className="ant-modal-uploadcsv">
        <Row>
          <Col>
            <span>Import File</span>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="drop-files">Drop file here or Click to Upload</p>
            <span>Download a </span>
            <CSVLink
              data={exampleData}
              enclosingCharacter={``}
              filename={new Date() + " data.csv"}
              onClick={(e) => e.stopPropagation()}
              isSanitized={true}
            >
              Sample CSV Template
            </CSVLink>{" "}
            to see an example of the format required.
          </Dragger>
        </Row>
        <div style={{ marginTop: "20px" }}></div>
        {errors.length > 0 && (
          <div style={{ marginTop: "50px" }}>
            <Alert
              message={
                <Row>
                  <Col span={24}>
                    <strong>{uploadedFile?.name}: Missing Data</strong>
                  </Col>
                  {/* <ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul> */}
                  <Col span={24}>
                    <Text>
                      <strong>Note:</strong> Please fill out all missing data
                      and re-upload the file.
                    </Text>
                  </Col>
                </Row>
              }
              type="error"
              showIcon
              closable
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UploadCsvFile;
