import React from 'react';
import { MenuTitle, MenuItem, MenuDropdown } from './Components/NavigationComponents';
import { getParameterByName } from "../utils";

export default class DialogueSubnav extends React.Component {
  hasFeature = (feature) => {
    // if (this.props.currentUser.accountId === 22 && feature !== 'recovery') return true;
    return this.props.currentUser.accountFeatures.includes(feature);
  }

  isActive = (path, paths) => {
    const pathName = this.props.location?.pathname;
    if (path) return path.includes(pathName);
    if (paths) {
      let hasPath = false;
      for (let i = 0; i < paths.length; i++) {
        if (paths[i] == pathName) return true;
      }
    }
    return false;
  }
  redirectToSite = (site_id) => {
    let queryParams = `?site_id=${site_id}`;
    if (site_id === +this.props.siteId && this.props.siteGroupId) {
      queryParams += `&site_group_id=${this.props.siteGroupId}`
    }
    else if (this.props.siteGroupId) {
      queryParams += `&sitegroup_id=${this.props.siteGroupId}`;
    }
    return queryParams;
  }
  render() {
    const propertySiteId = +getParameterByName("site_id", window.location);
    const siteIds = this.redirectToSite(propertySiteId)
    const { menuHidden, menuOpen, propertyType, currentUser } = this.props;
    const hasUSNATAccessMenu =  this.hasFeature('usnat');
    const hasFeatureAccess = this.hasFeature('tcf_v2') || this.hasFeature('non_iab_vl') || this.hasFeature('ccpa') || this.hasFeature('adblock') || this.hasFeature('adblock_v2') || hasUSNATAccessMenu;
    const hasAppleMessagingAccess = this.hasFeature('apple_messaging')
    const showiOSTracking = propertyType === 'app' || !propertyType;
    const showAdblock = propertyType === 'web' || !propertyType;
    const hasGDPRAccessMenu =  this.hasFeature('tcf_v2') || this.hasFeature('non_iab_vl') || this.hasFeature('privacy_manager_ott') || this.hasFeature('native_messaging') || this.hasFeature('native_ott')
    const hasCCPAccessMenu =  this.hasFeature('ccpa') || this.hasFeature('ccpa_ott') || this.hasFeature('native_ott_ccpa') || this.hasFeature('native_messaging')
    const hasCampaignEntitiesAccess = hasFeatureAccess && ((currentUser.featureAccess && currentUser.featureAccess.includes('Campaign Entities')) || currentUser.allFeatureAccess)
    const hasCustomMessages = this.hasFeature('custom_messages')
    const hasPreferencesAccess = this.hasFeature('preferences');

    return (hasCampaignEntitiesAccess &&
      <div className={`spt-dialogue ${menuOpen ? 'open' : ''} ${menuHidden ? 'closed' : ''}`}>
        {/*        <MenuItem name="Properties" to="/dialogue/sites?tab=properties" />
        <MenuItem name="Property Groups" to="/dialogue/sites?tab=groups" />*/}
        <MenuItem name="Campaigns" to={'/dialogue/campaigns_v2' + siteIds} active={this.isActive('/dialogue/campaigns_v2')} />
        <MenuItem name="Partition Sets" to={'/dialogue/partitions' + siteIds} active={this.isActive('/dialogue/partitions')} />
        <MenuItem
          name="Scenarios"
          to={"/dialogue/scenariosv3" + siteIds}
          active={this.isActive(null, ['/dialogue/scenariosv3', '/dialogue/scenarios'])}
        />
        <MenuDropdown
          name="Messages"
          className="dialogue-drop"
          noParent
          active={this.isActive(null, [
            '/dialogue/messages/tcfv2',
            '/dialogue/messagesV2',
            '/dialogue/privacy-manager/ott',
            '/dialogue/native-message-gdpr',
            '/dialogue/native-ott'
          ])}
        >
          {hasGDPRAccessMenu && (
            <MenuDropdown name="GDPR Messages" className="dialogue-drop">
              {this.hasFeature("tcf_v2") && <MenuItem name="Web / Webview (TCF)" to={'/dialogue/messages/tcfv2' + siteIds} />}
              {this.hasFeature("non_iab_vl") && <MenuItem name="Web / Webview (Standard)" to={'/dialogue/messages/non-tcf' + siteIds} />}
              {this.hasFeature("native_ott") && <MenuItem name="OTT" to={'/dialogue/native-ott' + siteIds} />}
              {this.hasFeature("privacy_manager_ott") && <MenuItem name="Legacy OTT" to={'/dialogue/privacy-manager/ott' + siteIds} />}
              {this.hasFeature("native_messaging") && <MenuItem name="Native App" to={'/dialogue/native-message-gdpr' + siteIds} />}
            </MenuDropdown>
          )}
          {hasCCPAccessMenu && (
            <MenuDropdown name=" U.S. Privacy Messages (Legacy)" className="dialogue-drop">
              <MenuItem name="Web / Webview" to={'/dialogue/messages/us_privacy' + siteIds} />
              {this.hasFeature("ccpa_ott") && <MenuItem name="Legacy OTT" to={'/dialogue/us-privacy-ott' + siteIds} />}
              {this.hasFeature("native_ott_ccpa") && <MenuItem name="OTT" to={'/dialogue/native-ott-us-privacy' + siteIds} />}
              {this.hasFeature("native_messaging") && <MenuItem name="Native Message" to={'/dialogue/native-message-us-privacy' + siteIds} />}
              {this.hasFeature("sar") && <MenuItem name="DSAR Retrieval Form" to={'/dialogue/dsar-retrieval' + siteIds} />}
              {this.hasFeature("sar") && <MenuItem name="SAR Form" to={'/dialogue/sar' + siteIds} />}
              {this.hasFeature("ccpa") && <MenuItem name="Legacy Privacy Manager" to={'/us_privacy/privacy_manager'} />}
            </MenuDropdown>
          )}
          {hasUSNATAccessMenu && (
            <MenuDropdown name=" U.S. Multi-State Privacy Messages" className="dialogue-drop">
              <MenuItem name="Web / Webview" to={'/dialogue/messages/usnat_fl' + siteIds} />
              { this.hasFeature("sar") && <MenuItem name="DSAR Retrieval Form" to={'/dialogue/dsar-retrieval' + siteIds} /> }
              { this.hasFeature("sar") && <MenuItem name="SAR Form" to={'/dialogue/sar' + siteIds} /> }
            </MenuDropdown>
        )}
          {hasPreferencesAccess && (
            <MenuItem name="Universal Consent and Preferences" to={'/dialogue/messages/preferences' + siteIds} />
          )}
          {hasAppleMessagingAccess && showiOSTracking && <MenuItem name="iOS Tracking Messages" to={'/dialogue/messages-ios' + siteIds} />}
          {hasCustomMessages && <MenuItem name="Custom Messages" to={'/dialogue/messages-custom' + siteIds} />}
          {this.hasFeature("adblock_v2") && showAdblock && <MenuItem name="Adblock Messages" to={'/dialogue/adblock' + siteIds} />}
          {this.hasFeature("adblock") && showAdblock && <MenuItem name="Adblock Messages (Classic)" to={'/dialogue/messages-adblock' + siteIds} />}
        </MenuDropdown>
      </div>
    );
  }
}
