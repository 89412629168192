import React from 'react';
import PropTypes from 'prop-types';
import {  Col, Input, Radio, Row } from 'antd';
import { Modal, Select, message, SelectWithSelectAll } from '../../../styleguide';
import { WarningOutlined } from '@ant-design/icons';
import { List, fromJS } from 'immutable';
import { getSnippet, getMulticampaignSnippet, getMultiCampaignPolicies, getPolicies, hasFeature, platforms, getAppSnippet, getDocImplemnationLink } from './helper';

const { Option } = Select;
const { TextArea } = Input;
const defaultSelectValue = undefined;

export default class CopyCodeSnippet extends React.Component {
  static propTypes = {
    showCodeSnippetModal: PropTypes.bool.isRequired,
    snippetProperty: PropTypes.object,
    accountId: PropTypes.number.isRequired,
    toggleSnippetModal: PropTypes.func.isRequired,
    multiCampaign: PropTypes.bool,
    multiCampaignWarning: PropTypes.bool.isRequired,
  };
  state = {
    policiesSelected: List(),
    nonTcfv2Selected: false,
    usStandardSelected: true,
    platform: defaultSelectValue,
    docImplemenationLink: null
  };
  componentDidMount() {
    let policies = List();
    const property = this.props.snippetProperty;
    if(this.props.multiCampaign && property) {
      policies = getMultiCampaignPolicies(this.props.accountFeatures, property.type);
    } else {
      policies = getPolicies(this.props.accountFeatures);
    }
    if(policies.size === 1) {
      const policyValue = policies.map(p => p.get('value'));
      this.setState({ policiesSelected: policyValue });
    }
    const { docImplemenationLink, propertyLabel } = { ...getDocImplemnationLink(property.type,this.props.multiCampaign) };
    this.setState({docImplemenationLink: <>{' '}<a href={docImplemenationLink} target="_blank">Click here</a> to review documentation related to your {propertyLabel} implementation.</>});
  }
  copyContent = (url) => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Copied to clipboard');
  };
  selectPolicy = (values) => {
    let policies;
    if(this.props.multiCampaign) {
      policies = getMultiCampaignPolicies(this.props.accountFeatures, this.props.snippetProperty.type);
    } else {
      policies = getPolicies(this.props.accountFeatures);
    }
    const allIndex = values.indexOf('all');
    const updatedPolicies = allIndex != -1 ? policies.map((p) => p.get('value')) : fromJS(values);
    let updatedStates = {
      policiesSelected: updatedPolicies,
      nonTcfv2Selected: !updatedPolicies.includes("gdpr") ? false : this.state.nonTcfv2Selected,
      usStandardSelected: !updatedPolicies.includes("usnat") ? true : this.state.usStandardSelected,
    };

    const isIOSSelection = [ 'objectiveC', 'swift' ].includes(this.state.platform);
    const isIOSTrackingSelected = values.includes('iOSTracking');
    if(isIOSTrackingSelected && values.length === 1 && !isIOSSelection) {
       updatedStates['platform'] = defaultSelectValue;
    }
    this.setState(updatedStates);
  };
  onCancel = (e) => {
    this.setState({ policiesSelected: List() });
    this.props.toggleSnippetModal(e);
  };
  selectPlatform = (platform) => {
    this.setState({ platform });
  }
  loadAppPropertySnippet = () => {
    const platform = this.state.platform;
    const policies = this.state.policiesSelected;
    let snippet = `Code will be displayed after you select the campaign type and platform`;
    if(!policies.size || !platform) {
       return snippet;
    }

    return getAppSnippet( policies, this.props.accountId, this.props.snippetProperty, platform);        
  }
  render() {
    let policyWrapper = 'policy-wrapper';
    let platformsDropDown;
    const selectedProperty = this.props.snippetProperty;
    const isAppProperty = selectedProperty && selectedProperty.type === 'app';
    let platformOptions = platforms;
    if(selectedProperty && this.props.multiCampaign) {
      if(isAppProperty) {
        policyWrapper = 'policy-campaigns-wrapper';

        const isIOSTrackingSelected = this.state.policiesSelected.includes('iOSTracking');
        if(isIOSTrackingSelected && this.state.policiesSelected.size === 1) {
           platformOptions = platformOptions.filter(p => p.value != 'kotlin' && p.value != 'java');
        }
        platformsDropDown = <div className={ policyWrapper }>
            <span className="policy-label">Platform</span>
            <Select placeholder="Select" value={this.state.platform} onChange={this.selectPlatform} disabled={!this.state.policiesSelected.size}>
              { platformOptions.map((p, pi) => <Option key={pi} value={p.value}>{p.label}</Option>) }
            </Select>
          </div>;
      }
    }

    const selectText = this.props.multiCampaign ? `campaign type ${ isAppProperty ? 'and platform' : '' }` : 'policy';
    let snippet = `Code will be displayed after you select the ${ selectText }`;
    if (this.state.policiesSelected.size) {
      if (this.props.multiCampaign) {
        let policiesSelected = this.state.policiesSelected;
        if (this.state.nonTcfv2Selected) {
          policiesSelected = policiesSelected.push('nonTcfv2')
        }
        if (this.state.usStandardSelected) {
          policiesSelected = policiesSelected.push('us_standard');
        }
        if (isAppProperty) {
          snippet = this.loadAppPropertySnippet();          
        } else {
          snippet = getMulticampaignSnippet(
            policiesSelected,
            this.props.accountId,
            this.props.snippetProperty,
          );
        }
      } else {
        snippet = getSnippet(
          this.state.policiesSelected,
          this.props.accountId,
          this.props.snippetProperty,
        );
      }
    }
    let policies = List();
    let multiCampaignMessage;
    let docsMessage;
    if (this.props.multiCampaign) {
      const multiCampaignPolicies = getMultiCampaignPolicies(this.props.accountFeatures, this.props.snippetProperty.type);
      const multiCampaignWarningMsg = <React.Fragment>Multi-campaign is now <span>enabled</span>. You can now set multiple campaigns and define priorites in the Campaigns tab.
                    To be able to launch more than one campaign, you will need to replace the <span>old snippet</span> on your property/ies with the <span>new snippet</span></React.Fragment>;
      const appDocMsg = <React.Fragment>Select Campaign Type(s) and Platform to get quick start code snippets for initializing our SDK and showing a message.</React.Fragment>;
      const replaceMsg = "This snippet should replace the existing snippet with the new snippet you have on your property.";

      multiCampaignMessage = (
        <div className='multi-campaign-messages'>
          {
            this.props.multiCampaignWarning ?
              (
                <div className='warning'>
                  <div><WarningOutlined /></div>
                  <div className='message'>
                    { multiCampaignWarningMsg }
                  </div>
                </div>
              ) :
              null
          }
          <div className='replace-message'>
            { isAppProperty ? appDocMsg : replaceMsg }
            { this.state.docImplemenationLink}
          </div>
        </div>
      );
      policies = multiCampaignPolicies;
    } else {
      docsMessage = (
        <div className="document-note">
          We provide documentation files for <b>GDPR TCF v2</b> and <b>U.S. Privacy</b> that will help you to know more about the code snippet.
          {this.state.docImplemenationLink}
        </div>
      );
      policies = getPolicies(this.props.accountFeatures);
    }

    const hasNonIab = hasFeature(this.props.accountFeatures, 'non_iab_vl');

    const selectTitle = this.props.multiCampaign ? 'Campaign Type' : 'Policy';
    const isMultiNonApp = this.props.multiCampaign && !isAppProperty;

    return (
      <Modal
        wrapClassName="property-modal"
        title="Code Snippet"
        visible={this.props.showCodeSnippetModal}
        onOk={() => this.copyContent(snippet)}
        okText="Copy Code"
        cancelText="Close"
        okButtonProps={{ disabled: this.state.policiesSelected.size == 0 }}
        onCancel={(e) => this.onCancel(e)}
        width={650}
      >
        <div className="properties-policy-select">
          {docsMessage}
          {multiCampaignMessage}
          <div className="select-policy-wrapper">
            {platformsDropDown ? (
              <div className="policy-campaigns-wrapper">
                <span className="policy-label">{selectTitle}</span>
                <SelectWithSelectAll
                  options={policies.map((p) => ({ label: p.get("label"), value: p.get("value")}))?.toJS()}
                  value={this.state.policiesSelected.toJS()}
                  onChange={this.selectPolicy}
                  width={280}
                />
              </div>
            ) : (
              <Row gutter={[0, 12]} align="middle" style={{ width: "100%" }}>
                <Col span={ isMultiNonApp && this.state.policiesSelected.includes("usnat") ? 7 : 5}>
                  <span className="policy-label">{selectTitle}</span>
                </Col>
                <Col span={isMultiNonApp && this.state.policiesSelected.includes("usnat") ? 17 : 19}>
                  <SelectWithSelectAll
                    options={policies.map((p) => ({ label: p.get("label"), value: p.get("value")}))?.toJS()}
                    value={this.state.policiesSelected.toJS()}
                    onChange={this.selectPolicy}
                    width={360}
                  />
                </Col>
                {isMultiNonApp && hasNonIab && this.state.policiesSelected.includes("gdpr") ? (
                  <>
                    <Col span={isMultiNonApp && hasNonIab && this.state.policiesSelected.includes("usnat") ? 7 : 5}>
                      <span className="policy-label">GDPR:</span>
                    </Col>
                    <Col span={isMultiNonApp && hasNonIab && this.state.policiesSelected.includes("usnat") ? 17 : 19}>
                      <Radio.Group
                        onChange={(v) => this.setState({ nonTcfv2Selected: v.target.value })}
                        value={this.state.nonTcfv2Selected}
                      >
                        <Radio value={false}>GDPR IAB TCF</Radio>
                        <Radio value={true}>GDPR Standard</Radio>
                      </Radio.Group>
                    </Col>
                  </>
                ) : null}
                {isMultiNonApp && this.state.policiesSelected.includes("usnat") ? (
                  <>
                    <Col span={7}>
                      <span className="policy-label">
                        U.S. MultiState Privacy:
                      </span>
                    </Col>
                    <Col span={17}>
                      <Radio.Group
                        onChange={(v) =>this.setState({ usStandardSelected: v.target.value })}
                        value={this.state.usStandardSelected}
                      >
                        <Radio value={true}>US Standard</Radio>
                        <Radio value={false}>US IAB GPP</Radio>
                      </Radio.Group>
                    </Col>
                  </>
                ) : null}
              </Row>
            )}
            {platformsDropDown}
          </div>
        </div>
        <TextArea
          className="disabled-code"
          value={snippet}
          rows={10}
          readOnly={true}
          disabled={true}
          onContextMenu={(e) => e.preventDefault()}
        />
      </Modal>
    );
  }
}
