import { MinusSquareOutlined, PlusSquareOutlined, InfoCircleFilled ,IssuesCloseOutlined, CopyOutlined } from '@ant-design/icons';
import { Checkbox, ConfigProvider, Divider, message, Modal,Button,Popover,Flex } from 'antd';
import enUS from 'antd/lib/locale/en_US.js';
import { List, is } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import groupBy from 'lodash/groupBy';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { updateAccount } from '../../api/admin_consent';
import { getAllSDKKeys,generateSDKKey } from '../../actions/account_actions'
import { User } from '../../records/account_records';
import {  Input, Select, SelectDropdown, Table } from '../../styleguide';
import moment from 'moment';

const CheckboxGroup = Checkbox.Group;

const diagnoseDashboardMetricOptions = [
    {
        label: 'Non disclosed vendors observed',
        value: 'diagnose_metric_non_disclosed_vendors',
    },
    {
        label: 'Disclosed Vendors',
        value: 'diagnose_metric_disclosed_vendors',
    },
    {
        label: 'Vendors triggered prior to consent',
        value: 'diagnose_metric_vendors_prior_to_consent',
    },
    {
        label: 'Possible Fingerprinting',
        value: 'diagnose_metric_fingerprinting',
    },
    {
        label: 'Data leaving the EEA',
        value: 'diagnose_metric_data_leaving_eea',
    },
    {
        label: 'Cookies with long lifespans',
        value: 'diagnose_metric_cookies_with_long_lifespans',
    },
    {
        label: 'All Cookies Found',
        value: 'diagnose_metric_all_cookies_found',
    },
    {
        label: 'Vendors after opt out',
        value: 'diagnose_metric_vendors_after_opt_out',
    },
    {
        label: 'Tracking Tech',
        value: 'diagnose_metric_tracking_tech_detected',
    },
];

const appMetricOptions = [
    {
        label: 'Vendors triggered prior to consent',
        value: 'app_metric_vendors_prior_to_consent',
    },
    {
        label: 'Vendors after opt out',
        value: 'app_metric_vendors_after_opt_out',
    },
    {
        label: 'Vendors after reject all',
        value: 'app_metric_vendors_after_reject_all',
    },
    {
        label: 'All vendors found',
        value: 'app_metric_all_vendors_found',
    },
];

const columnNames = [
    'altpay',
    'consent',
    'messaging',
    'samba',
    'vendor_scanning',
    'sdk',
    'adblock',
    'message_builder_v1',
    'message_builder_v2',
    'tcf_v2',
    'usnat',
    'preferences',
    'ccpa',
    'vendor_list_dns',
    'sar',
    'consent_compensation',
    'native_messaging',
    'privacy_lens',
    'privacy_lens_admin',
    'privacy_lens_read_only',
    'privacy_lens_scoring_only',
    'adblock_v2',
    'custom_choices',
    'scenario_v2',
    'google_atp',
    'privacy_manager_ott',
    'vendor_scanning_v2',
    'status_page',
    'non_iab_vl',
    'reporting_v2',
    'diagnose_dashboard',
    'app_dashboard',
    'apple_messaging',
    'pur_model',
    'ccpa_ott',
    'multi_campaign',
    'multi_campaign_default',
    'native_ott',
    'native_ott_ccpa',
    'zendesk_auth',
    'iab_custom_stack',
    // 'request_report_v2',
    'custom_messages',
    'diagnose_vendor_trace',
    'no_server_storage',
    'diagnose_vendor_management',
    'tcf2.2',
    'preferences',
    'diagnose_metric_non_disclosed_vendors',
    'diagnose_metric_disclosed_vendors',
    'diagnose_metric_vendors_prior_to_consent',
    'diagnose_metric_fingerprinting',
    'diagnose_metric_data_leaving_eea',
    'diagnose_metric_cookies_with_long_lifespans',
    'diagnose_metric_all_cookies_found',
    'diagnose_metric_vendors_after_opt_out',
    'app_metric_vendors_prior_to_consent',
    'app_metric_vendors_after_opt_out',
    'app_metric_vendors_after_reject_all',
    'app_metric_all_vendors_found',
    'diagnose_metric_tracking_tech_detected',
    'diagnose_beta',
    'hide_property_groups',
    'candu'
];

const columnAddServices = ['vendor_scanning_v2', 'sdk', 'sar', 'privacy_manager_ott', 'pur_model','vendor_scanning','adblock','adblock_v2','custom_messages','apple_messaging'];
const columnAdvFeatures = ['custom_choices', 'native_messaging', 'native_ott', 'native_ott_ccpa', 'ccpa_ott','iab_custom_stack', 'no_server_storage', 'tcf2.2', 'preferences', 'hide_property_groups','candu'];
const columnPrivacyLens = ['privacy_lens_admin', 'privacy_lens', 'privacy_lens_read_only', 'privacy_lens_scoring_only'];
const privacyLensAcessOptions = ['privacy_lens', 'privacy_lens_read_only', 'privacy_lens_scoring_only'];
export const columnDiagnose = ['diagnose_dashboard', 'diagnose_vendor_trace', 'diagnose_vendor_management', 'diagnose_metric_non_disclosed_vendors', 'diagnose_metric_disclosed_vendors', 'diagnose_metric_vendors_prior_to_consent', 'diagnose_metric_fingerprinting', 'diagnose_metric_data_leaving_eea', 'diagnose_metric_cookies_with_long_lifespans', 'diagnose_metric_all_cookies_found', 'diagnose_metric_vendors_after_opt_out','diagnose_metric_tracking_tech_detected'];
const diagnoseMetrics = ['diagnose_metric_non_disclosed_vendors', 'diagnose_metric_disclosed_vendors', 'diagnose_metric_vendors_prior_to_consent', 'diagnose_metric_fingerprinting', 'diagnose_metric_data_leaving_eea', 'diagnose_metric_cookies_with_long_lifespans', 'diagnose_metric_all_cookies_found', 'diagnose_metric_vendors_after_opt_out','diagnose_metric_tracking_tech_detected'];
const appMetrics = ['app_metric_vendors_prior_to_consent',
    'app_metric_vendors_after_opt_out',
    'app_metric_vendors_after_reject_all',
    'app_metric_all_vendors_found',]


const generateClients = (accountOwners) => {
    return accountOwners.map((client) => {
        let newClient = client;
        columnNames.forEach((columnName) => {
            const checkedBool = client.get('accountFeatures').includes(columnName) ? 1 : 0;
            newClient = newClient.toMap().set(columnName, checkedBool);
        });
        return newClient;
    });
};

export class AccountFeatures extends React.Component {
    static propTypes = {
        accountOwners: ImmutablePropTypes.listOf(PropTypes.instanceOf(User)).isRequired,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log('prevState.clients.size: ', prevState.clients.size)
        // console.log('nextProps.accountOwners.size: ', nextProps.accountOwners.size)
        if (!prevState.clients.size && nextProps.accountOwners.size) {
            const newClients = generateClients(nextProps.accountOwners);
            return {
                clients: newClients,
            };
        }
            return null;
    }

    state = {
        searchText: '',
        clients: generateClients(this.props.accountOwners),
        toggle: false,
        columnExpandCollapse: {
            addServices: false,
            advFeatures: false,
            privacyLens: false,
            diagnose: false,
        },
        subFeaturesByColumn: {
            addServices: columnAddServices,
            advFeatures: columnAdvFeatures,
            privacyLens: columnPrivacyLens,
            diagnose: columnDiagnose,
            diagnoseMetrics,
            appMetrics
        },
        generateSdkKeyModal: false,
        currentRecord: null,
    };

    componentDidUpdate(prevProps) {
        if (!is(prevProps.accountOwners, this.props.accountOwners)) {
            const newClients = generateClients(this.props.accountOwners);
            this.setState({ clients: newClients });
        }
    }

    componentDidMount() {
        this.props.getAllSDKKeys(this.props.currentUser.accountId);
    }

    updateGroup = (clients, group, index) => {
        const client = clients.get(index).toJS();
        const subFeatures = this.state.subFeaturesByColumn[group];
        const count = subFeatures.reduce((countCalc, column) => {
            if (client[column]) {
                const checkBool = client[column];
                if (checkBool) {
                    return countCalc + 1;
                }
            }
            return countCalc;
        }, 0);
        if (count === subFeatures.length) {
            clients = clients.setIn([index, group], 1);
        } else if (count === 0) {
            clients = clients.setIn([index, group], 0);
        } else if (count < subFeatures.length) {
            clients = clients.setIn([index, group], 2);
        }
        return clients;
    }

    onCheckboxClick = (e, record, featureName, group) => {
        const checked = e.target.checked;
        const checkedBool = checked ? 1 : 0;
        const index = this.state.clients.findIndex(client => client.get('accountId') === record.accountId);
        let clients = this.state.clients.setIn([index, featureName], checkedBool);

        if (group) {
            clients = this.updateGroup(clients, group, index);
        }

        this.setState({ clients });
    }

    reduceDataByColumn = (column) => {
        const { searchText, subFeaturesByColumn } = this.state;
        let reg;
        try {
            reg = new RegExp(searchText, 'gi');
        } catch (e) {
            console.log(e);
            message.error('Invalid search parameters');
        }

        const clients = this.state.clients.toJS()
            .map((record) => {
                const match = record[column].match(reg);
                if (!match) {
                    return null;
                }
                return {
                    ...record,
                    name: record[column],
                };
            }).filter(record => !!record);


        for (const key in subFeaturesByColumn) {
        const subFeatures = subFeaturesByColumn[key];
        clients.map((client) => {
            let count = 0;
            subFeatures.map((column) => {
                if (client[column]) {
                    const checkBool = client[column];
                    if (checkBool) {
                        count++;
                    }
                }
            });

            if (count === subFeatures.length) {
                client[key] = 1;
            } else if (count === 0) {
                client[key] = 0;
            } else if (count < subFeatures.length) {
                client[key] = 2;
            }
            return client;
        });
        }

        for (const key in subFeaturesByColumn) {
        const subFeatures = subFeaturesByColumn[key];
        clients.map((client) => {
            let count = 0;
            subFeatures.map((column) => {
                if (client[column]) {
                    const checkBool = client[column];
                    if (checkBool) {
                        count++;
                    }
                }
            });

            if (count === subFeatures.length) {
                client[key] = 1;
            } else if (count === 0) {
                client[key] = 0;
            } else if (count < subFeatures.length) {
                client[key] = 2;
            }
            return client;
        });
        }

        return clients;
    }

    handleAlphabeticalSort(a, b, key) {
        const first = a[key].toUpperCase();
        const second = b[key].toUpperCase();

        if (first < second) return -1;
        if (first > second) return 1;
        return 0;
    }

    handleNumberSort = (a, b, key) => {
        return parseFloat(a[key]) - parseFloat(b[key]);
    };

    handleGenerateSdkKey = async (currentRecord) => {
        this.props.generateSDKKey(currentRecord?.accountId).then(() => {
            message.success("API Key Has been successfully generated")
            this.setState({generateSdkKeyModal : false})
            this.props.getAllSDKKeys()
        })
    }

     footer = [
        <Button type='link' onClick={() => this.setState({generateSdkKeyModal : false})}>No</Button>,
        <Button type='primary'  onClick={() => this.handleGenerateSdkKey(this.state.currentRecord)}>Yes</Button>,
    ];

    onInputChange = (e) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    onClear = () => {
        this.setState({
            searchText: '',
            data: this.props.accountOwners,
        });
    }
    
    removeMetaData = () => {
        const clients = this.state.clients;
        return clients.map((client) => {
            let newClient = client;
            let features = newClient.get('accountFeatures');

            columnNames.forEach((columnName) => {
                if (client.get(columnName)) {
                    if (!client.get('accountFeatures').includes(columnName)) {
                        features = features.push(columnName);
                    }
                } else {
                    features = features.filterNot(v => v === columnName);
                }
                newClient = newClient.delete(columnName).set('accountFeatures', features);
            });
            return newClient;
        });
    }

    findUpdatedClients = (clientsWithoutMetaData) => {
        let clientsToBeUpdated = new List([]);
        clientsWithoutMetaData.forEach((c, i) => {
            const featuresAreEqual = is(c.get('accountFeatures').sort(), this.props.accountOwners.get(i).get('accountFeatures').sort());
            if (!featuresAreEqual) {
                clientsToBeUpdated = clientsToBeUpdated.push(c);
            }
        });
        return clientsToBeUpdated;
    }

    handleSave = () => {
        const clientsWithoutMetaData = this.removeMetaData();
        const clientsToBeUpdated = this.findUpdatedClients(clientsWithoutMetaData);
        if (clientsToBeUpdated.size > 5) {
            message.error('More than 5 accounts was modified. Please limit changes to 5 or less accounts to save.');
            return;
        }
        if (clientsToBeUpdated.size) {
            clientsToBeUpdated.forEach((client) => {
                updateAccount(client.get('accountId'), client.get('accountFeatures').toJS());
            });
            message.success('Features have been updated and saved');
        } else {
            message.warning('You have not made any feature changes');
        }
    }

    toggleColumn = (title) => {
        this.setState(prevState => ({ columnExpandCollapse: { ...prevState.columnExpandCollapse, [title]: !prevState.columnExpandCollapse[title] } }));
    }

    handleReset = (clearFilters) => {
        clearFilters();
    };

    selectPrivacyLensProductAccess = (featureName, record) => {
        const index = this.state.clients.findIndex(client => client.get('accountId') === record.accountId);
        let clients = this.state.clients;

        privacyLensAcessOptions.forEach((option) => {
            if (option === featureName) {
                clients = clients.setIn([index, option], 1);
            } else {
                clients = clients.setIn([index, option], 0);
            }
        });

        this.setState({ clients });
    }

    renderPrivacyLensProductAccess = () => {
        const featuresMap = {
            'All Access': 'privacy_lens',
            'Read Only': 'privacy_lens_read_only',
            'Scoring Results': 'privacy_lens_scoring_only',
            'No Access': 0,
        };

        return ({
            filterDropdown: ({ filters, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            const filterOptions = filters.map(f => f.text);
            return (
            <div className="account-features-dropdown">
                <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 2 }}>
                <Checkbox defaultChecked indeterminate={(selectedKeys.length > 0) && selectedKeys.length < filterOptions.length} checked={selectedKeys.length === filterOptions.length} onChange={e => (e.target.checked ? setSelectedKeys(filterOptions) : setSelectedKeys([]))}>
                All
                </Checkbox>
                <CheckboxGroup
                    className="account-features-checkbox"
                    value={selectedKeys}
                    options={filterOptions}
                    onChange={(list) => {
                    setSelectedKeys(list);
                }}
                />
                </div>
                <Divider />
                <Button
                    type="link"
                    size="small"
                    onClick={() => this.handleReset(clearFilters)}
                >
                    Clear Filter
                </Button>
                <Button
                    className="btn-primary"
                    size="small"
                    type="primary"
                    onClick={() => {
                    confirm({ closeDropdown: false });
                    }}
                >
                    Apply
                </Button>
            </div>
            );
        },
            onFilter: (value, record) => {
                return !!record[featuresMap[value]];
            },
            // render: (feature, record) => <Checkbox purple checked={feature} onClick={e => this.onCheckboxClick(e, record, dataIndex, group)} />
            render: (feature, record) => {
                let value = 'No Access';
                Object.values(featuresMap).forEach((f) => {
                    if (record[f]) value = f;
                });
                return (

                    <Select
                        value={value}
                        onChange={val => this.selectPrivacyLensProductAccess(val, record)}
                    >
                        {
                            Object.entries(featuresMap).map(nameAndVal => (
                                <Select.Option value={nameAndVal[1]} key={nameAndVal[1]}>{nameAndVal[0]}</Select.Option>
                            ))
                        }
                    </Select>
                );
            },
        });
    }

    getColumnSearchProps = (dataIndex, group) => ({
        // filterDropdown: ({ filters, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        //     const filterOptions = filters.map(f => f.text);
        //     return (
        //   <div className="account-features-dropdown">
        //     <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 2 }}>
        //      <Checkbox defaultChecked indeterminate={(selectedKeys.length > 0) && selectedKeys.length < filterOptions.length} checked={selectedKeys.length === filterOptions.length} onChange={e => (e.target.checked ? setSelectedKeys(filterOptions) : setSelectedKeys([]))}>
        //      All
        //     </Checkbox>
        //     <CheckboxGroup
        //         className="account-features-checkbox"
        //         value={selectedKeys}
        //         options={filterOptions}
        //         onChange={(list) => {
        //         setSelectedKeys(list);
        //     }}
        //     />
        //     </div>
        //       <Divider />
        //       <Button
        //         type="link"
        //         size="small"
        //         onClick={() => this.handleReset(clearFilters)}
        //       >
        //         Clear Filter
        //       </Button>
        //       <Button
        //         className="btn-primary"
        //         size="small"
        //         type="primary"
        //         onClick={() => {
        //           confirm({ closeDropdown: false });
        //         }}
        //       >
        //         Apply
        //       </Button>
        //   </div>
        // );
        // },
        filters: [
            { text: 'Active', value: 1 },
            { text: 'Inactive', value: 0 },
        ],
        onFilter: (value, record) => {
            return record[dataIndex] === value;
        },
        render: (feature, record) => <Checkbox disabled={dataIndex === 'privacy_lens_admin' && this.isPrivacyLensAdminDisabled(record)} purple checked={feature} onClick={e => this.onCheckboxClick(e, record, dataIndex, group)} />,
    })

    isPrivacyLensAdminDisabled = (record) => {
        let isDisabled = true;
        privacyLensAcessOptions.forEach((option) => {
            if (record[option]) isDisabled = false;
        });
        return isDisabled;
    }

    componentDidUpdate(prevProps,prevState){
        if (!is(prevState.currentRecord, this.state.currentRecord)) {
            this.setState({ currentRecord: this.state.currentRecord });
        }
    }

    onDropdownSelect = (selectedFeatures, record, group, column) => {
        const index = this.state.clients.findIndex(client => client.get('accountId') === record.accountId);
        let clients = this.state.clients;

        this.state.subFeaturesByColumn[column].forEach((metric) => {
            clients = clients.setIn([index, metric], +selectedFeatures.includes(metric));
        });

        if (column === 'diagnoseMetrics') {
            clients = clients.setIn([index, 'diagnose_dashboard'], +!!selectedFeatures.length);
        }

        if (column === 'appMetrics') {
            clients = clients.setIn([index, 'app_dashboard'], +!!selectedFeatures.length);
        }


        if (group) {
            clients = this.updateGroup(clients, group, index);
        }

        this.setState({ clients });
    }

     copyToClipboard = async (text) => {
        try {
            if (navigator.clipboard && navigator.clipboard.writeText) {
              await navigator.clipboard.writeText(text);
              message.success('Copied to clipboard!');
            } else {
              message.success('Clipboard API not supported in your browser');
            }
          } catch (error) {
            console.error('Failed to copy text: ', error);
            message.success('Failed to copy!');
          }
      };

    render() {
        if (!this.props.currentUser) { return null; }

        if (this.props.currentUser.accountId !== 22 && this.props.currentUser.accountId !== 808) {
            browserHistory.replace('/');
        }

        let table;
        if (this.state.clients) {
            const columns = [
                {
                    title: 'Accounts',
                    dataIndex: 'name',
                    key: 'name',
                    fixed: 'left',
                    width: 90,
                    sorter: (a, b) => this.handleAlphabeticalSort(a, b, 'name'),
                },
                {
                    title: 'GDPR TCFv2',
                    dataIndex: 'tcf_v2',
                    key: 'tcf_v2',
                    width: 90,
                    align: 'center',
                    ...this.getColumnSearchProps('tcf_v2'),
                },
                {
                    title: 'GDPR Standard',
                    dataIndex: 'non_iab_vl',
                    key: 'non_iab_vl',
                    width: 90,
                    align: 'center',
                    ...this.getColumnSearchProps('non_iab_vl'),
                },
                {
                    title: 'U.S. MultiState Privacy',
                    dataIndex: 'usnat',
                    key: 'usnat',
                    width: 90,
                    align: 'center',
                    ...this.getColumnSearchProps('usnat'),
                },
                {
                    title: 'U.S. Privacy (Legacy)',
                    dataIndex: 'ccpa',
                    key: 'ccpa',
                    width: 90,
                    align: 'center',
                    ...this.getColumnSearchProps('ccpa'),
                },
                {
                    title: 'Multi Campaign Default',
                    dataIndex: 'multi_campaign_default',
                    key: 'multi_campaign_default',
                    width: 90,
                    align: 'center',
                    ...this.getColumnSearchProps('multi_campaign_default'),
                },
                {
                    title: <>{!this.state.columnExpandCollapse.privacyLens ? <PlusSquareOutlined onClick={() => this.toggleColumn('privacyLens')} /> : <MinusSquareOutlined onClick={() => this.toggleColumn('privacyLens')} />}{" "}Privacy Lens </>,
                    children: this.state.columnExpandCollapse.privacyLens && [
                        {
                            title: 'Product Access',
                            dataIndex: 'product-access',
                            key: 'product-access',
                            align: 'center',
                            filters: [
                                { text: 'All Access', value: 'privacy_lens' },
                                { text: 'Read Only', value: 'privacy_lens_read_only' },
                                { text: 'Scoring Resules', value: 'privacy_lens_scoring_only' },
                                { text: 'No Access', value: 0 },

                            ],
                            ...this.renderPrivacyLensProductAccess(),
                        },
                        {
                            title: 'Accuracy Controls',
                            dataIndex: 'privacy_lens_admin',
                            key: 'privacy_lens_admin',
                            align: 'center',
                            ...this.getColumnSearchProps('privacy_lens_admin', 'privacyLens'),
                        },
                    ],
                    align: 'center',
                    width: 90,
                    render: (feature, record) => <Checkbox purple checked={record.privacyLens} />,
                },
                {
                    title: <>{!this.state.columnExpandCollapse.addServices ? <PlusSquareOutlined onClick={() => this.toggleColumn('addServices')} /> : <MinusSquareOutlined onClick={() => this.toggleColumn('addServices')} />}{" "}Additional Fee Services </>,
                    children: this.state.columnExpandCollapse.addServices && [
                        {
                            title: 'Diagnose Vendor Scanning',
                            dataIndex: 'vendor_scanning_v2',
                            key: 'vendor_scanning_v2',
                            align: 'center',
                            ...this.getColumnSearchProps('vendor_scanning_v2', 'addServices'),
                        },
                        {
                            title: 'Mobile App SDKs',
                            dataIndex: 'sdk',
                            key: 'sdk',
                            align: 'center',
                            ...this.getColumnSearchProps('sdk', 'addServices'),
                        },
                        {
                            title: 'Data Subject Access Request - U.S, Privacy',
                            dataIndex: 'sar',
                            key: 'sar',
                            align: 'center',
                            ...this.getColumnSearchProps('sar', 'addServices'),
                        },
                        {
                            title: 'Legacy OTT',
                            dataIndex: 'privacy_manager_ott',
                            key: 'privacy_manager_ott',
                            align: 'center',
                            ...this.getColumnSearchProps('privacy_manager_ott', 'addServices'),
                        },
                        {
                            title: 'TCFv2 PUR MODEL',
                            dataIndex: 'pur_model',
                            key: 'pur_model',
                            align: 'center',
                            ...this.getColumnSearchProps('pur_model', 'addServices'),
                        },
                        {
                            title: 'Mediatrust Vendor Scanning',
                            dataIndex: 'vendor_scanning',
                            key: 'vendor_scanning',
                            align: 'center',
                            ...this.getColumnSearchProps('vendor_scanning', 'addServices'),
                        },
                        {
                            title: 'Adblock',
                            dataIndex: 'adblock',
                            key: 'adblock',
                            align: 'center',
                            ...this.getColumnSearchProps('adblock', 'addServices'),
                        },
                        {
                            title: 'Adblock v2',
                            dataIndex: 'adblock_v2',
                            key: 'adblock_v2',
                            align: 'center',
                            ...this.getColumnSearchProps('adblock_v2', 'addServices'),
                        },
                        {
                            title: 'Custom Messages',
                            dataIndex: 'custom_messages',
                            key: 'custom_messages',
                            align: 'center',
                            ...this.getColumnSearchProps('custom_messages', 'addServices'),
                        },
                        {
                            title: 'iOS Tracking Prompt',
                            dataIndex: 'apple_messaging',
                            key: 'apple_messaging',
                            align: 'center',
                            ...this.getColumnSearchProps('apple_messaging', 'addServices'),
                        },
                    ],
                    align: 'center',
                    width: 90,
                    render: (feature, record) => <Checkbox purple indeterminate={record.addServices === 2} checked={record.addServices} />,
                },
                {
                    title: <>{!this.state.columnExpandCollapse.advFeatures ? <PlusSquareOutlined onClick={() => this.toggleColumn('advFeatures')} /> : <MinusSquareOutlined onClick={() => this.toggleColumn('advFeatures')} />}{" "}Advanced Features </>,
                    children: this.state.columnExpandCollapse.advFeatures && [
                    {
                        title: 'Custom Choice Options',
                        dataIndex: 'custom_choices',
                        key: 'custom_choices',
                        align: 'center',
                        ...this.getColumnSearchProps('custom_choices', 'advFeatures'),
                    },
                    {
                        title: 'Native Messaging',
                        dataIndex: 'native_messaging',
                        key: 'native_messaging',
                        align: 'center',
                        ...this.getColumnSearchProps('native_messaging', 'advFeatures'),
                    },
                    {
                        title: 'U.S. Privacy OTT',
                        dataIndex: 'ccpa_ott',
                        key: 'ccpa_ott',
                        align: 'center',
                        ...this.getColumnSearchProps('ccpa_ott', 'advFeatures'),
                    },
                    {
                        title: 'OTT',
                        dataIndex: 'native_ott',
                        key: 'native_ott',
                        align: 'center',
                        ...this.getColumnSearchProps('native_ott', 'advFeatures'),
                    },
                    {
                        title: 'OTT U.S. Privacy',
                        dataIndex: 'native_ott_ccpa',
                        key: 'native_ott_ccpa',
                        align: 'center',
                        ...this.getColumnSearchProps('native_ott_ccpa', 'advFeatures'),
                    },
                    {
                        title: 'IAB Under Custom Stack',
                        dataIndex: 'iab_custom_stack',
                        key: 'iab_custom_stack',
                        align: 'center',
                        ...this.getColumnSearchProps('iab_custom_stack', 'advFeatures'),
                    },
                    {
                        title: 'No server side storage (Android)',
                        dataIndex: 'no_server_storage',
                        key: 'no_server_storage',
                        align: 'center',
                        ...this.getColumnSearchProps('no_server_storage', 'advFeatures'),
                    },
                    {
                        title: 'TCF v2.2',
                        dataIndex: 'tcf2.2',
                        key: 'tcf2.2',
                        align: 'center',
                        ...this.getColumnSearchProps('tcf2.2', 'advFeatures'),
                    },
                    {
                        title: 'Universal Consent and Preferences',
                        dataIndex: 'preferences',
                        key: 'preferences',
                        align: 'center',
                        ...this.getColumnSearchProps('preferences', 'advFeatures'),
                    },
                    {
                        title: 'Hide Property Groups',
                        dataIndex: 'hide_property_groups',
                        key: 'hide_property_groups',
                        align: 'center',
                        ...this.getColumnSearchProps('hide_property_groups', 'advFeatures'),
                    },
                    {
                        title: 'Candu',
                        dataIndex: 'candu',
                        key: 'candu',
                        align: 'center',
                        ...this.getColumnSearchProps('candu', 'advFeatures'),
                    },
                    ],
                    align: 'center',
                    width: 90,
                    render: (record, feature) => <Checkbox purple indeterminate={record.advFeatures === 2} checked={record.advFeatures} />,
                },
                {
                    title: <>{!this.state.columnExpandCollapse.diagnose ? <PlusSquareOutlined onClick={() => this.toggleColumn('diagnose')} /> : <MinusSquareOutlined onClick={() => this.toggleColumn('diagnose')} />}{" "}Diagnose </>,
                    children: this.state.columnExpandCollapse.diagnose && [
                        {
                            title: 'Dashboard Metrics',
                            dataIndex: 'diagnose_dashboard',
                            key: 'diagnose_dashboard',
                            align: 'center',
                            render: (feature, record) => (
                                <SelectDropdown
                                    options={diagnoseDashboardMetricOptions}
                                    onChange={val => this.onDropdownSelect(val, record, 'diagnose', 'diagnoseMetrics')}
                                    value={this.state.subFeaturesByColumn.diagnoseMetrics.filter(metric => !!record?.[metric])} 
                                    selectAll
                                    multiple
                                    entityName="Metrics"
                                />
                            ),
                        },
                        {
                            title: 'App Metrics',
                            dataIndex: 'app_dashboard',
                            key: 'app_dashboard',
                            align: 'center',
                            render: (feature, record) => (
                                <SelectDropdown
                                    options={appMetricOptions}
                                    onChange={val => this.onDropdownSelect(val, record, 'diagnose', 'appMetrics')}
                                    value={this.state.subFeaturesByColumn.appMetrics.filter(metric => !!record?.[metric])}
                                    selectAll
                                    multiple
                                    entityName="Metrics"
                                />
                            ),
                        },
                        {
                            title: 'Vendor Trace',
                            dataIndex: 'diagnose_vendor_trace',
                            key: 'diagnose_vendor_trace',
                            align: 'center',
                            ...this.getColumnSearchProps('diagnose_vendor_trace', 'diagnose'),
                        },
                        {
                            title: 'Vendor Status Management',
                            dataIndex: 'diagnose_vendor_management',
                            key: 'diagnose_vendor_management',
                            align: 'center',
                            ...this.getColumnSearchProps('diagnose_vendor_management', 'diagnose'),
                        },
                        {
                            title: 'Beta features',
                            dataIndex: 'diagnose_beta',
                            key: 'diagnose_beta',
                            align: 'center',
                            ...this.getColumnSearchProps('diagnose_beta', 'diagnose'),
                        },
                        {
                            title: 'API Key - App SDK',
                            dataIndex: 'diagnose_beta',
                            key: 'diagnose_beta',
                            align: 'center',
                            render: (feature,record) => {
                                const groupByAccountId = groupBy(this.props.allSdkKeys, 'account_id');
                                const sortedGroupedData = Object.fromEntries(
                                    Object.entries(groupByAccountId).map(([accountId, items]) => [
                                      accountId,
                                      items.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)),
                                    ])
                                  );
                                const allSdkKeys = sortedGroupedData[record.accountId];

                                if(allSdkKeys && allSdkKeys.length > 0){
                                    const content = (
                                        <Flex vertical>
                                        <Flex align='center' justify='space-between'><b>New API Key:</b>{allSdkKeys && allSdkKeys[0]?.apikey} <Button type="link" icon={<CopyOutlined />} onClick={() => this.copyToClipboard(keyRecord?.apikey)}></Button></Flex>
                                        <Flex  align='center' justify='space-between'><b>Old API Key:</b>{allSdkKeys && allSdkKeys[1]?.apikey}<Button type="link" icon={<CopyOutlined />} onClick={() => this.copyToClipboard(keyRecord?.apikey)}></Button></Flex>
                                        <Flex  align='center' justify='space-around'><b style={{marginRight: "10px"}}>Generated On:</b>{ allSdkKeys && moment(allSdkKeys[0]?.created_on).format("DD/MM/YY")}</Flex>
                                        </Flex>
                                    );
                                    return (allSdkKeys[0] && <div>
                                        {allSdkKeys[0]?.apikey}
                                        <Popover trigger="click" placement="topRight" content={content}>
                                            <Button type='link' icon={<IssuesCloseOutlined />} />
                                       </Popover>
                                        </div>)
                                }
                
                            }
                           // ...this.getColumnSearchProps('diagnose_beta', 'diagnose'),
                        },
                        {
                            title: 'Action',
                            dataIndex: 'diagnose_beta',
                            key: 'diagnose_beta',
                            align: 'center',
                            render: (feature,record) => <Button type="link" onClick={
                                () => {this.setState({ generateSdkKeyModal: true }),this.setState({currentRecord: record})}}>Generate</Button>
                        },
                    ],
                    align: 'center',
                    width: 90,
                    render: (record, feature) => <Checkbox purple indeterminate={record.diagnose === 2} checked={record.diagnose} />,

                },
            ];

            const data = this.reduceDataByColumn('accountName')

            table = (
                <ConfigProvider locale={enUS}>
                    <Table
                        className="account-features-table"
                        scroll={{ x: 'max-content' }}
                        rowKey={record => record.accountId}
                        dataSource={data}
                        columns={columns}
                        pagination={30}
                        onChange={this.onClick}
                    />
                </ConfigProvider>
            );
        }
        return (
            <div>
                <div className="admin-settings-page-new">
                    <div className="search-container-admin">
                        <Input
                            purple
                            ref={el => this.searchInput = el}
                            placeholder="Search"
                            value={this.state.searchText}
                            onChange={this.onInputChange}
                        />
                        <Button
                            type="primary"
                            onClick={this.handleSave}
                        >
                        Save
                        </Button>
                    </div>
                    <div className="features-table__wrapper">
                    {table}
                    </div>
                </div>
                <Modal
                 className='generate-sdk-modal'
                 open={this.state.generateSdkKeyModal}
                 closable={true}
                 destroyOnClose={true}
                 onCancel={() => this.setState({ generateSdkKeyModal: false})}
                 footer={this.footer}
                >
                    <div className="title">Generate API Key</div>
                    <div className='info-message'>Are you sure you want to generate a key?</div>

                </Modal>
            </div>
        );
    }
}

const mapStateToProps = function (store) {
    return {
      currentUser: store.accountState.getIn(['userDetails', 'value']),
      allSdkKeys: store.accountState.getIn(['sdkKeyList', 'value'])
    };
  };

export default connect(mapStateToProps, {
    getAllSDKKeys,
    generateSDKKey,
})(AccountFeatures);
