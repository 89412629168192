import React, { useEffect, useState } from "react";
import { Input } from "../../../styleguide";
import { List } from "immutable";
import PropertySelection from "../../common/PropertySelection";
import IntegrationCategoryMapping from "./Screens/IntegrationCategoryMapping";
import AddCategories from "./Screens/AddCategories/AddCategories.jsx";
import LanguageAndTranslations from "./Screens/LanguageAndTranslations";
import {  Tabs, Alert, Modal } from "antd";
import { evaluateRequriedFieldsFromStep } from "./helper";
import classNames from "classnames";
import { useSelector } from "react-redux";

const EditModal = (props) => {
  const [configuration, setConfiguration] = useState(props.configuration);
  const [confirmMsgBeforeSave, setConfirmMsgBeforeSave] = useState(null);
  const [stepHasError, setStepHasError] = useState(false);
  const [activeTab, setActiveTab] = useState(props.defaultTab ?? "")
  const configurationSavePending = useSelector(state => state.preferencesState.get('savePending'));

  useEffect(()=>{
    if(props.onEditScreen && !evaluateRequriedFieldsFromStep(props.edit, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
      setStepHasError(true);
    } else {
      setStepHasError(false);
    } 
  }, [configuration]);
  
  const onChangeTab = (value) => {
    setActiveTab(value)
  }

  let content = null;
  let modalWidth = 1086;
  let modalTitle = null;
  switch(props.edit) {
    case 'name':
      const configName = configuration.get('name');
      content = (
      <>
        <label htmlFor="name"> Enter Name </label>
        <Input
          placeholder="Enter Name"
          value={configName}
          onChange={({target: {value}}) => {
            setConfiguration(configuration.set('name', value));
          }}
          maxLength={60}
          showCount={true}
          error={stepHasError ? props.preferenceConfigurationNamesList.includes(configName) ? <>Regulation with name <b>{configName}</b> already exists</> :"Please enter a Configuration Name" : null}
        />
      </>)
      modalWidth = 400;
      break;
    case 'properties':
      content = (
        <>
        <div className="step-error">{stepHasError ? "Please select at least one property" : null}</div>
        <div className="step-section">
          <div className={`section-content ${stepHasError ? 'validation-error' : null}`}>
            <PropertySelection
              selectedPropertiesKeys={configuration.get('propertyIds')?.toJS()}
              setSelectedPropertiesKeys={(keys) => {setConfiguration(configuration.set('propertyIds', List(keys)))}}
              sites={props.sites}
              siteGroups={props.siteGroups}
            />
          </div>
        </div>
        </>)
      modalTitle = "Manage Properties";
      break;
    case 'language':
      content = (
        <LanguageAndTranslations
          regulation={configuration}
          categories={configuration?.categories}
          updateConfiguration={setConfiguration}
          onEditScreen={props.onEditScreen}
          parentTab={activeTab}
          showError={stepHasError}
        />
      );
      modalTitle = "Language and Translation";
      break;
    case 'manage_preferences':
      content = (
        <Tabs
          activeTab={activeTab}
          onChange={onChangeTab}
          defaultActiveKey={props.defaultTab}
        >
          <Tabs.TabPane tab="Marketing Preference Categories" key="categories">
            <AddCategories
              readOnly={false}
              configuration={configuration}
              categories={configuration?.categories}
              updateConfiguration={setConfiguration}
              catType="MARKETING"
              searchEnabled
              onEditScreen={props.onEditScreen}
              showError={stepHasError}
          />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Integration Mapping" key="int_mapping" disabled={configuration?.categories?.filter((cat) => !cat.get("dateDeleted") && cat.type === "MARKETING").size === 0}>
            <IntegrationCategoryMapping
              configuration={configuration}
              updateConfiguration={setConfiguration}
              onEditScreen={props.onEditScreen}
              catType="MARKETING"
            />
          </Tabs.TabPane>
        </Tabs>
      );
      modalTitle = "Manage Preference Categories";
      break;
    case 'manage_transactions':
      content = (
        <AddCategories
          readOnly={false}
          configuration={configuration}
          categories={configuration?.categories}
          updateConfiguration={setConfiguration}
          catType="LEGAL-TRANSACTION"
          searchEnabled
          onEditScreen={props.onEditScreen}
          showError={stepHasError}
        />
      );
      modalTitle = "Manage Transaction Categories";
      break;
  }

  const handleOk = (showConfirmation) => {
    if(props.onEditScreen && !evaluateRequriedFieldsFromStep(props.edit, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
      setStepHasError(true);
      return;
    } 
    if(props.edit == 'properties' & showConfirmation){
      setConfirmMsgBeforeSave("These changes will affect the associated cookies and live campaigns running on associated properties. Are you sure you want to make these changes?")
    } else {
      props.saveConfiguration(configuration)
      setConfirmMsgBeforeSave(null)
    }
    setStepHasError(false);
  }

  const handleCancel = () => {
      props.cancelEdit()
  }

  return (
    <Modal
      wrapClassName={classNames("edit-preferences-modal", props.edit)}
      title={modalTitle}
      onOk={() => props.onEditScreen && !confirmMsgBeforeSave ? handleOk(true) : handleOk(false)}
      okText="Save"
      okButtonProps={{disabled: stepHasError || configurationSavePending, loading: configurationSavePending}}
      cancelButtonProps={{type: "link"}}
      onCancel={handleCancel}
      closable
      open={true}
      width={modalWidth}
      style={{
        top: "5%"
      }}
    >
        {content}
        {confirmMsgBeforeSave ? <Alert style={{marginTop: '20px', marginBottom: '-20px'}} type={"warning"} showIcon message={confirmMsgBeforeSave}/>: null}
    </Modal>
  )
}

export default EditModal;