import moment from 'moment';
import * as types from '../actions/action_types';
import { StoreRecord } from '../records/store_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

import { Map, List } from 'immutable';

const initialState = Map({
  vendors: StoreRecord({ value: List([]) }),
  vendorsHash: StoreRecord({ value: Map({}) }),
  vendorConnections: StoreRecord({ value: List([]) }),
  vendorConnectionsIndexMap: StoreRecord({ value: Map({}) }),
  preferenceConfigurationList: StoreRecord({ value: List([]) }),
  savePending: false,
  latestLegalDocs: StoreRecord({value: []}),
  campaignTypeList:  new StoreRecord({ value: [] }),
})

export default (state = initialState, action) => {

  const prefConfigList = state.getIn(['preferenceConfigurationList', 'value']);
  let updatedPrefConfigList;
  let indexOfUpdatedConfiguration;

  const lists = state.getIn(['vendorConnections', 'value']);
  let updatedList;
  switch(action.type) {

    case types.VENDOR_CONNECTION_PENDING:
      return setPending(state, ['vendorConnections']);

    case types.VENDOR_CONNECTIONS_LOADED:
      const vendorConnectionsIndexMap =  action.vendorConnections?.reduce(function (map, obj) {
          return map.set(obj.connectionId, obj);
        }, Map({}))
      state = setValue(state, ['vendorConnectionsIndexMap'], vendorConnectionsIndexMap);
      state = setValue(state, ['vendorConnections'], action.vendorConnections.sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated))));
      break;
    
    case types.VENDOR_CONNECTION_ERROR:
      return setError(state, ['vendorConnections']);

    case types.PREFERENCE_CONFIGURATION_SAVE_PENDING:
      return state.set('savePending', true);

    case types.PREFERENCE_CONFIGURATION_PENDING:
      return setPending(state, ['preferenceConfigurationList']);

    case types.PREFERENCE_CONFIGURATION_LIST_LOADED:
      return setValue(state, ['preferenceConfigurationList'], action.list.sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated))));

    case types.PREFERENCE_CONFIGURATION_ERROR:
      return setError(state, ['preferenceConfigurationList'], action.error).set('savePending', false);

    case types.CLEAR_PREFERENCE_CONFIGURATION_ERROR:
      return setError(state, ['preferenceConfigurationList'], null)
    
    case types.GET_PREFERENCE_CAMPAIGN_TYPE_PENDING:
      return setPending(state, ['campaignTypeList']);
    case types.GET_PREFERENCE_CAMPAIGN_TYPE_LOADED:
      action?.typeList?.push({type: "none", displayName: "None"});
      return setValue(state, ['campaignTypeList'],action.typeList)
    case types.GET_PREFERENCE_CAMPAIGN_TYPE_ERROR:
      return setError(state, ['campaignTypeList'], action.error);

    case types.PREFERENCE_CONFIGURATION_LOADED:
      indexOfUpdatedConfiguration = prefConfigList.findIndex((prefConfig) => prefConfig.id == action.prefConfig.id)
      updatedPrefConfigList = prefConfigList.set(indexOfUpdatedConfiguration, action.prefConfig)
      return setValue(state, ['preferenceConfigurationList'], updatedPrefConfigList);
    
    case types.PREFERENCE_CONFIGURATION_CREATED:
      updatedPrefConfigList = prefConfigList.push(action.prefConfig).sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated)));
      return setValue(state, ['preferenceConfigurationList'], updatedPrefConfigList).set('savePending', false);
    
    case types.PREFERENCE_CONFIGURATION_UPDATED:
      indexOfUpdatedConfiguration = prefConfigList.findIndex((prefConfig) => prefConfig.id == action.prefConfig.id)
      updatedPrefConfigList = prefConfigList.set(indexOfUpdatedConfiguration, action.prefConfig)
      return setValue(state, ['preferenceConfigurationList'], updatedPrefConfigList).set('savePending', false);
    
    case types.PREFERENCE_CONFIGURATION_DELETED:
      updatedPrefConfigList = prefConfigList.filter(l => l.id !== action.id);
      return setValue(state, ['preferenceConfigurationList'], updatedPrefConfigList)

    case types.PREFERENCES_CACHED:
      return stopPending(state, [action.property]);

    case types.VENDORS_PENDING:
      return setPending(state, ['vendors']);
    case types.VENDORS_LOADED:
      const vendorsHash =  action.vendors?.reduce(function (map, obj) {
          return map.set(obj.id, obj);
        }, Map({}))
      state = setValue(state, ['vendorsHash'], vendorsHash);
      state = setValue(state, ['vendors'], action.vendors);
      break;
    case types.VENDORS_ERROR:
      return setError(state, ['vendors'], action.error).set('savePending', false);
    case types.VENDOR_CONNECTION_SAVE_PENDING:
      return state.set('savePending', true)

    case types.VENDOR_CONNECTION_CREATED:
      updatedList = lists.push(action.connection).sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated)));
      return setValue(state, ['vendorConnections'], updatedList).set('savePending', false);
    case types.VENDOR_CONNECTION_DELETED:
      updatedList = lists.filter(l => l.connectionId !== action.id);
      return setValue(state, ['vendorConnections'], updatedList).set('savePending', false);
    case types.VENDOR_CONNECTION_UPDATED:
      let indexOfUpdatedConnection = lists.findIndex((prefConfig) => prefConfig.connectionId == action.connection.connectionId)
      updatedList = lists.set(indexOfUpdatedConnection, action.connection)
      return setValue(state, ['vendorConnections'], updatedList).set('savePending', false);
    case types.VENDORS_CONNECTION_SAVE_ERROR:
      return setError(state, ['vendorConnections'], action.error).set('savePending', false);

    //legal docs
    case types.LATEST_LEGAL_DOCS_LIST:
      return setValue(state, ["latestLegalDocs"], action.latestDocs);
    case types.LEGAL_DOCS_PENDING:
      return setPending(state, ["latestLegalDocs"]);
    case types.LEGAL_DOCS_ERROR:
      return setError(state, ["latestLegalDocs"], action.error)
  }

  return state;
};
