import React, { useState, useEffect, useRef } from "react";
import { Typography, Button, Upload, Row, Col, Space, Modal, Radio, message, Divider, Tabs } from "antd";
import { findObjectByKeyRef } from "./helper";
import { DownloadOutlined, LeftOutlined, InboxOutlined, CheckCircleFilled } from "@ant-design/icons";
import { convertToCSV } from "../../../../utils.js";
import SelectWithSelectAll from "../../../../../styleguide/components/SelectWithSelectAll.js";

const { Dragger } = Upload;

const tabsFieldTranslationkey = { 1: "names", 2: "descriptions" };

const BulkUploadModal = (props) => {
  const [choiceValue, setChoiceValue] = useState(1);
  const [languages, setLanguages] = useState(props.selectedLang);
  const [purposes, setPurposes] = useState(props?.purposeOptions.map((p) => p.value));

  const choiceValueRef = useRef(choiceValue);
  const languagesRef = useRef(languages);
  const purposesRef = useRef(purposes);

  useEffect(() => {
    // Update the ref when choiceValue changes
    choiceValueRef.current = choiceValue;
    languagesRef.current = languages;
    purposesRef.current = purposes;
  }, [choiceValue,languages]); 

  const parseCsvRow = (row) => {
    // Regular expression to split CSV rows, handling quoted fields and commas within quotes
    const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
    
    // Split the row, handling empty cells and quoted fields
    const values = row.split(regex).map(value => {
      // Trim whitespace and remove surrounding quotes if present
      return value.trim().replace(/^"(.*)"$/, '$1');
    });
  
    return values;
  };
  
  const beforeUpload = async (file) => {
    const reader = new FileReader();
    const currentChoiceValue = choiceValueRef.current;
    const languagesChoiceValue = languagesRef.current;
    const sampleData = getSampleData(currentChoiceValue, languagesChoiceValue);
  
    if (file) {
      const allowedExtensions = ["csv", "xlsx", "xls"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        reader.readAsText(file, "UTF-8");
        reader.onload = (e) => {
          const fileContent = e.target.result;
          const parsedData = [];
          
          // Split rows, filtering out completely empty rows
          const rows = fileContent.split(/\r\n|\r|\n/).filter(row => row.trim() !== '');
          
          // Handle headers separately
          const headers = rows[0].split(',').map(header => header.trim().replace(/^"(.*)"$/, '$1'));
          
          // Process data rows
          for (let i = 1; i < rows.length; i++) {
            const rowData = parseCsvRow(rows[i]);
            
            // Only create an object if we have the same number of values as headers
            if (rowData.length === headers.length) {
              const rowObj = {};
              
              for (let j = 0; j < headers.length; j++) {
                const key = headers[j];
                const value = rowData[j];
                
                // Only add non-empty values
                if (value !== undefined) {
                  rowObj[key] = value;
                }
              }
              
              // Only add the row if it has some content
              if (Object.keys(rowObj).length > 0) {
                parsedData.push(rowObj);
              }
            }
          }
          
          uploadTranslations(parsedData, sampleData, currentChoiceValue);
        };
        
        return false;
      }
    }
  };
  const onChange = (key) => {
    const selectedTab = Number(key);
    const options = selectedTab === 1 ? purposeOptions : descriptionOptions;
    const values =
      selectedTab === 1
        ? purposeOptions.map((p) => p.value)
        : descriptionOptions.map((p) => p.value);
    setChoiceValue(selectedTab);
    setOptions(options);
    setPurposes(values);
    updateSampleData(values, selectedTab);
  };

  const customBeforeUpload = async (file) => {
    const currentChoiceValue = choiceValueRef.current;
    beforeUpload(file, currentChoiceValue);
  };

  const uploadParams = {
    name: "file",
    // accept: '.csv',
    maxCount: 1,
    openFileDialogOnClick: true,
    beforeUpload: beforeUpload,
    onChange: (props) => {
      if(props.file.status == "error") {
        props.file.response = "Invalid file format."
        message.error("Invalid file format.")
      }else if(!props.file.status){
        props.file.response = "File uploaded successfully"
        message.success("File uploaded successfully.")
      }
    }
  };

  const {
    closeModal,
    selectedLang,
    purposeOptions,
    descriptionOptions,
    regulationCategories,
    uploadTranslations,
    activeTab,
    allLanguages,
  } = props;
  //const [purposes, setPurposes] = useState(purposeOptions.map((p) => p.value));
  const [descriptions, setDescriptions] = useState(
    descriptionOptions.map((p) => p.value)
  );
  const [sampleData, setSampleData] = useState([]);
  const [uploadProps, setUploadProps] = useState(uploadParams);
  const [showSuccessMessage, setShowSuccessMsg] = useState(false);
  const [options, setOptions] = useState(purposeOptions);
  const [values, setValues] = useState(purposeOptions.map((p) => p.value));

  let languageOptions = allLanguages.toJS()
    .filter((c) => selectedLang?.includes(c.code))
    .map((l, i) => ({
      label: l.language,
      value: l.code,
    }));

  const handleSelectedLanguage = (value) => {
    setLanguages(value);
  };
  const handleSelectedPurpose = (value) => {
    setPurposes(value);
  };

  useEffect(() => {
    updateSampleData(purposes, choiceValue);
  }, [languages, purposes, choiceValue]);

  const updateSampleData = (options, choiceValue) => {
    let sampleData = [];
    const selLanguages = allLanguages.filter((lang) =>
      languages.includes(lang.code)
    ).map((l) => l.language);
    const fieldTranslationkey = tabsFieldTranslationkey[choiceValue.toString()];
    options.map((opt) => {
      let purpose =
        findObjectByKeyRef(
          regulationCategories,
          "categories",
          "purposeRef",
          opt
        ) ||
        findObjectByKeyRef(
          regulationCategories,
          "categories",
          "systemStackRef",
          opt
        ) ||
        findObjectByKeyRef(regulationCategories, "categories", "categoryId", opt);
      if (purpose) {
        const newData = {
          "Category ID": purpose?.categoryId,
          "Category": purpose?.name,
        };
        selLanguages.forEach((lang) => {
          const langCode = allLanguages.find(
            (l) => l.language === lang
          )?.code.toLowerCase();
          const translation =
            purpose?.translations[fieldTranslationkey][langCode] || "";
          newData[lang] = translation || ""; // Initialize other language keys with empty string
        });
        sampleData.push(newData);
      }
    });
    const csvContent = sampleData.length > 0 ? convertToCSV(sampleData) : "";
    setSampleData(csvContent);
  };

  const getSampleData = (activeTab,languages) => {
    let sampleData = [];
    const selLanguages = allLanguages.filter((lang) =>
      languages.includes(lang.code)
    ).map((l) => l.language);
    const fieldTranslationkey = tabsFieldTranslationkey[activeTab.toString()];
    const options =
      activeTab === 1
        ? purposeOptions.map((p) => p.value)
        : descriptionOptions.map((p) => p.value);
    options.map((opt) => {
      let purpose = findObjectByKeyRef(regulationCategories, "categories", "categoryId", opt);
      if (purpose) {
        const newData = {
          "Category ID": purpose?.categoryId,
          "Category": purpose?.name
        };
        selLanguages.forEach((lang) => {
          const langCode = allLanguages.find(
            (l) => l.language === lang
          )?.code.toLowerCase();
          const translation =
            purpose?.translations[fieldTranslationkey][langCode] || "";
          newData[lang] = translation || ""; // Initialize other language keys with empty string
        });
        sampleData.push(newData);
      }
    });

    return sampleData;
  };

  const handleDownload = (e) => {
    //const utf8Bytes = new TextEncoder().encode(sampleData);
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), sampleData], {
      type: "text/csv;charset=utf-8",
    });
    //const blob = new Blob([utf8Bytes], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    //const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = new Date() + "Bulk_Upload.csv";
    a.click();
    URL.revokeObjectURL(url);
    setShowSuccessMsg(true);
    e.stopPropagation();
  };

  return (
    <Modal
      wrapClassName={`bulk-upload`}
      open={true}
      visible={true}
      footer={[
        <Button onClick={closeModal}>
          Close
        </Button>,
      ]}
      onCancel={closeModal}
      title={
        <Space>
          <Button
            icon={<LeftOutlined />}
            type="text"
            size="middle"
            onClick={closeModal}
          />
          <div>
            <Typography.Text level={4}>
              {"Bulk Upload"}
            </Typography.Text>
          </div>
        </Space>}
      width={ 720}
      style={{
        top: "5%",
      }}
    >
      <Row gutter={[0, 12]}>
        {/* {props.onEditScreen ? (
          <Col span={24}>
            <Space>
              <Button
                icon={<LeftOutlined />}
                type="text"
                size="middle"
                onClick={closeModal}
              />
              <div>
                <Typography.Text level={4} disabled={true}>
                  {"Bulk Upload"}
                </Typography.Text>
              </div>
            </Space>
          </Col>
        ) : null} */}
        <Col span={24}>
          <Tabs onChange={onChange}>
            <Tabs.TabPane tab="Title" key={1}></Tabs.TabPane>
            <Tabs.TabPane tab="Description" key={2}></Tabs.TabPane>
          </Tabs>
          {/* <Space direction="vertical" size={4}>
            <Typography.Text>Upload for: </Typography.Text>
            <Radio.Group onChange={onChange} value={choiceValue}>
              <Radio value={1}>Title</Radio>
              <Radio value={2}>Description</Radio>
            </Radio.Group>
          </Space> */}
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={4}>
            <Typography.Text>Language(s)</Typography.Text>
            <SelectWithSelectAll
              options={languageOptions}
              onChange={(value) => handleSelectedLanguage(value)}
              value={languages}
              width={"334px"}
            />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={4}>
            <Typography.Text>Categories</Typography.Text>
            <SelectWithSelectAll
              options={options}
              onChange={(value) => handleSelectedPurpose(value)}
              value={purposes}
              width={"334px"}
            />
          </Space>
        </Col>
        <Col span={12}>
          {showSuccessMessage ? (
            <Typography.Text type="success">
              {" "}
              <CheckCircleFilled /> Successfully Downloaded
            </Typography.Text>
          ) : null}
        </Col>
        <Col span={8} offset={4}>
          <Button onClick={handleDownload}>
            <DownloadOutlined />
            Download CSV Template
          </Button>
        </Col>
        <Col span={24} flex="auto">
          <Divider style={{margin:"16px 0"}}/>
          <Space direction="vertical" size={4} style={{width: "100%"}}>
            <Typography.Text>Upload translations CSV</Typography.Text>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};
BulkUploadModal.propTypes = {};
export default BulkUploadModal;
