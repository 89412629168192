import { createAccountScan, getAccountBulkScanDetails, getAccountBulkScansDetailFilters, getAccountScans, getAccountWebsites, getBulkScanListData, getBulkScansFilters, getConsentCategories, getProxyTools, getSitemapTemplateCsv } from '../api/diagnose_dashboard/scan_setup';
import * as types from './action_types';
import { actionCreator } from './helpers';

const diagnoseScanSetupListPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_PENDING,
});

const diagnoseScanSetupListLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_LOADED,
  data,
});

const diagnoseScanSetupListError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_DATA_ERROR,
  errorMessage,
});

export const getDiagnoseAccountScans = actionCreator(
  getAccountScans,
  diagnoseScanSetupListPending,
  diagnoseScanSetupListLoaded,
  diagnoseScanSetupListError,
);

const getDiagnoseScanWebsitesListPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_PENDING,
});

const getDiagnoseScanWebsitesListLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_LOADED,
  data,
});

const getDiagnoseScanWebsitesListError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_ACCOUNT_WEBSITES_ERROR,
  errorMessage,
});

export const getDiagnoseAccountWebsites = actionCreator(
  getAccountWebsites,
  getDiagnoseScanWebsitesListPending,
  getDiagnoseScanWebsitesListLoaded,
  getDiagnoseScanWebsitesListError,
);

const diagnoseScanSetupConsentCategoriesPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_PENDING,
});

const diagnoseScanSetupConsentCategoriesLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_LOADED,
  data,
});

const diagnoseScanSetupConsentCategoriesError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_CONSENT_CATEGORIES_ERROR,
  errorMessage,
});

export const getDiagnoseConsentCategories = actionCreator(
  getConsentCategories,
  diagnoseScanSetupConsentCategoriesPending,
  diagnoseScanSetupConsentCategoriesLoaded,
  diagnoseScanSetupConsentCategoriesError,
);

const getDiagnoseScanProxyToolsPending = () => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_PENDING,
});

const getDiagnoseScanProxyToolsLoaded = (data) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_LOADED,
  data,
});

const getDiagnoseScanProxyToolsError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_SCAN_SETUP_PROXY_TOOLS_ERROR,
  errorMessage,
});

export const getDiagnoseProxyTools = actionCreator(
  getProxyTools,
  getDiagnoseScanProxyToolsPending,
  getDiagnoseScanProxyToolsLoaded,
  getDiagnoseScanProxyToolsError,
);

const diagnoseBulkScanListPending = () => ({
  type: types.DIAGNOSE_GET_BULK_SCAN_LIST_PENDING,
});

const diagnoseBulkScanListLoaded = (data) => ({
  type: types.DIAGNOSE_GET_BULK_SCAN_LIST_LOADED,
  data,
});

const diagnoseBulkScanListError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_BULK_SCAN_LIST_ERROR,
  errorMessage,
});

export const getAccountBulkScans = actionCreator(
  getBulkScanListData,
  diagnoseBulkScanListPending,
  diagnoseBulkScanListLoaded,
  diagnoseBulkScanListError,
);

const diagnoseBulkScanFiltersPending = () => ({
  type: types.DIAGNOSE_GET_BULK_SCAN_FILTERS_PENDING,
});

const diagnoseBulkScanFiltersLoaded  = (data) => ({
  type: types.DIAGNOSE_GET_BULK_SCAN_FILTERS_LOADED,
  data,
});

const diagnoseBulkScanFiltersError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_BULK_SCAN_FILTERS_ERROR,
  errorMessage,
});

export const getDiagnoseBulkScanFilter = actionCreator(
  getBulkScansFilters,
  diagnoseBulkScanFiltersPending,
  diagnoseBulkScanFiltersLoaded,
  diagnoseBulkScanFiltersError,
);

const diagnoseAccountBulkScanDetailsPending = () => ({
  type: types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_PENDING,
});

const diagnoseAccountBulkScanDetailsLoaded  = (data) => ({
  type: types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_LOADED,
  data,
});

const diagnoseAccountBulkScanDetailsError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_ERROR,
  errorMessage,
});

export const getdiagnoseAccountBulkScanDetails = actionCreator(
  getAccountBulkScanDetails,
  diagnoseAccountBulkScanDetailsPending,
  diagnoseAccountBulkScanDetailsLoaded,
  diagnoseAccountBulkScanDetailsError,
);

const diagnoseAccountBulkScanDetailsFiltersPending = () => ({
  type: types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_FILTERS_PENDING,
});

const diagnoseAccountBulkScanDetailsFiltersLoaded  = (data) => ({
  type: types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_FILTERS_LOADED,
  data,
});

const diagnoseAccountBulkScanDetailsFiltersError = (errorMessage) => ({
  type: types.DIAGNOSE_GET_CLIENT_BULK_SCAN_DETAILS_FILTERS_ERROR,
  errorMessage,
});

export const getdiagnoseAccountBulkScanFiltersDetails = actionCreator(
  getAccountBulkScansDetailFilters,
  diagnoseAccountBulkScanDetailsFiltersPending,
  diagnoseAccountBulkScanDetailsFiltersLoaded,
  diagnoseAccountBulkScanDetailsFiltersError,
);
