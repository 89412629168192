import React from 'react';
import { DeleteFilled, InfoCircleFilled } from "@ant-design/icons";
import { Chip, Infotip } from '../../styleguide';
import { Button, Popconfirm } from 'antd';


export function displayErrors(errors) {
  return (
    <div>
      {
        errors.map(m => {
          if (typeof m === 'string') {
            return <div><p>{ m }</p></div>;
          } else {
            return (
              <div className='mult-errors'>
                {
                  m.map((err,i) => {
                    if (i === m.size - 1) {
                      return <p>{ err }</p>;
                    } else {
                      return <p>{`- ${err}`}</p>;
                    }
                  })
                }
              </div>
            );
          }
        })
      }
    </div>
  );
}


export function displayUSPrivacyErrors(regulation,errors) {
  return (
    <>
      <div>{regulation?.name} cannot be activated due to the following reasons:</div>
      <div style={{border:'1px solid grey'}}>
        <div>CONFLICT: 46 property(s) are already present in an Active Regulation.</div>
        <div style={{background: "#F2F2F2",borderRadius: '6px'}}>
        Note: Property can only be mapped to one Active Regulation at a time.
        </div>
        <div style={{background: "#FFFFFF",border: '1px solid #DEDEDE',borderRadius: '6px'}}>
        <div><span>Regulation</span>{regulation?.name}</div>
        <div><span>Properties</span>{errors.map((err => {
            return(<Chip>{err}</Chip>)
        }))}</div>
        </div>
      </div>
    </>
  );
}
export const renderInfoTip = (content, additionalClass, marginLeft = "4px") => {
  const className = `usp-infotip ${additionalClass ?? ''}`
  return (
    <Infotip
      className={className}
      trigger="hover"
      title={null}
      content={content}
    >
      <InfoCircleFilled style={{ marginLeft }} />
    </Infotip>
  )
}

export const wrapInInfotip = (children, content) => {
  const className = `usp-infotip`
  return (
    <Infotip
      className={className}
      trigger="hover"
      title={null}
      content={content}
    >
      {children}
    </Infotip>
  )
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const ConfirmationWrapper = ({actionDescription, deleteHandler, deleteBtn}) => {
  return (
    <Popconfirm
      title={`Are you sure you want to ${actionDescription}?`}
      trigger="click"
      icon={null}
      onConfirm={deleteHandler}
      okText="Yes"
      cancelText="No"
    >
      {deleteBtn}
    </Popconfirm>
  )
}

export function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'instant'});
  }
}
