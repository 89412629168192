import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Flex,
  message,
  Modal,
  Progress,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getdiagnoseAccountBulkScanDetails,
  getdiagnoseAccountBulkScanFiltersDetails,
} from "../../../actions/diagnose_scan_setup";
import { useDispatch, useSelector } from "react-redux";

const { Text } = Typography;

function DiagnoseBulkScanModal({
  accountId,
  bulkModalOpenType,
  bulkData,
  onClose,
}) {
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const scanSetupState = useSelector((store) => store.diagnoseScanSetupState);
  const bulkScanDetails = scanSetupState.getIn([
    "accountBulkScanDetails",
    "value",
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const isTableLoading = scanSetupState.getIn([
    "accountBulkScanDetails",
    "pending",
  ]);
  const bulkScanDetailsFilters = scanSetupState.getIn([
    "bulkScanDetailsFilters",
    "value",
  ]);
  const [tableFilter, setTableFilter] = useState({});
  const [paginationState, setPaginationState] = useState({});

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        await dispatch(
          getdiagnoseAccountBulkScanDetails(
            { accountId },
            { website_id: bulkData }
          )
        );
      } catch (error) {
        console.error("Failed to fetch initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (bulkData) {
      fetchInitialData();
    }
  }, [dispatch, accountId]);

  useEffect(() => {
    const handlePopState = (event) => {
      onClose();
    };

    if (bulkModalOpenType) {
      // Only add history entry when modal opens
      window.history.pushState(
        { modal: true }, // Adding state to track modal
        "",
        window.location.pathname + window.location.search
      );
      window.addEventListener("popstate", handlePopState);
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [bulkModalOpenType]);

  useEffect(() => {
    const fetchBulkScanDetailFilter = async () => {
      try {
        await dispatch(
          getdiagnoseAccountBulkScanFiltersDetails(
            { accountId },
            { website_id: bulkData }
          )
        );
      } catch (error) {
        console.error("Failed to load filters:", error);
        message.error("Failed to load filters for the selected account.");
      }
    };
    if (bulkData) {
      fetchBulkScanDetailFilter();
    }
  }, [bulkData, accountId, dispatch]);

  const showScanUrlModal = (scanData) => {
    const tableData = scanData.scan_urls.map((scanUrlData, index) => ({
      url: scanUrlData.url,
      scan_ids: scanUrlData.id,
      status: scanUrlData.status,
    }));
    const scanTable = [
      {
        title: "Scan ID",
        dataIndex: "scan_ids",
        key: "scan_ids",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Scan URL",
        dataIndex: "url",
        key: "url",
        width: 300,
      },
      {
        title: "Scan Status",
        dataIndex: "status",
        key: "status",
        render: (status) => {
          if (status === "pending")
            return <span className="scan-status-pending">• Pending</span>;
          if (status === "success")
            return <span className="scan-status-successful">• Successful</span>;
          if (status === "failure")
            return <span className="scan-status-failed">• Failed</span>;
          if (status === "disabled") return <span>Disabled</span>;
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (text, record) => {
          const handleCopy = () => {
            const textToCopy = `URL: ${record.url}\nScan ID: ${record.scan_ids}`;
            navigator.clipboard
              .writeText(textToCopy)
              .then(() => {
                message.success("Copied to clipboard successfully!");
              })
              .catch((err) => {
                message.error("Failed to copy to clipboard: ", err);
              });
          };
          return (
            <Tooltip
              onClick={(evt) => evt.stopPropagation()}
              overlayClassName="diagnose--tooltip"
              title={
                <div onClick={(evt) => evt.stopPropagation()}>
                  Copy Scan ID and URL
                </div>
              }
            >
              <Button
                size="small"
                type="link"
                icon={<CopyOutlined />}
                onClick={handleCopy}
              />
            </Tooltip>
          );
        },
      },
    ];

    modal.info({
      title: `Scan IDs & URLs (${scanData.zone} - ${scanData.region})`,
      icon: null,
      width: 1000,
      okButtonProps: { style: { display: "none" } },
      closable: true,
      content: (
        <div>
          <Table
            columns={scanTable}
            dataSource={tableData}
            rowKey="id"
            pagination={false}
            scroll={{ y: 400 }}
          />
        </div>
      ),
    });
  };

  const columns = [
    {
      title: "Region",
      dataIndex: "zone",
      key: "zone",
      filters: bulkScanDetailsFilters?.zones?.map((zone) => ({
        text: zone.trim(),
        value: zone.trim(),
      })),
      filteredValue: tableFilter.zone || null,
      onFilter: (value, record) => record.zone?.trim() === value,
      width: 250,
    },
    {
      title: "Country / State / Province",
      dataIndex: "region",
      key: "region",
      filters: bulkScanDetailsFilters?.regions?.map((region) => ({
        text: region,
        value: region,
      })),
      filteredValue: tableFilter.region || null,
      onFilter: (value, record) => record.region === value,
      render: (text) => <Tag>{text || "N/A"}</Tag>,
      width: 250,
    },
    {
      title: "Consent Category",
      dataIndex: "consent_type",
      key: "consent_type",
      filters: bulkScanDetailsFilters?.consent_types?.map((type) => ({
        text: type,
        value: type,
      })),
      filteredValue: tableFilter.consent_type || null,
      onFilter: (value, record) => record.consent_type === value,
      width: 250,
    },
    {
      title: "Status",
      dataIndex: "overall_status",
      key: "overall_status",
      filters: bulkScanDetailsFilters?.statuses?.map((status) => ({
        text: status,
        value: status,
      })),
      filteredValue: tableFilter.overall_status || null,
      onFilter: (value, record) => record.overall_status === value,
      render: (status) => {
        if (status === "pending") {
          return <span className="scan-status-pending">• Pending</span>;
        }
        if (status === "failed") {
          return <span className="scan-status-failed">• Failed</span>;
        }
        if (status === "successful") {
          return <span className="scan-status-successful">• Successful</span>;
        }
        if (status === "disabled") return <span>Disabled</span>;

        return null;
      },
      width: 250,
    },
    {
      title: "Scan IDs & URLs",
      render: (text, record) => (
        <div className="vendor-details-paramters-btn">
          <button
            className="anchor-button"
            onClick={() => showScanUrlModal(record)}
          >
            View
          </button>
        </div>
      ),
      width: 250,
    },
  ];

  const refreshBulkScanDetailsTable = async (
    page = 1,
    pageSize = 10,
    sortOrder,
    sortField,
    filters = {}
  ) => {
    const sort_order = sortOrder === "ascend" ? 1 : 0;

    const payload = {
      website_id: bulkData,
      page,
      page_size: pageSize,
      sort_order,
      sort_field: sortField || null,
      zones: filters.zones || [],
      region: filters.regions || [],
      scan_ids: filters.scan_ids || [],
      consent_types: filters.consent_types || [],
      statuses: filters.statuses || [],
    };

    try {
      await dispatch(getdiagnoseAccountBulkScanDetails({ accountId }, payload));
    } catch (error) {
      console.error("Failed to load scans:", error);
      message.error("Failed to load scans for the selected account.");
    }
  };

  const handleBulkScanPaginationChange = async (
    pagination,
    filters,
    sorter
  ) => {
    setPaginationState(pagination);
    setTableFilter(filters);
    const normalizedFilters = {
      zones: filters.zone ? filters.zone : [],
      regions: filters.region ? filters.region : [],
      scan_ids: filters.scan_ids ? filters.scan_ids : [],
      consent_types: filters.consent_type ? filters.consent_type : [],
      statuses: filters.overall_status ? filters.overall_status : [],
    };

    try {
      await refreshBulkScanDetailsTable(
        pagination.current,
        pagination.pageSize,
        sorter.order,
        sorter.field,
        normalizedFilters
      );
    } catch (error) {
      console.error("Failed to load scans:", error);
      message.error("Failed to load scans for the selected account.");
    }
  };

  return (
    <Modal
      className="fullscreen-modal scan-setup-form--modal"
      title="Scan Details"
      width={"100vw"}
      open={bulkModalOpenType}
      footer={null}
      closable={true}
      onCancel={() => onClose()}
    >
      <>
        <Flex justify="space-between" align="center">
          <div>
            {isLoading && <Spin tip="Loading..." size="large" fullscreen />}
            <Flex justify="space-between">
              <h4>{bulkScanDetails?.website_stats?.display_name || ""}</h4>
              {bulkScanDetails?.website_stats?.progress_rate === 0 &&
                bulkScanDetails?.bulk_scans?.some(
                  (scan) => scan.overall_status === "pending"
                ) && (
                  <div>
                    | <span className="scan-status-pending">• Pending</span>
                  </div>
                )}
            </Flex>
          </div>
          {bulkScanDetails?.website_stats?.progress_rate > 0 && (
            <div>
              <Flex justify="space-between" align="center">
                <div className="scan-details-progress">
                  <Flex justify="space-between">
                    <span>Progress</span>
                    <h4>
                      {bulkScanDetails?.website_stats?.progress_rate !==
                      undefined
                        ? `${bulkScanDetails.website_stats.progress_rate}%`
                        : ""}
                    </h4>
                  </Flex>
                  <Progress
                    percent={bulkScanDetails?.website_stats?.progress_rate || 0}
                    strokeColor={"#47CD89"}
                    showInfo={false}
                  />
                  <div className="scan-details-count">
                    <Text type="secondary">
                      {bulkScanDetails?.website_stats?.progress_count !==
                        undefined &&
                      bulkScanDetails?.website_stats?.scan_count !== undefined
                        ? `${bulkScanDetails.website_stats.progress_count}/${bulkScanDetails.website_stats.scan_count} scans completed`
                        : ""}
                    </Text>
                  </div>
                </div>
                <div className="scan-details-progress">
                  <Flex justify="space-between">
                    <span>Performance</span>
                    <h4>
                      {bulkScanDetails?.website_stats?.success_rate !==
                      undefined
                        ? `${bulkScanDetails.website_stats.success_rate}%`
                        : ""}
                    </h4>
                  </Flex>
                  <Progress
                    success={{
                      percent:
                        bulkScanDetails?.website_stats?.success_rate || 0,
                      strokeColor: "#47CD89",
                    }}
                    trailColor={
                      bulkScanDetails?.website_stats?.failed_scans > 0
                        ? "#991414"
                        : undefined
                    }
                    showInfo={false}
                  />
                  <Flex justify="space-between" align="center">
                    <Text type="secondary">
                      {bulkScanDetails?.website_stats?.successful_scans !==
                      undefined
                        ? `${bulkScanDetails.website_stats.successful_scans} successful`
                        : ""}
                    </Text>
                    <Text type="secondary">
                      {bulkScanDetails?.website_stats?.failed_scans !==
                      undefined
                        ? `${bulkScanDetails.website_stats.failed_scans} failed`
                        : ""}
                    </Text>
                  </Flex>
                </div>
              </Flex>
            </div>
          )}
        </Flex>

        <Table
          columns={columns}
          dataSource={bulkScanDetails?.bulk_scans}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: bulkScanDetails?.total_count || 0,
            current: paginationState.current,
            pageSize: paginationState.pageSize,
          }}
          loading={{
            spinning: isTableLoading,
            size: "large",
            className: "diagnose-spinning",
          }}
          rowKey="scanId"
          style={{ marginTop: "20px" }}
          onChange={handleBulkScanPaginationChange}
        />

        {contextHolder}
      </>
    </Modal>
  );
}

export default DiagnoseBulkScanModal;
