import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { TreeSelect, Result } from 'antd';
import { message as antMessage } from '../../../styleguide/index.js';
import Loading from '../../common/Loading.js.jsx';
import BuilderModal from '../../v2/builder/BuilderModal.jsx';
import NoticeTable from './NoticeTable/NoticeTable';
import { getAllSites, getSiteGroups } from '../../../actions/site_actions';
import {
  newMessageEndpoints,
  subcategoryMap,
  categoryMap,
  messageEndpoints,
  BACKEND_STATUS_TYPES,
} from '../../../constants';
import { CSRF_TOKEN } from '../../../api/helper';
import { log } from '../../../util';
import { SitesContext, CategoryContext } from '../../v2/contexts';
import { User } from '../../../records/account_records';
import Builder from '../../v2/builder/Builder/index.js';
import NativeOttBuilder from '../../v2/nativeOttBuilder/index.jsx';
import { mockPreferencesLists } from '../../../mocks'

const subcategoryPmMap = {
  'notice_non_iab': 'privacy_manager_non_iab'
};
const defaultId = undefined;

class SysTemplates extends Component {
  static propTypes = {
    currentUser: PropTypes.instanceOf(User).isRequired,
  };

  state = {
    builderVisible: false,
    builderMounted: false,
    templates: [],
    loading: true,
    loaded: 0,
    searchText: '',
    sort: 'All',
    editing: null,
    isPreviewOnly: false,
    messageCategory: 'gdpr',
    messageSubCategory: 'notice',
    selectedConditionsMsg: [],
    selectedConditionsTemp: [],
    selectedConditionsStatus: [],
    showTemplateDropDown: true,
    expandedKeys: [],
    previewSiteId: defaultId,
    draftPMs: [],
    preferencesLists: []
  };

  componentDidMount = () => {
    if (this.props.currentUser) {
      this.getPreferencesLists()
      this.props.getAllSites();
      this.props.getSiteGroups(this.props.currentUser.accountId);
      this.getAllTemplates();
    }
  };

  componentDidUpdate = (oldProps, prevState) => {
    if (!oldProps.currentUser && this.props.currentUser) {
      this.props.getAllSites();
      this.props.getSiteGroups(this.props.currentUser.accountId);
      this.getAllTemplates();
    }
    const differentParams = (oldProps.location.search !== this.props.location.search);
    const differentPath = (oldProps.location.pathname !== this.props.location.pathname);
    if (differentParams || differentPath) {
      this.getPmMessages();
      this.getAllTemplates();
    }
  }

  getPreferencesLists = async () => {
    const preferencesLists = await new Promise(r => r(mockPreferencesLists))
    this.setState({ preferencesLists })
  }

  getPmMessages = async (type = 'draft') => {
    try {
      const siteId = this.state.previewSiteId;
      const { messageSubCategory } = this.state;
      const url = `${messageEndpoints.MESSAGES}?object_status=${BACKEND_STATUS_TYPES[type]}&site_id=${siteId}`;
      const pmMessages = [];
      const allMessages = (await (await fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })).json()).data.message_list;
      allMessages.forEach((msg) => {
        const pmSubCategory = subcategoryPmMap[messageSubCategory] || 'privacy-manager';
        const isPM = (
          msg.message_sub_category_id === subcategoryMap[pmSubCategory]
          && msg.message_category_id === categoryMap.gdpr
        );
        if (isPM) pmMessages.push(msg);
      });

      let newState = {
        loading: false,
        draftPMs: pmMessages
      };

      return this.setState(newState);
    } catch (err) {
      log(err)
      antMessage.error('Could not load messages, try again');
      this.setState({ loading: false });
    }
  }

  deleteMessage = async (messageId, messageName) => {
    try {
      const url = `${newMessageEndpoints.DELETE}?template_id=${messageId}`;
      await fetch(url, {
        method: 'post',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.getAllTemplates().then(() => antMessage.success(`'${messageName}' template deleted`));
    } catch (err) {
      log(err);
      antMessage.error('Could not remove message, try again');
    }
  };

  onExpand = (expandedKeys, { expanded, node }) => {
    this.setState({ expandedKeys });
  };

  onSelect = (selectedKeys, { node }) => {
    const { expandedKeys } = this.state;
    if (node.isLeaf) {
      this.setState({ selectedKeys });
    } else {
      this.setState({
        expandedKeys: node.props.expanded
          ? expandedKeys.filter(k => k !== node.props.eventKey)
          : expandedKeys.concat(node.props.eventKey)
      });
    }
  };

  getAllTemplates = async () => {
    const url = newMessageEndpoints.MESSAGES;
    const templates = await (await (
      await fetch(url, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })
    ).json()).data.message_templates;
    this.setState({ templates: templates, loading: false });
  };

  showBuilder = (message, isPreviewOnly) => {
    let messageCategory;
    let messageSubCategory;
    if (message && (message.message_category_id === 6)) {
      if (message.message_sub_category_id === 2) {
        messageCategory = 'usnat';
        messageSubCategory = 'us_pm';
      } else if (message.message_sub_category_id === 1) {
        messageCategory = 'usnat';
        messageSubCategory = 'usnat_notice';
      }
    } else if (message && message.message_category_id === 7 && message.message_sub_category_id === 1) {
      messageCategory = 'preferences'
      messageSubCategory = 'preferences'
    } else {
      messageCategory = (message != null) ? this.keyByVal(categoryMap, message.message_category_id) : this.state.messageCategory;
      messageSubCategory = (message != null) ? this.keyByVal(subcategoryMap, message.message_sub_category_id) : this.state.messageSubCategory;
    }


    this.setState({
      builderVisible: true,
      builderMounted: true,
      messageCategory,
      messageSubCategory,
      editing: message || null,
      isPreviewOnly,
    });
  }

  closeBuilder = () => this.setState({ builderVisible: false, previewSiteId: defaultId, draftPMs: [] });

  saveMessage = async (messages, _, template_params) => {
    if (messages && this.state.editing) {
      await this.postMessage(messages, 'update', template_params);
    } else if (messages && !this.state.editing) {
      this.postMessage(messages, 'create', template_params);
    }
  };

  hasFeature = feature => {
    const {
      currentUser
    } = this.props;
    return (
      currentUser &&
      (currentUser.accountFeatures.includes(feature))
    );
  };

  postMessage = async (msg, type, template_params) => {
    msg[0].name = msg[0].name || `template: ${moment().format('YYYY-MM-DD')}`;
    const clonedMessage = _.cloneDeep(msg);
    delete clonedMessage[0].siblings;

    const updatedMsg =
      clonedMessage.length > 1
        ? Object.assign(msg[0], { siblings: _.cloneDeep(clonedMessage) })
        : msg[0];


    const { accountId } = this.props.currentUser.toJS();
    const { messageCategory, messageSubCategory } = this.state;

    const description = updatedMsg.name;
    const id = type !== 'create' ? this.state.editing.id : '';
    const category = categoryMap[messageCategory];
    const subCat = subcategoryMap[messageSubCategory];
    const is_system_template = template_params.is_system_template;
    const is_draft = template_params.is_draft;

    let message;
    try {
      const url =
        type === 'create'
          ? `${newMessageEndpoints.CREATE}?name=${encodeURIComponent(description)}&category=${category}&sub_category=${subCat}&is_system=${is_system_template}&is_draft=${is_draft}`
          : `${newMessageEndpoints.UPDATE}?template_id=${id}&name=${encodeURIComponent(description)}&category=${category}&sub_category=${subCat}&is_system=${is_system_template}&is_draft=${is_draft}`;
      message = await (
        await fetch(url, {
          method: 'post',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedMsg)
        })
      ).json();

      if (type === 'create') {
        if (message.status != 'error') {
          antMessage.success(`Template with name '${description}' is created and saved as ${is_draft ? 'draft' : 'public'}`);
          this.closeBuilder();
          this.setState({ loading: false }, () => this.getAllTemplates());
        } else {
          const isDuplicateTemplate = message.msg.includes('already exists');
          const duplicateMsgWarning = isDuplicateTemplate ? <React.Fragment>A template with name <b>{description}</b> already exists. If you wish to save this template, please select a different name or make changes to an existing template.</React.Fragment> : '';
          antMessage.error(
            isDuplicateTemplate ? duplicateMsgWarning : message.msg,
          );
        }
      } else {
        if (message.status != 'error') {
          antMessage.success('Template is updated and saved as draft')
          this.closeBuilder();
          this.setState(
            { loading: false, editing: message },
            () => this.getAllTemplates()
          );
        } else {
          const isDuplicateTemplate = message.msg.includes('already exists');
          const duplicateMsgWarning = isDuplicateTemplate ? <React.Fragment>A template with name <b>{description}</b> already exists. If you wish to save this template, please select a different name or make changes to an existing template.</React.Fragment> : '';
          antMessage.error(
            isDuplicateTemplate ? duplicateMsgWarning : message.msg,
          );
        }
      }
    } catch (err) {
      log(err);
      antMessage.error('Could not save template, try again');
    }
  };

  filterMessages = message => {
    const messageName = message.name.toLowerCase();
    const message_id = message.id.toString();
    return this.state.searchText.length > 0
      ? messageName.includes(this.state.searchText.toLowerCase()) ||
      message_id.includes(this.state.searchText.toLocaleLowerCase())
      : true;
  };

  updateSearch = e => {
    this.setState({ searchText: e.target.value });
  };

  filterByMsgType = templates => {
    let filteredTemplates = [];
    let { selectedConditionsMsg } = this.state;
    selectedConditionsMsg.map((condition) => {
      if (condition === 'adblock') {
        filteredTemplates.push(templates.filter((temp) => {
          let type = this.keyByVal(categoryMap, temp.message_category_id);
          return type === 'adblock';
        }))
      }
      if (condition === 'ccpa') {
        filteredTemplates.push(templates.filter((temp) => {
          let type = this.keyByVal(categoryMap, temp.message_category_id);
          return type === 'ccpa';
        }))
      }
      if (condition === 'gdpr') {
        filteredTemplates.push(templates.filter((temp) => {
          let type = this.keyByVal(categoryMap, temp.message_category_id);
          return type === 'gdpr';
        }))
      }
      if (condition === 'ios') {
        filteredTemplates.push(templates.filter((temp) => {
          let type = this.keyByVal(categoryMap, temp.message_category_id);
          return type === 'ios14';
        }))
      }
      if (condition === 'custom') {
        filteredTemplates.push(templates.filter((temp) => {
          let type = this.keyByVal(categoryMap, temp.message_category_id);
          return type === 'custom';
        }))
      }
      if (condition === 'usnat') {
        filteredTemplates.push(templates.filter((temp) => {
          let type = this.keyByVal(categoryMap, temp.message_category_id);
          return type === 'usnat';
        }))
      }
    })

    templates = (filteredTemplates.length > 0) ? [...new Set(filteredTemplates.reduce((a, b) => [...a, ...b], []))] : templates;
    return templates;
  };

  filterByStatus = (templates) => {
    let filteredTemplates = []
    let { selectedConditionsStatus } = this.state;

    selectedConditionsStatus.map((condition) => {
      if (condition === 'draft') {
        filteredTemplates.push(templates.filter((template) => template.is_draft));
      }
      if (condition === 'public') {
        filteredTemplates.push(templates.filter((template) => !template.is_draft))
      }
    })
    templates = (filteredTemplates.length > 0) ? [...new Set(filteredTemplates.reduce((a, b) => [...a, ...b], []))] : templates;
    return templates;
  }

  filterByTempType = (templates) => {
    let filteredTemplates = []
    let { selectedConditionsTemp } = this.state;

    selectedConditionsTemp.map((condition) => {
      if (condition === 'system') {
        filteredTemplates.push(templates.filter((template) => template.is_system_template))
      }
      if (condition === 'account') {
        filteredTemplates.push(templates.filter((template) => !template.is_system_template))
      }
    })

    templates = (filteredTemplates.length > 0) ? [...new Set(filteredTemplates.reduce((a, b) => [...a, ...b], []))] : templates;
    return templates;
  }

  filterTemplates = (selectedConditionsMsg, selectedConditionsTemp, selectedConditionsStatus) => {
    this.setState({ selectedConditionsMsg, selectedConditionsTemp, selectedConditionsStatus })
  }

  changePublishStatus = async (template, status) => {
    await this.publishStatus(template, !status);
  };

  keyByVal = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  publishStatus = async (template, status) => {
    const accountId = template.account_id;
    const description = template.name;
    const id = template.id;
    const category = template.message_category_id;
    const subCat = template.message_sub_category_id;
    const is_system_template = template.is_system_template;
    const is_draft = status;

    let message;
    try {
      const url = `${newMessageEndpoints.UPDATE}?account_id=${accountId}&template_id=${id}&name=${encodeURIComponent(description)}&category=${category}&sub_category=${subCat}&is_system=${is_system_template}&is_draft=${is_draft}`;
      message = await (
        await fetch(url, {
          method: 'post',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: template.template_json
        })
      ).json();

      if (message.status != 'error') {
        const status = message.data.message_template.is_draft
          ? 'unpublished'
          : 'published';
        this.setState({ loading: false }, () => this.getAllTemplates());
        antMessage.success(`Template is ${status} successfully`);
      } else {
        antMessage.error('Could not publish message, try again');
      }
    } catch (err) {
      log(err);
      antMessage.error('Could not save message, try again');
    }
  };

  setCategory = value => {
    let messageCategory = '';
    let messageSubCategory = '';
    const hasTcfv2 = this.hasFeature('tcf_v2');
    const hasPrivacyManagerOTT = this.hasFeature('privacy_manager_ott');
    switch (value) {
      case 'v2':
        messageCategory = 'gdpr';
        messageSubCategory = 'notice-tcf-v2';
        break;
      case 'gdpr':
        messageCategory = 'gdpr';
        if (hasTcfv2) {
          messageSubCategory = 'notice-tcf-v2';
        } else if (hasPrivacyManagerOTT) {
          messageSubCategory = 'privacy-manager-ott';
        } else {
          messageSubCategory = 'notice_non_iab';
        }
        break;
      case 'ccpa v2':
        messageCategory = 'ccpa';
        messageSubCategory = 'notice';
        break;
      case 'tcfv2':
        messageCategory = 'gdpr';
        messageSubCategory = 'notice-tcf-v2';
        break;
      case 'ccpa':
        messageCategory = 'ccpa';
        messageSubCategory = 'notice';
        break;
      case 'adblock':
        messageCategory = 'adblock';
        messageSubCategory = 'notice';
        break;
      case 'privacy-manager':
        messageCategory = 'gdpr';
        messageSubCategory = 'privacy-manager';
        break;
      case 'sar':
        messageCategory = 'ccpa';
        messageSubCategory = 'sar';
        break;
      case 'dsar':
        messageCategory = 'ccpa';
        messageSubCategory = 'dsarRetrieval';
        break;
      case 'privacy-manager-ott':
        messageCategory = 'gdpr';
        messageSubCategory = 'privacy-manager-ott';
        break;
      case 'notice_non_iab':
        messageCategory = 'gdpr';
        messageSubCategory = 'notice_non_iab';
        break;
      case 'privacy_manager_non_iab':
        messageCategory = 'gdpr';
        messageSubCategory = 'privacy_manager_non_iab';
        break;
      case 'ios':
        messageCategory = 'ios14';
        messageSubCategory = 'ios';
        break;
      case 'custom':
        messageCategory = 'custom';
        messageSubCategory = 'custom';
        break;
      case 'ccpa_pm':
        messageCategory = 'us';
        messageSubCategory = 'ccpa_pm';
        break;
      case 'us_pm':
        messageCategory = 'usnat';
        messageSubCategory = 'us_pm';
        break;
      case 'usnat_notice':
        messageCategory = 'usnat';
        messageSubCategory = 'usnat_notice';
        break;
      case 'usnat':
        messageCategory = 'usnat';
        messageSubCategory = 'usnat_notice';
        break;
      case 'native-ott':
        messageCategory = 'gdpr';
        messageSubCategory = 'native-ott';
        break;
      case 'native-ott-ccpa':
        messageCategory = 'ccpa';
        messageSubCategory = 'native-ott-ccpa';
        break;
      case 'preferences':
        messageCategory = 'preferences'
        messageSubCategory = 'preferences'
        break
    }
    this.setState({ messageCategory, messageSubCategory }, () => {
      this.showBuilder();
    });
  };

  getNewTemplateDropDown = () => {
    const expandedKeys = this.state.expandedKeys;
    let dropdownData = [
      {
        title: 'GDPR',
        value: 'gdpr',
        switcherIcon: (expandedKeys.includes('gdpr')) ? <MinusOutlined /> : <PlusOutlined />,
        children: []
      }
    ]

    const hasPrivacyManagerOTT = this.hasFeature('privacy_manager_ott');
    const hasTcfv2 = this.hasFeature('tcf_v2');
    const hasccpa = this.hasFeature('ccpa');
    const hasusnat = this.hasFeature('usnat');
    const hasNonIab = this.hasFeature('non_iab_vl');
    const hasAppleMessaging = this.hasFeature('apple_messaging');
    const hasAdblockFeature = this.hasFeature('adblock_v2');
    const hasNativeOtt = this.hasFeature('native_ott')
    const hasNativeOttCcpa = this.hasFeature('native-ott-ccpa')


    if (hasTcfv2) {
      const tcfv2Items = [
        {
          title: 'TCF v2 Privacy Manager',
          value: 'privacy-manager',
        },
        {
          title: 'TCF v2 First layer',
          value: 'tcfv2',
        }
      ];
      dropdownData[0].children = dropdownData[0].children.concat(tcfv2Items);
    };

    if (hasPrivacyManagerOTT) {
      const privacyItems = {
        title: 'Legacy OTT',
        value: 'privacy-manager-ott',
      };
      dropdownData[0].children = dropdownData[0].children.concat(privacyItems);
    }

    if (hasNativeOtt) {
      const items = {
        title: 'OTT',
        value: 'native-ott',
      };
      dropdownData[0].children = dropdownData[0].children.concat(items);
    }

    if (hasccpa) {
      const ccpaItems = {
        title: 'U.S. Privacy',
        value: 'ccpa v2',
        switcherIcon: (expandedKeys.includes('ccpa v2')) ? <MinusOutlined /> : <PlusOutlined />,
        children: [
          {
            title: 'First Layer',
            value: 'ccpa',
          },
          {
            title: 'Privacy Manager',
            value: 'ccpa_pm',
          },
          {
            title: 'Subject Access Request Form',
            value: 'sar',
          },
          {
            title: 'DSAR Retrieval Form',
            value: 'dsar',
          },
        ]
      };
      dropdownData = dropdownData.concat(ccpaItems)

      if (hasNativeOttCcpa) {
        const items = {
          title: 'OTT',
          value: 'native-ott-ccpa',
        };
        dropdownData[1].children = dropdownData[1].children.concat(items);

      }
    };

    if (hasusnat) {
      const usItems = {
        title: 'U.S. MultiState Privacy',
        value: 'usnat',
        switcherIcon: (expandedKeys.includes('usnat')) ? <MinusOutlined /> : <PlusOutlined />,
        children: [
          {
            title: 'Privacy Manager',
            value: 'us_pm',
          },
          {
            title: 'First Layer',
            value: 'usnat_notice',
          },
        ]
      };
      dropdownData = dropdownData.concat(usItems);
    };

    if (hasAdblockFeature) {
      const adblockOption = {
        title: 'Adblock',
        value: 'adblock',
      };
      dropdownData.push(adblockOption);
    };

    if (hasAppleMessaging) {
      const iOSOption = {
        title: 'iOS Tracking',
        value: 'ios'
      };
      dropdownData.push(iOSOption);
    };

    const customOption = {
      title: 'Custom Message',
      value: 'custom'
    };
    dropdownData.push(customOption);

    const preferencesOption = {
      title: 'Universal Consent and Preferences',
      value: 'preferences'
    }
    dropdownData.push(preferencesOption);

    if (hasNonIab) {
      const nonIabSubmenuItems = [
        {
          title: 'Non IAB First layer',
          value: 'notice_non_iab',
        },
        {
          title: 'Non IAB Privacy Manager',
          value: 'privacy_manager_non_iab',
        },
      ];
      dropdownData[0].children = dropdownData[0].children.concat(nonIabSubmenuItems);
    };

    if (!hasPrivacyManagerOTT && !hasTcfv2 && !hasNonIab) {
      dropdownData.shift(); //remove GDPR from dropdown altogether
    };

    return dropdownData;

  }

  setPropertyPreview = (siteId) => {
    this.setState({ previewSiteId: Number(siteId) }, () => this.getPmMessages());
  }

  render() {
    if (!this.props.currentUser) return null;

    const pmMessages = [...this.state.draftPMs];
    const loading = this.state.loading;
    const {
      currentUser,
    } = this.props;
    let templates =
      currentUser.accountId !== 22
        ? this.state.templates.filter(
          template => template.is_system_template === false
        )
        : this.state.templates;

    templates = templates.filter(this.filterMessages);
    templates = this.filterByMsgType(templates);
    templates = this.filterByStatus(templates);
    templates = this.filterByTempType(templates);

    const { messageCategory, messageSubCategory } = this.state;
    const sites = this.props.allSites;
    const newTemplateDropDown = this.getNewTemplateDropDown();

    const isAccessToTemplates = (currentUser.featureAccess && currentUser.featureAccess.size && currentUser.featureAccess.includes('templates')) ||
      !currentUser.featureAccess;
    if (!isAccessToTemplates) {
      return <Result
        className="system-templates-error"
        status="error"
        title="403"
        subTitle="Sorry, you are not authorized to access system templates page."
      />;
    }

    const builder = (messageSubCategory === 'native-ott' || messageSubCategory === 'native-ott-ccpa')
      ? (
        <BuilderModal
          accountId={currentUser.accountId}
          messageCategory={messageCategory}
          messageSubcategory={messageSubCategory}
        >
          {({
            createTemplate,
            deleteTemplate,
            sites,
            previewSites,
            templates,
          }) => (
            <NativeOttBuilder
              isTemplate
              accountId={currentUser.accountId}
              messageToEdit={this.state.editing}
              closeBuilder={this.closeBuilder}
              saveMessage={this.saveMessage}
              messageCategory={messageCategory}
              // currentUser={currentUser}
              isPreviewOnly={this.state.isPreviewOnly}
              setPropertyPreview={this.setPropertyPreview}
              siteId={this.state.previewSiteId}
              createTemplate={createTemplate}
              deleteTemplate={deleteTemplate}
              sitesUnderSg={sites}
              previewSites={previewSites}
              templates={templates}              
            />
          )}
        </BuilderModal>
      )
      : (

        <BuilderModal
          accountId={currentUser.accountId}
          messageCategory={messageCategory}
          messageSubcategory={messageSubCategory}
        >
          {({
            createTemplate,
            deleteTemplate,
            sites,
            previewSites,
            templates,
          }) => (
            <Builder
              isTemplate
              messageCategory={messageCategory}
              messageSubcategory={messageSubCategory}
              editing={this.state.editing}
              currentUser={currentUser}
              exit={this.closeBuilder}
              saveMessage={this.saveMessage}
              isPreviewOnly={this.state.isPreviewOnly}
              setPropertyPreview={this.setPropertyPreview}
              pmMessages={pmMessages}
              createTemplate={createTemplate}
              deleteTemplate={deleteTemplate}
              sitesUnderSg={sites}
              previewSites={previewSites}
              templates={templates}
              preferencesLists={this.state.preferencesLists}
              siteId={this.props.siteId}
            />
          )}
        </BuilderModal>
      )

    return (
      <div id="messages-v2" className="messages-v2">
        {loading ? (
          <Loading />
        ) : (
          <SitesContext.Provider value={{
            sites: [],
            isSiteGroup: false,
            siteGroupId: defaultId,
            previewSites: sites
          }}>
            <React.Fragment>
              <div className="nt-header">
                <div className="nt-title nt-title-template">{'Templates'}</div>
                <div className="nt-right">
                  {newTemplateDropDown.length > 0 && <div className="btn-multiSelect-wrapper">
                    <TreeSelect
                      value="New Template"
                      treeData={newTemplateDropDown}
                      switcherIcon={<PlusOutlined />}
                      virtual={false}
                      onTreeExpand={(expandedKeys) => this.setState({ expandedKeys })}
                      onChange={value => this.setCategory(value)} />
                  </div>}
                </div>
              </div>
              <NoticeTable
                accountId={currentUser.accountId}
                showBuilder={this.showBuilder}
                templates={templates}
                updateSearch={this.updateSearch}
                deleteMessage={this.deleteMessage}
                changePublishStatus={this.changePublishStatus}
                searchText={this.state.searchText}
                messageSubCategory={messageSubCategory}
                messageCategory={messageCategory}
                filterTemplates={this.filterTemplates}
                isPreviewOnly
                currentUser={currentUser}
              />
              {this.state.builderMounted && this.state.builderVisible && (
                <CategoryContext.Provider value={messageCategory}>
                  {builder}
                </CategoryContext.Provider>
              )}
            </React.Fragment>
          </SitesContext.Provider>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ siteState, accountState }) => {
  return {
    currentUser: accountState.getIn(['userDetails', 'value']),
    allSites: siteState.getIn(['sites', 'value']),
    allSiteGroups: siteState.getIn(['siteGroups', 'value']),
  };
};

export default connect(mapStateToProps, {
  getAllSites,
  getSiteGroups
})(SysTemplates);
