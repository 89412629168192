import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Category, Configuration } from "../../../records/preferences_records.js";
import moment from 'moment';
import { List, Map } from 'immutable';
import { Alert, message, RenderLimitedChips } from '../../../styleguide';
import DismissErrorModal from '../../common/DismissErrorModal';
import Loading from '../../common/Loading.js';
import { CloseOutlined,  LeftOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { getSiteGroups, getAllSites } from '../../../actions/site_actions.js';
import { clearPreferenceConfigurationError, createPreferenceConfiguration, getAllLatestLegalDocuments, getAllPreferenceConfiguration, getAllVendors, getPreferenceCampaignTypes } from '../../../actions/preferences_actions.js';
import { browserHistory } from 'react-router';
import { findNthTermInAnEntityList } from '../../utils.js';
import { Modal, Typography, Button, theme, Space, Row, Col, Input, Flex, Divider, Tag } from 'antd';
import EditModal from './EditModal.jsx';
import { catTypeToLabelMap, preferences_steps, valideAllStepsBeforeSave } from './helper.js';
import CategoriesTable from './Screens/AddCategories/CategoriesTable.jsx';
import AddCategoryModal from './Screens/AddCategories/AddCategoryModal.jsx';

const {Title, Text} = Typography;

const CreateConfigNew = (props) => {
  const [openExitWizardModal, setOpenExitWizardModal] = useState(false);
  const [configuration, setConfiguration] = useState(new Configuration({}));
  const [editEntity, setEditEntity] = useState(null);
  const [showError, setShowError] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [defaultTabOnEditModal, setShowDefaultTabOnEditModal] = useState('');

  useEffect(() => {
    if(props.preferenceConfigurationServerSideError && typeof props.preferenceConfigurationServerSideError !== "string"){
        let content = "Something went wrong"; 
        if(props.preferenceConfigurationServerSideError?.response?.status == 500){
          Modal.error({
            title: "Failed to save Configuration",
          })
          return;
        }
        const errors = props.preferenceConfigurationServerSideError?.get('errors');
        const validationError = props.preferenceConfigurationServerSideError?.get('err');
        if(errors?.size){
          content = <ul>{errors.map((error) => <li>{error.msg}</li>)}</ul>
        } else if(validationError){
          content = validationError
        }
        Modal.error({
          title: "Failed to save Configuration",
          content: content
        })
    }
    return ()=>{
      props.clearPreferenceConfigurationError();
    }
  },[props.preferenceConfigurationServerSideError]);


  useEffect(()=>{
    (async () => {
      try {
        if(props.currentUser){
          props.getSiteGroups(props.currentUser);
          props.getAllPreferenceConfiguration(props.currentUser?.accountId);
          props.getAllLatestLegalDocuments();
          props.getPreferenceCampaignTypes();
          setConfiguration(configuration.set("accountId", props.currentUser?.accountId))
        }
        props.getAllSites();
        props.getAllVendors();
      } catch(err) {
        console.log(err)
      }
    })();
  }, [props.currentUser]);
  

  useEffect(()=>{
    if(!props.pendingRequestsMap.get("preferenceConfigurationNamesList") && props.preferenceConfigurationNamesList.size && !Boolean(configuration.get("name"))){
      setConfiguration((configuration) => configuration.set("name", findNthTermInAnEntityList(props.preferenceConfigurationNamesList, "New Configuration")))
    }
  },[props.preferenceConfigurationNamesList])

  const addCategory = (newCategory) => {
    setConfiguration((configuration) => configuration.update("categories", (categories) =>
      categories.push(newCategory)
    ))
  }

  const managePreferencesModalOnLoad = (defaultTab) => {
    setEditEntity("manage_preferences");
    setShowDefaultTabOnEditModal(defaultTab);
  }

  const handleSave = async () => {
    const stepsWithError = valideAllStepsBeforeSave(preferences_steps, configuration?.toJS(), props.preferenceConfigurationNamesList);
    if(stepsWithError.length === 0 ) {
      try {
        const resp = await props.createPreferenceConfiguration(configuration);
  
        if (resp?.name) {
          message.success(
            <>
              Configuration <b>{resp.name}</b> has been successfully created
            </>
          );
          browserHistory.push(`/preferences/edit?configurationId=${resp.id}`);
        }
      } catch (error) {
        // Handle error if needed
      }
    } else {
      setShowError(stepsWithError)
    }

  };  

  const { token } = theme.useToken();

  const saveConfiguration = (config) => {
    setConfiguration(config);
    setEditEntity(null);
  }
  const configName = configuration.get('name')
  const configurationNameError = showError.includes("name") && configName.trim().length === 0 ? "Please enter Configuration Name" : props.preferenceConfigurationNamesList?.includes(configName.trim()) ? <>Configuration with name <b>{configName}</b> already exists</> : null
  const propertiesChips = configuration?.propertyIds?.map(id => <Tag>{props.sitesNameMap?.get(String(id))}</Tag>);
  const hasNoProperties = configuration?.get("propertyIds")?.size < 1;

  const legalTransactionCats = configuration?.categories?.filter((cat) => cat.type === "LEGAL-TRANSACTION");
  const marketingPreferencesCats = configuration?.categories?.filter((cat) => cat.type === "MARKETING");

  return configuration ? (
  <>
    <div className="new-us-privacy-regulations-wizard create-preferences-config">
      <div className="header">
        <Typography.Title level={4} style={{margin: 0}}>Configurations Setup</Typography.Title>
        <Button className='close-wizard-btn' icon={<CloseOutlined/>} onClick={()=>setOpenExitWizardModal(true)}/>
      </div>
      <div className="content">
        <div className="layout">
          <Row gutter={[0, token.marginMD]}>
            <Col span={24}>
              <Space size={token.marginXXS}>
                <Button icon={<LeftOutlined />} type="text" size="large" onClick={()=>setOpenExitWizardModal(true)}/>
                <Typography.Title level={4}>Add Configuration</Typography.Title>
              </Space>
            </Col>
            <Col span={24}>
            <Space block direction="vertical" size={token.marginXXS}>
              <Typography.Text>Configuration Name</Typography.Text>
              <Input
                style={{width: token.controlHeight * 10}}
                placeholder="Enter Name"
                value={configuration.get("name")}
                onChange={({target: {value}}) => {
                  setConfiguration((config) => config.set('name', value));
                }}
                status={configurationNameError ? "error" : null}
                maxLength={60}
                showCount={true}
              />
              {configurationNameError ? <Typography.Text type='danger'>{configurationNameError}</Typography.Text> : null}
            </Space>
            </Col>
            <Col span={24}>
              <div className="edit-preferences-container">
                <Space direction="vertical" size={token.marginSM}>
                  <Flex justify="space-between">
                    <Typography.Title level={5} style={{marginBottom: 0}}>Property ({configuration.propertyIds.size})</Typography.Title>
                    {hasNoProperties ? null : (
                      <Button size={"small"}  icon={<EditOutlined/>} type="link" onClick={() => setEditEntity("properties")}/>
                    )}
                  </Flex>
                  <Divider/>
                  {hasNoProperties ? (
                    <div className="empty-div">
                      <Typography.Title level={5} type="secondary">
                        Start by adding Properties
                      </Typography.Title>
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => setEditEntity("properties")}
                      >
                        {" "}
                        Add Property
                      </Button>
                      {showError.includes("properties") && hasNoProperties ? (
                        <Typography.Text type="danger" strong>
                          {"Please add at least one property"}
                        </Typography.Text>
                      ) : null}
                    </div>
                    ) : (
                    <RenderLimitedChips chips={propertiesChips?.toJS()} cutOff={15}/>
                    )
                  }
                </Space>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <Typography.Title level={5} style={{marginBottom: token.margin}}>Categories</Typography.Title>
                <div className="edit-preferences-container">
                  <Space direction="vertical" size={token.marginSM}>
                    <Flex justify="space-between">
                      <Title style={{marginBottom: 0}} level={5}>Legal Transactions</Title>
                      {legalTransactionCats.size >= 1 ? (
                        <Space size={token.marginSM}>
                          <Button type="primary" onClick={() => setEditEntity("manage_transactions")}>Manage Categories</Button>
                        </Space>
                      ) : null}
                    </Flex>
                    {legalTransactionCats.size < 1 ? (
                      <div className="empty-div">
                        <Typography.Title level={5} type="secondary">
                          Start by adding your first {catTypeToLabelMap["LEGAL-TRANSACTION"]}
                        </Typography.Title>
                        <Button
                          icon={<PlusOutlined />}
                          type="primary"
                          onClick={() => setShowAddCategoryModal("LEGAL-TRANSACTION")}
                        >
                          {" "}
                          Add Category
                        </Button>
                      </div>
                    ) : (
                    <CategoriesTable
                      readOnly
                      dataSource={legalTransactionCats?.toJS()}
                      columnsToDisplay={["Name", "Type", "Legal Document", "Default User State"]}
                    />)}
                  </Space>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <div className="edit-preferences-container">
                  <Space direction="vertical" size={token.marginSM}>
                  <Flex justify="space-between">
                    <Title style={{marginBottom: 0}} level={5}>Marketing Preferences</Title>
                    {marketingPreferencesCats.size >= 1 ? (
                      <Space size={token.marginSM}>
                        <Button onClick={() => managePreferencesModalOnLoad("int_mapping")} disabled={!Boolean(marketingPreferencesCats.size)} title={!Boolean(marketingPreferencesCats.size) ? "No Marketing Prefernce Category added" : null}>Manage Connections</Button>
                        <Button type="primary" onClick={() =>  managePreferencesModalOnLoad("categories")}>Manage Categories</Button>
                      </Space>
                    ) : null}
                  </Flex>
                  {marketingPreferencesCats.size < 1 ? (
                    <div className="empty-div">
                      <Typography.Title level={5} type="secondary">
                        Start by adding your first {catTypeToLabelMap["MARKETING"]}
                      </Typography.Title>
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => setShowAddCategoryModal("MARKETING")}
                      >
                        {" "}
                        Add Category
                      </Button>
                    </div>
                    ) : (
                    <CategoriesTable
                      readOnly
                      dataSource={marketingPreferencesCats?.toJS()}
                      columnsToDisplay={["Name", "Channels", "Default User State", "Connections"]}
                    />
                  )}
                  </Space>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="header">
        {showError.includes("manage_preferences") ? <Alert style={{width: "100%"}} showIcon message={<div>To save the configuration, you need to add atleast one category in transactions or preferences.</div>} type="warning"/> : <div></div>}
        <Space>
          <Button onClick={()=>setOpenExitWizardModal(true)}>Cancel</Button>
          <Button type='primary' onClick={handleSave} disabled={configurationNameError || hasNoProperties}>Save</Button>
        </Space>
      </div>
    </div>
    {editEntity ? (
      <EditModal
        title={null}
        modalWrapperClass="activate-confirmation"
        saveConfiguration={saveConfiguration}
        cancelEdit={() => setEditEntity(null)}
        configuration={configuration}
        edit={editEntity}
        defaultTab={defaultTabOnEditModal}
        sites={props.sites}
        siteGroups={props.siteGroups}
        preferenceConfigurationNamesList={props.preferenceConfigurationNamesList.filter( name => name != configuration.name)}
      />) : null}
    {showAddCategoryModal ? (
      <AddCategoryModal
        catType={showAddCategoryModal}
        existingCategories={showAddCategoryModal === "LEGAL-TRANSACTION" ? legalTransactionCats : showAddCategoryModal === "MARKETING" ? marketingPreferencesCats : null}
        showAddCategoryModal={showAddCategoryModal}
        handleCancel={() => setShowAddCategoryModal(false)}
        addCategory={addCategory}
        mode={"Add"}
        currentCategory={new Category({})}
        initialValues={{ name: "", description: "", channels: [] }}
      />
    ) : null}
    { openExitWizardModal ? 
    <DismissErrorModal
      modalWrapperClass="activate-confirmation"
      title={"Exit Configuration"}
      error={<><div>All the data will be lost if you exit the configuration without saving it.<br/>Are you sure you want to exit now?</div></>}
      isModalVisible={openExitWizardModal}
      handleCancel={()=>setOpenExitWizardModal(false)}
      renderOk
      okText={"Exit"}
      cancelText={"Cancel"}
      handleOk={()=>{browserHistory.push("/preferences/"); setOpenExitWizardModal(false)}}
      primaryAction="submit"
    /> : null}
  </>) : <Loading/>;
};

const mapStateToProps = function (store) {
  return {
  currentUser: store.accountState.getIn(['userDetails', 'value']),
  sitesNameMap: store.siteState.getIn(['sitesNameMap', 'value']),
  sites: store.siteState.getIn(['sites', 'value'])?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')),
  siteGroups: store.siteState
    .getIn(['siteGroups', 'value'])
    .sort(
      (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
    ),
  preferenceConfigurationNamesList: store.preferencesState.getIn(['preferenceConfigurationList', 'value'])?.map((con) => con.name) ?? List([]),
  preferenceConfigurationServerSideError: store.preferencesState.getIn(["preferenceConfigurationList", "error"]),
  pendingRequestsMap: Map({
    savePending: store.preferencesState.get('savePending'),
    sites: store.siteState.getIn(['sites', 'pending']),
    siteGroups: store.siteState.getIn(['siteGroups', 'pending']),
    preferenceConfigurationNamesList: store.preferencesState.getIn(['preferenceConfigurationList', 'pending'])
  }),
  };
};

export default connect(mapStateToProps, {
  getSiteGroups,
  getAllSites,
  getAllVendors,
  getAllPreferenceConfiguration,
  createPreferenceConfiguration,
  clearPreferenceConfigurationError,
  getAllLatestLegalDocuments,
  getPreferenceCampaignTypes,
})(CreateConfigNew);