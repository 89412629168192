import { Radio } from '../../../../../../../styleguide';
// import { Radio } from 'antd';

import React from 'react';

const RadioSelector = ({
  settingKey,
  settingsTitle,
  updateSetting,
  value,
}) => {
  const settingsMap = { 
    toggleType: {
      slider: 'Default Toggle',
      onOff: 'On/Off Toggle',
      checkbox: 'Checkbox',
      none: 'No Selection',
    }, 
    togglePosition: {
      right: 'Right',
      left: 'Left',
    },
  };
  console.log('settingKey: ', settingKey)
  console.log('settingsMap[settingKey]: ', settingsMap[settingKey])

  return (
    <div className="set-type">
      <h4>{settingsTitle}</h4>
      <div>
        <Radio.Group value={value} onChange={ (e) => updateSetting(settingKey, e.target.value)}>
          {Object.entries(settingsMap[settingKey]).map(([val, name]) => (
            <Radio value={val}>{name}</Radio>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default RadioSelector;

