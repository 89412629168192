import {
  Alert,
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import CSVLink from "../../common/CSVLink.js";
import { List } from "immutable";
import { useDispatch, useSelector } from "react-redux";
import {
  getSitemapTemplateCsv,
  uploadSitemapCsv,
} from "../../../api/diagnose_dashboard/scan_setup.js";
import { set } from "react-immutable-proptypes";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { Text } = Typography;

const requiredFields = [
  "Property (Display Name)",
  "Sourcepoint account ID",
  "Scan URL",
  "Region",
  "Consent Types",
  "Frequency(weekly/monthly)",
];

function UploadSiteMapModal({
  accountId,
  uploadCsvModalType,
  onClose,
  refreshBulkAccountScans,
}) {
  const [errors, setErrors] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [submittable, setSubmittable] = useState(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const validateCsvContent = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv = e.target.result;
        const rows = csv.split(/\r\n|\n/);
        const urlCount = rows.slice(1).filter((row) => {
          const columns = row.split(",");
          const scanUrl = columns[2]?.trim();
          return scanUrl && scanUrl.startsWith("http");
        }).length;
        const errorMessage = [];
        if (urlCount > 300) {
          errorMessage.push(
            `The file contains ${urlCount} URLs, which exceeds the maximum limit of 300. Please reduce the number of URLs and re-upload.`
          );
        }

        resolve(errorMessage);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUploadCsv = async (file) => {
    const validationErrors = await validateCsvContent(file);
    setErrors(validationErrors);
    setUploadedFile(file);
    return false;
  };

  const uploadProps = {
    name: "file",
    accept: ".xml",
    maxCount: 1,
    beforeUpload: beforeUploadCsv,
  };

  const handleSave = async () => {
    if (!uploadedFile) {
      message.error("Please upload a CSV file before saving.");
      return;
    }

    if (errors.length > 0) {
      message.error("Please fix the errors in your file and re-upload.");
      return;
    }

    try {
      setIsUploading(true);
      const response = await uploadSitemapCsv(uploadedFile);
      message.success("File uploaded successfully!");
      refreshBulkAccountScans();
      onClose(); // Close the modal
    } catch (error) {
      console.error("Upload failed:", error);
      message.error("Failed to upload the file.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (values) => {
    const url = values.url;
    try {
      const response = await getSitemapTemplateCsv({ accountId, url });

      if (response) {
        const blob = new Blob([response], { type: "text/csv" });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);

        link.download = "sitemap_template.csv";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        message.success("CSV file downloaded successfully");
      } else {
        message.error("Provided URL not a valid Sitemap");
      }
    } catch (error) {
      console.error("Failed to load data:", error);
      message.error("Failed to get data for the required sitemap");
    }
  };

  return (
    <Modal
      title="Upload Site Map"
      open={!!uploadCsvModalType}
      okText="Save"
      onOk={handleSave}
      onCancel={onClose}
      confirmLoading={isUploading}
      okButtonProps={{
        disabled: errors.length > 0,
      }}
    >
      <div>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <span>Link</span>
          </Col>
          <Form name="upload-sitemap-url" onFinish={handleSubmit} form={form}>
            <Flex align="center" justify="space-between" gap={10}>
              <Form.Item
                name="url"
                rules={[
                  {
                    validator: (_, value) => {
                      const xmlUrlPattern =
                        /^(https?:\/\/)[\w.-]+(\.[\w.-]+)+\/?.*\.xml$/i;
                      if (!xmlUrlPattern.test(value)) {
                        return Promise.reject(
                          new Error("Please enter a valid .xml URL!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Paste sitemap link"
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!submittable}
                >
                  Download CSV File
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </Row>
        <Row style={{ marginTop: "8px" }}>
          <span>Import File</span>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={24}>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file(s) to this area to upload
              </p>
            </Dragger>
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }}></div>
        {errors.length > 0 && (
          <div style={{ marginTop: "50px" }}>
            <Alert
              message={
                <Row>
                  <Col span={24}>
                    <strong>
                      {uploadedFile?.name}:Maximum URL limit exceeded
                    </strong>
                  </Col>
                  <Col span={24}>
                    <Text>{errors}</Text>
                  </Col>
                </Row>
              }
              type="error"
              showIcon
              closable
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UploadSiteMapModal;
