import { Select } from "antd"
import React, {
  useContext
} from 'react';

import { SitesContext } from '../../../../../contexts';

const PreferencesPreviewSelect = ({
  siteId,
  preferencesLists,
  updateSetting,
  value,
  settingKey,
}) => {

  const { sites, isSiteGroup } = useContext(SitesContext);


  const siteList = isNaN(siteId) ? [] : [+siteId]; 
  const siteIds = isSiteGroup ? sites.map(({ id }) => id).toJS() : siteList;

    const selected = preferencesLists.find(({ id }) => id === value)
  || preferencesLists.find(({ propertyIds, isActive }) => propertyIds.some(id => siteIds.includes(id)) && isActive)
  || (preferencesLists.find(({ propertyIds }) => propertyIds.some(id => siteIds.includes(id))) 
      ? preferencesLists.find(({ propertyIds }) => propertyIds.some(id => siteIds.includes(id))) 
      : null);

  return (
    <div className="four-options">
      <Select
        style={{ width: '100%' }}
        value={selected.id}
        onChange={(id) => updateSetting(settingKey, id)}
      >
        {preferencesLists.filter(({ propertyIds, isActive }) => propertyIds.some(id => siteIds.includes(id)) && isActive).map(({ id, name }) => (
          <Select.Option
            key={id}
            id={id}
          >
            {name}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default PreferencesPreviewSelect