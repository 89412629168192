import React, { useState } from "react";
import { Typography, Button, Modal, Space, Flex, Divider, Input } from "antd";
import { message } from "../../../../../styleguide";
import { wrapInInfotip } from "../../../helper";
import { DeleteOutlined, ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import AddCategoryModal from "./AddCategoryModal";
import { Category } from "../../../../../records/preferences_records";
import CategoriesTable from "./CategoriesTable";
import { useDebouncedSearch } from "../../../../../hooks";
import { catTypeToLabelMap } from "../../helper";

const AddCategories = (props) => {
  const { configuration, categories, updateConfiguration, readOnly } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(new Category({}));
  const [mode, setMode] = useState(null);
  const [openBulkDeleteConfirmation, setOpenBulkDeleteConfirmation] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleCancel = () => {
    setShowAddCategoryModal(false);
    setCurrentCategory(new Category({}));
  };

  const handleShowAddcategory = (mode, record) => {
    const currentCategory = categories.find(
      (cat) => cat.get("categoryId") == record.categoryId
    );
    setCurrentCategory(currentCategory ?? new Category({}));
    setShowAddCategoryModal(true);
    setMode(mode);
  };

  const bulkDelete = (selectedRowKeys) => {
    if (selectedRowKeys.length === 0) {
      message.error("Please select rows to delete.");
      return;
    }
    let updatedCategories;
    let updatedConfiguration = categories;

    let tempIds = selectedRowKeys.filter((id) => id.includes("temp_id_"));
    updatedCategories = updatedConfiguration.filter(
      (cat) => !tempIds.includes(cat.get("categoryId"))
    );

    let existingIds = selectedRowKeys.filter((id) => !id.includes("temp_id_"));
    updatedCategories = updatedCategories.map((category) => {
      if (existingIds.includes(category.get("categoryId"))) {
        return category.set("dateDeleted", new Date().toISOString());
      }
      return category;
    });

    updatedConfiguration = configuration.set("categories", updatedCategories);
    updateConfiguration(updatedConfiguration);
    setSelectedRowKeys([]);
  };

  const addCategory = (newCategory, mode) => {
    const categoryIndex = categories.findIndex(
      (cat) => cat.get("categoryId") === newCategory.get("categoryId")
    );

    let updatedConfiguration;
    if (categoryIndex !== -1) {
      updatedConfiguration = configuration.updateIn(
        ["categories", categoryIndex],
        () => newCategory
      );
    } else {
      updatedConfiguration = configuration.update("categories", (categories) =>
        categories.push(newCategory)
      );
    }
    updateConfiguration(updatedConfiguration);
  };

  const deleteCategory = (cat) => {
    const catId = cat?.categoryId?.toString();
    if (catId) {
      const isTempId = catId?.includes("temp_id_");
      let updatedCategories;
      let updatedConfiguration;
      if (isTempId) {
        updatedCategories = categories.filter(
          (category) => category.get("categoryId") !== cat?.categoryId
        );
        updatedConfiguration = configuration.set(
          "categories",
          updatedCategories
        );
      } else {
        let existingCategoryToBeDeleted = cat;
        existingCategoryToBeDeleted.dateDeleted = new Date().toISOString();
        let categoryIndex = categories.findIndex(
          (cat) =>
            cat.get("categoryId") === existingCategoryToBeDeleted.categoryId
        );
        updatedConfiguration = configuration.updateIn(
          ["categories", categoryIndex],
          (category) => category.set("dateDeleted", new Date().toISOString())
        );
      }
      setSelectedRowKeys((selectedRowKeys) => selectedRowKeys.filter((key) => key !== catId))
      updateConfiguration(updatedConfiguration);
    }
  };

  const currentCategories = categories.filter(
    (item) => !item.get("dateDeleted") && item.type === props.catType
  );

  let categoriesData = currentCategories?.toJS().map((item, index) => ({
    ...item,
    key: item.categoryId || index,
  }));

  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);
  let dataSource = searchValue.trim().length
    ? _.cloneDeep(categoriesData)?.filter(
        (vendor) =>
          vendor.name
            .toLowerCase()
            .indexOf(searchValue.trim().toLowerCase()) !== -1
      )
    : _.cloneDeep(categoriesData);
  const disableBulkActions = selectedRowKeys?.length === 0

  let columnsToDisplay = [];
  let sectionTitle = "";
  let sectionDescription = "";
  switch (props.catType) {
    case "LEGAL-TRANSACTION":
      columnsToDisplay = [
        "Name",
        "Type",
        "Legal Document",
        "Default User State",
        "Action",
      ];
      sectionTitle = "Legal Transactions";
      sectionDescription =
        "Legal transaction categories are the consent or legal categories that are displayed to the user in your universal consent and preference message. Create your categories and map the relevant legal documents to them.";
      break;
    case "MARKETING":
      columnsToDisplay = [
        "Name",
        "Channels",
        "Default User State",
        "Action",
      ];
      sectionTitle = "Marketing Preferences";
      sectionDescription =
        "Marketing preference categories are the subscriptions, topics, or preferences that are displayed to the user in your universal consent and preference message. Create your categories and choose the relevant channels to display to users.";
      break;
  }

  if (props.onEditScreen) {
    // columnsToDisplay.unshift("ID");
    columnsToDisplay.splice(columnsToDisplay.length - 1, 0, "Description");
  }

  return (
    <>
      {props.onEditScreen ? (
        <div className="step-breif">{sectionDescription}</div>
      ) : null}
      <div className={`${props.showError ? "validation-error" : null}`}>
        <>
          <div className="table-action-section">
            <div className="search-container">
              {props.searchEnabled ? (
                <Input.Search
                  placeholder="Search"
                  type="search"
                  onChange={({ target: { value } }) => {
                    debouncedChangeHandler(value);
                  }}
                  style={{ width: "320px" }}
                />
              ) : (
                <Typography.Title style={{ marginBottom: 0 }} level={5}>
                  {sectionTitle}
                </Typography.Title>
              )}
            </div>
            {currentCategories.size >= 1 ? (
              <Flex align="center">
                <Typography.Text
                  disabled={disableBulkActions}
                  style={{ marginRight: `var(--ant-margin-xs)` }}
                >
                  Bulk actions:
                </Typography.Text>
                {wrapInInfotip(
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    disabled={disableBulkActions}
                    onClick={() => setOpenBulkDeleteConfirmation(true)}
                  />,
                  "Remove Categories"
                )}
                <Divider type="vertical" orientation="center" />
                <Button
                  className="btn"
                  type="primary"
                  onClick={() => handleShowAddcategory("Add", {})}
                >
                  + Add {catTypeToLabelMap[props.catType]} Category
                </Button>
              </Flex>
            ) : null}
          </div>
          {currentCategories.size < 1 ? (
            <div className="empty-div">
              <Typography.Title level={5} type="secondary">
                Start by adding your first {catTypeToLabelMap[props.catType]}
              </Typography.Title>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => handleShowAddcategory("Add", {})}
              >
                {" "}
                Add Category
              </Button>
              {props.showError ? (
                <Typography.Text type="danger" strong>
                  {"Please add at least one category"}
                </Typography.Text>
              ) : null}
            </div>
          ) : (
            <CategoriesTable
              dataSource={dataSource}
              deleteCategory={deleteCategory}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              columnsToDisplay={columnsToDisplay}
              handleShowAddcategory={handleShowAddcategory}
            />
          )}
        </>
      </div>
      {showAddCategoryModal ? (
        <AddCategoryModal
          catType={props.catType}
          existingCategories={currentCategories}
          showAddCategoryModal={showAddCategoryModal}
          handleCancel={handleCancel}
          addCategory={addCategory}
          mode={mode}
          currentCategory={currentCategory}
          initialValues={{ name: "", description: "", channels: [] }}
          onEditScreen={props.onEditScreen}
        />
      ) : null}
      <Modal
        open={openBulkDeleteConfirmation}
        title={null}
        okText={"Yes"}
        cancelText={"No"}
        cancelButtonProps={{ type: "link" }}
        onOk={() => {
          bulkDelete(selectedRowKeys);
          setOpenBulkDeleteConfirmation(false);
        }}
        onCancel={() => {
          setOpenBulkDeleteConfirmation(false);
        }}
      >
        <Space>
          {" "}
          <Typography.Title type="warning" level={5}>
            <ExclamationCircleFilled />
          </Typography.Title>{" "}
          <Typography.Text strong>
            Are you sure you want to remove selected Categories?
          </Typography.Text>
        </Space>
      </Modal>
    </>
  );
};

export default AddCategories;
