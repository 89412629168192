import React, { useState,useEffect, useMemo } from "react";
import moment from "moment";
import { List } from "immutable";
import { Input , Dropdown, Menu, Table, Button, Tag, Switch, Space, Typography, Tooltip } from 'antd';
import { shallowEqual } from "react-redux";
import { SearchOutlined, CloseOutlined,EllipsisOutlined} from '@ant-design/icons';
import { message, Infotip, Alert } from '../../../styleguide';
import CloneModal from '../../common/CloneModal.jsx';
import DismissErrorModal from '../../common/DismissErrorModal';
import { useDebouncedSearch } from "../../../hooks";
import { handleServerSideErrors, renderCannotUpdateRegulationError } from "./helper";
import Loading from "../../common/Loading.js";
import PropertiesDetailModal from "../../common/PropertiesDetailModal";
import { REG_TYPE_TITLE_MAP } from "../us_privacy_regulation/helper.js";
import dayjs from "dayjs";

function RegulationListGeneric(props) {
  
  //destructing props
  const {
    currentUser, 
    vendorLists ,
    vendorListError,
    sites ,
    siteGroups ,
    vendorListsPending
  } = props;

  const [isPropertyFilterOpen, setIsPropertyFilterOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [currentRegulation, setCurrentRegulation] = useState({})
  const [cloneVlModalOpen, setCloneVlModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [showActivateErrorModal, setShowActivateErrorModal] = useState(false)
  const [showPropertiesDetail, setShowPropertiesDetail] = useState(false)
  const [serverErrors, setServerErrors] = useState(List([]))
  const [selectedFilterValues, setSelectedFilterValues] = useState([])
  const [tableDataSource, setTableDataSource] = useState([])

  const indexSiteNameMap = useMemo(() => sites.toJS().reduce((map, obj) => (map[obj.id] = obj.domain, map), {}), [sites]);
  const sitesIds = useMemo(() => sites.map(s => s.id).toJS(), [sites]);
  const filterPropertyObjects = useMemo(()=>{
    const allRegulationListSiteIds = Array.from(new Set(vendorLists.toJS()?.map(acc => acc.siteIds)?.flat()));
    const allAccountLevelIds = allRegulationListSiteIds.filter(id => indexSiteNameMap[id]).map((id) => {
      return { text: indexSiteNameMap[id], value: id };
    });
    allAccountLevelIds.unshift({text: "(no property)", value: "no_property"});
    return allAccountLevelIds;
  },[vendorLists, sites])

  const readOnly = props.readOnly || !props.isAccountPartOfWave1;

  const handleCopy = (e,record) => {
    e.stopPropagation()
    setCurrentRegulation(record)
    setCloneVlModalOpen(true)
  }

  const showDeleteModal = (e,record) => {
    e.stopPropagation()
    setCurrentRegulation(record)
    setDeleteModalOpen(true);
  }

  const toggleDeactivateMessage = (record) => {
    setCurrentRegulation(record)
    setShowDeactivateModal(!showDeactivateModal);
  }

  //debouncing search for performance 
  const debouncedChangeHandler = useDebouncedSearch(setSearchText, 700);

  useEffect(()=>{
    setTableDataSource(searchText.trim().length ? vendorLists.toJS()?.filter(vl => vl.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) !== -1) : vendorLists.toJS())
  },[vendorLists, searchText])

  useEffect(() => {
    if(vendorListError){
      const serverErrors = handleServerSideErrors(vendorListError, indexSiteNameMap);
      if(serverErrors){
        setShowActivateErrorModal(true)
        setServerErrors(serverErrors)
      }
    }
    return ()=>{
      props.clearVendorListError();
    }
  },[vendorListError])

  const editMenu = (record) => {
    return(
    <Menu style={{ zIndex: 100 }}>
      <Menu.Item>
        <Button type="link" onClick={(e) => handleCopy(e,record)}>Clone</Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="link" danger onClick={(e) => showDeleteModal(e,record)}>Delete</Button>
      </Menu.Item>
    </Menu>
  )
  }

  const headingMessage = <>Are you sure you want to delete the following {props.entity} <b>{currentRegulation?.name}</b>&nbsp;?</>;
  const errorNote = <></>;
  const okText = "Delete";
  const cancelText = "Cancel";

  const headingDeactivate = (
    <>
      Are you sure you want to{" "}
      {currentRegulation?.isActive ? "deactivate" : "activate"} the following{" "}
      {props.entity} <b>{currentRegulation?.name}</b>&nbsp;?
      {currentRegulation?.isActive ? (
        <>
          <br/><br/>
          <div>By deactivating this {props.entity?.toLowerCase()}, the users' choices made in conjunction with these settings will no longer be respected.</div>
        </>
      ) : null}
    </>
  );
  const okTextDeactivate = currentRegulation?.isActive
    ? "Deactivate"
    : "Activate";

  const errorContent = (
    <React.Fragment>
      <div className="error-wrapper">
        <div className="message-block">
          {headingMessage}
          <div className="note">{errorNote}</div>
        </div>
      </div>
    </React.Fragment>
  );

  const errorContentDeactivate = (
    <React.Fragment>
      <div className="error-wrapper">
        <div className="message-block">
          {headingDeactivate}
          <div className="note">{errorNote}</div>
        </div>
      </div>
    </React.Fragment>
  );

  const errorContentRegulation = renderCannotUpdateRegulationError(currentRegulation, serverErrors, props.entity);

  const cloneVl = (newVlName) => {
    props.cloneVendorList(currentUser.accountId, currentRegulation?.id, newVlName).then((resp) => {
      if(resp){
        message.success(<>{props.entity} <b>{currentRegulation?.name}</b> has been successfully cloned</>)
      }
    })
    setCloneVlModalOpen(false)
  }

  const handleDelete = () => {
    props.deleteVendorList(currentUser?.accountId, currentRegulation?.id).then((resp) => {
    if (resp) {
      message.success(<>{props.entity} <b>{currentRegulation?.name}</b> was successfully deleted</>)
    }
    setDeleteModalOpen(false)
    });
  }


  const togglePropertiesDetail = (record) => {
    setCurrentRegulation(record)
    setShowPropertiesDetail(!showPropertiesDetail)
  }


  const handleDeactivate = () => { 
    if(currentRegulation?.siteIds.length < 1 && !currentRegulation?.isActive) {
      setShowActivateErrorModal(true);
    } else {
      props.activateDeactivateVendorList(currentRegulation).then((resp) => {
        if(resp){
          message.success(<>{props.entity} <b>{currentRegulation?.name}</b> is successfully {currentRegulation?.isActive?'deactivated':'activated'}</>)
        }
      })
    }
    setShowDeactivateModal(false) 
  }

  const handlePropertyFilter = (value) => {
    setSelectedFilterValues(value);
  }

  const columns = [
    {
      title: props.entity,
      dataIndex: "name",
      key: "name",
      align: "left",
      className: "name-column",
      shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord),
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => (
        <div className="pointer" onClick={() => props.handleEdit(record.id)}>
          {name}
        </div>
      ),
    },
    {
      title: "Last Modified",
      dataIndex: "dateUpdated",
      key: "dateUpdated",
      align: "left",
      className: "last-modified-colum",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        !a.dateUpdated && !b.dateUpdated
          ? 0
          : !a.dateUpdated
          ? -1
          : !b.dateUpdated
          ? 1
          : moment(a.dateUpdated).diff(b.dateUpdated),
      render: (dateUpdated) => dateUpdated ?  dayjs(dateUpdated).format('MMMM D, YYYY, h:mm A') : "",
      shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord),
    },
    {
      title: "Properties",
      dataIndex: "siteIds",
      key: "siteIds",
      width: 150,
      align: "right",
      filters: filterPropertyObjects,
      filterSearch: true,
      shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord),
      onFilter: (filteredSiteId, record) =>
        filteredSiteId === "no_property"
          ? record.siteIds.includes(filteredSiteId) || !record.siteIds.length
          : record.siteIds.includes(filteredSiteId),
      // filterDropdown: (props) =>
      //   isPropertyFilterOpen ? (
      //     <TableFilterDropdown
      //       {...props}
      //       selectAllOption
      //       showSearch
      //       multiple
      //       dropdownClassName="us-privacy-filter"
      //       handleSelectedKeysOnSave={handlePropertyFilter}
      //     />
      //   ) : null,
      // filterDropdownOpen: isPropertyFilterOpen,
      // onFilterDropdownOpenChange: (open) => {
      //   setIsPropertyFilterOpen(open);
      // },
      sorter: (a, b) => a.siteIds.length - b.siteIds.length,
      render: (_, record) => (
        <Tooltip
          title={"Click to view all properties"}
        >
          <Tag color="processing" className="pointer" bordered onClick={() => togglePropertiesDetail(record)}>{record?.siteIds.length}</Tag>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 150,
      align: "left",
      className: "status-column",
      shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord),
      // sorter: (a, b) => {
      //   const first = a.isActive ? 1 : 0;
      //   const second = b.isActive ? 1 : 0;
      //   return first - second;
      // },
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      render: (_, record) =>
        !readOnly && !props.calculateAccess(sitesIds, record.siteIds) ? (
          <Space size={"middle"}>
            <Switch
              onClick={(e) => toggleDeactivateMessage(record)}
              checked={record.isActive}
              size="small"
            />
            <span>{record.isActive ? "Active" : "Inactive"}</span>
          </Space>
        ) : (
          <>
            <i
              className="fas fa-circle"
              style={{color: record.isActive ? "#52C41A" : "#D9D9D9", width: 10, height: 10, marginRight: 5}}
            />
            <span>{record.isActive ? "Active" : "Inactive"}</span>
          </>
        ),
      onFilter: (value, record) => record.isActive === value,
      // filterDropdown: (props) => (
      //   <TableFilterDropdown {...props} selectAllOption />
      // ),
    },
    {
      title: "Actions",
      key: "action",
      width: 100,
      align: "center",
      shouldCellUpdate: (record, prevRecord) => !shallowEqual(record, prevRecord),
      render: (_, record) => {
        const viewOnlyAccess = props.calculateAccess(sitesIds, record.siteIds);

        return readOnly || viewOnlyAccess ? null : (
          <Dropdown
            overlay={() => editMenu(record)}
            placement="top"
          >
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];
  
  if(props.gdpr && !props.nonIab) {
    const tcfVersionColumn =
    {
      title: 'TCF Version',
      dataIndex: 'tcfVersion',
      key: 'tcfVersion',
      width: 150,
      align:'right',
      className:'tcfV-column',
      shouldCellUpdate: (record, prevRecord) => !shallowEqual(record,prevRecord),
      sorter: (a, b) => {
        return a.tcfVersion - b.tcfVersion;
      },
      filters: [
        {
          text: '2.1',
          value: 2.1,
        },
        {
          text: '2.2',
          value: 2.2,
        }
      ],
      render: (_, record) => record.tcfVersion == 2.1 ? (
        <Infotip
          className="usp-infotip count-container pointer"
          trigger="hover"
          title={null}
          content={"Update to TCF v2.2 pending. Please review the updates to transition to the latest version."}
        >
          <div>
            {record.tcfVersion}
            {record.tcfVersion == 2.1 ? <i className="fas fa-circle" style={{ color: '#FCA015' }} /> : null}
          </div>
        </Infotip>
      ) : (
          <div>
            {record.tcfVersion}
            {record.tcfVersion == 2.1 ? <i className="fas fa-circle" style={{ color: '#FCA015' }} /> : null}
          </div>
        ),
      onFilter: (value, record) => record.tcfVersion === value,
      // filterDropdown: (props) => <TableFilterDropdown {...props} selectAllOption />,
    }

    columns.splice(2, 0, tcfVersionColumn);
  }

  if (props.usnat) {
    const regulationType = {
      title: "Regulation Type",
      dataIndex: "legislation",
      width: 250,
      align: "left",
      sorter: (a, b) => { 
        let regTypeForA = a?.nonIab ? "Standard" : REG_TYPE_TITLE_MAP[a?.legislation];
        let regTypeForB = b?.nonIab ? "Standard" : REG_TYPE_TITLE_MAP[b?.legislation];
        return regTypeForA?.localeCompare(regTypeForB);
      },
      render: (legislation, record) => {
        let regType = record?.nonIab
          ? "Standard"
          : <Space size={8}>{REG_TYPE_TITLE_MAP[legislation]}<Tag color="orange">IAB</Tag></Space>;
        return <Typography.Text>{regType}</Typography.Text>;
      },
    };
    columns.splice(1, 0, regulationType);
  }

  const getVlNameError = (oldVlName, newVlName) => {
    let nameError = null;
    const duplicateName = vendorLists.map(al => al.name).find(vl => vl === newVlName.trim());
    if(newVlName.trim().length === 0){
      nameError = <>Name field can not be empty</>
    }else if(duplicateName && duplicateName !== oldVlName) {
      nameError = <>{props.entity} with name '<strong>{newVlName}</strong>' already exists</>
    }
    return nameError; 
  }

  let content;
  if (vendorListsPending || !currentUser || !sites) {
    content = <Loading />;
  } else if(vendorLists.size) {
    content = (
      <Table
        className="us-privacy-table"
        style={{marginTop:'20px'}}
        columns={columns}
        dataSource={tableDataSource}
        bordered={false}
        onChange={(pagination, filters, sorter) => {
          setSelectedFilterValues(filters.siteIds);
        }}
        pagination={false}
        scroll={{
          y: `calc(100vh - 350px)`,
        }}
      />
    )
  } else if(!readOnly){
    content = (
      <div className='no-lists-message-container'>
        <p className='title'>
          You haven’t created a Vendor List yet.
        </p>
        <p className='descr'>
          We use vendor lists to make updating easy. Any changes you make, update automatically across all Privacy Managers using that list.
        </p>
        <Button type="primary" onClick={props.handleCreate}>Get Started</Button>
      </div>
    );
  } else {
    content = (
      <div className='no-lists-message-container'>
        <p className='title'>
          There are no Vendor Lists available.
        </p>
      </div>
    );
  }

  let title = null;
  let subTitle = null;
  let viewOnlyModeAlert = [];
  if(props.usnat){
    title = <h2>U.S. Multi-State Privacy</h2>
    subTitle = <h5>By implementing these settings, websites can help ensure that they are compliant with US Privacy regulations and protect user data appropriately</h5>
    viewOnlyModeAlert = props.readOnly ? [<><b>View Only:</b> User does not has access to U.S. Multi State Regulations</>] : []
  } else if(props.gdpr) {
    title = <h2>{(props.nonIab ? "GDPR Standard - " : "GDPR TCF - ") + "Vendor Lists"}</h2>
    subTitle = <h5>{!props.nonIab ? "These settings enable you to select and curate lists of vendors that are processing user data on your properties under GDPR. They also allow you to customise settings that meet the requirements of the IAB's Transparency and Consent Framework." : null}</h5>
    viewOnlyModeAlert = props.readOnly ? [ <> <b>View Only:</b> User does not has access to GDPR Vendor Lists</>] : [];
    if(!props.isAccountPartOfWave1){
      viewOnlyModeAlert.splice(0,0, "As Sourcepoint migrates clients from TCF V2.1 to TCFV2.2 we are taking extra precautions to ensure that this goes smoothly. At present your account is not enabled for TCFV2.2 transition and as such you will not be able to perform actions on your TCF vendor lists at this time (including edit, duplicate, activate, or deactivate). Please reach out to your account manager for details of when your account will be activated and if you have any questions." )
    };
  }

  return (
        <>
        <div className="us-privacy-list-layout">
          {readOnly ? viewOnlyModeAlert.map( m => <Alert style={{marginBottom: '10px'}} type="error" message={m} showIcon />) : null}
          {title}
          {subTitle}
          <div className="us-privacy-search-add">
            <Input.Search
              placeholder={"Search for " + props.entity + " names"}
              type="search"
              onChange={({ target: { value } }) => {
                debouncedChangeHandler(value);
              }}
              style={{width:360}}
            />
            {!readOnly  ? <Button className="add-new" onClick={props.handleCreate} type="primary">+ New {props.entity}</Button> : null}
          </div>
          {content}
        </div>
      {cloneVlModalOpen && (
        <CloneModal
          type="vendor-management"
          cloneMethod={cloneVl}
          name={currentRegulation?.name}
          closeModal={() => setCloneVlModalOpen(false)}
          getNameError={getVlNameError}
          entity={props.entity}
          entityType={"Inactive"}
        />
      )}
      {deleteModalOpen && (<DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title={`Delete ${props.entity}`}
        error={errorContent}
        isModalVisible={deleteModalOpen}
        handleCancel={()=>setDeleteModalOpen(false)}
        renderOk
        okText={okText}
        cancelText={cancelText}
        handleOk={handleDelete}
        primaryAction="submit"
      />)}
      {showDeactivateModal && (<DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title= {`${currentRegulation?.isActive? "Deactivate":"Activate"} ${props.entity}`}
        error={errorContentDeactivate}
        isModalVisible={showDeactivateModal}
        handleCancel={() => toggleDeactivateMessage(null)}
        renderOk
        okText={okTextDeactivate}
        cancelText={cancelText}
        handleOk={handleDeactivate}
        primaryAction="submit"
      />)}
      {showActivateErrorModal && (<DismissErrorModal
        modalWrapperClass="activate-confirmation"
        title={`Activate ${props.entity}`}
        error={errorContentRegulation}
        isModalVisible={showActivateErrorModal}
        handleCancel={() => setShowActivateErrorModal(false)}
        renderOk={false}
        okText={"OK"}
        cancelText={"Close"}
        handleOk={() => setShowActivateErrorModal(false)}
        primaryAction="submit"
      />)}
      {showPropertiesDetail && (
        <PropertiesDetailModal
          siteIds={currentRegulation?.siteIds}
          sites={sites}
          siteGroups={siteGroups}
          showPropertiesDetailModal={showPropertiesDetail}
          setShowPropertiesDetailsModal={setShowPropertiesDetail}
          setShowActivateErrorModal={setShowPropertiesDetail}
          indexSiteMap={indexSiteNameMap}
          filteredSiteIds={selectedFilterValues}
        />
      )}
      </>
  )
}

export default RegulationListGeneric;
