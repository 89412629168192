import * as types from './action_types';
import { actionCreator } from './helpers';
import {
  getAllVendorConnectionsAjax,
  createPreferenceConfigurationAjax,
  updatePreferenceConfigurationAjax,
  getAllPreferenceConfigurationAjax,
  getPreferenceConfigurationAjax,
  deletePreferenceConfigurationAjax,
  getAllVendorsAjax,
  createVendorConnectionAjax,
  deleteVendorConnectionAjax,
  updateVendorConnectionAjax,
  clonePreferenceConfigurationAjax,
  activateDeactivatePreferenceConfigurationAjax,
  getAllLatestLegalDocumentsAjax,
  getPreferenceCampaignTypesAjax
} from '../api/preferences';
import { createPreferenceConfigurationLog, editPreferenceConfigurationLog, deletePreferenceConfigurationLog, createVendorConnectionLog, editVendorConnectionLog, deleteVendorConnectionLog } from '../api/change_log';
import { FETCHED } from '../constants';

export const getAllVendorConnections = actionCreator(
  getAllVendorConnectionsAjax,
  vendorConnectionPending,
  vendorConnectionsLoaded,
  vendorConnectionsError,
  preferencesCached.bind(null, "vendorConnections")
);

export const getAllPreferenceConfiguration = (accountId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(preferenceConfigurationPending());

      const configList = await getAllPreferenceConfigurationAjax(accountId);
      
      if (configList === FETCHED) {
        dispatch(preferencesCached('preferenceConfigurations'));
      } else {
        let sitesNameMap = getState().siteState.getIn(['sitesNameMap', 'value']);
        let updatedConfigList = configList.map((config) => {
          const siteIdsFiltered = config.propertyIds.filter(id => Boolean(sitesNameMap.get(String(id))));
          return config.set('propertyIds', siteIdsFiltered);
        });
        dispatch(allPreferenceConfigurationsLoaded(updatedConfigList));
      }
    } catch (err) {
      dispatch(preferenceConfigurationError(err));
    }
  }
}

export const getPreferenceConfiguration = (prefConfigId, accountId) =>{
  return async (dispatch, getState) => {
    dispatch(preferenceConfigurationPending());

    try {
      const config = await getPreferenceConfigurationAjax(prefConfigId, accountId);
      let sitesNameMap = getState().siteState.getIn(['sitesNameMap', 'value']);

      const siteIdsFiltered = config.propertyIds.filter(id => Boolean(sitesNameMap.get(String(id))));

      const updatedConfig = config.set('propertyIds', siteIdsFiltered);
      dispatch(preferenceConfigurationLoaded(updatedConfig));
      return updatedConfig;
    } catch (resp) {
      return dispatch(preferenceConfigurationError(resp));
    }
  }
}


export const createPreferenceConfiguration = actionCreator(
  createPreferenceConfigurationAjax,
  preferenceConfigurationSavePending,
  preferenceConfigurationCreated,
  preferenceConfigurationError,
  null,
  createPreferenceConfigurationLog
)

export const clonePreferenceConfiguration = actionCreator(
  clonePreferenceConfigurationAjax,
  preferenceConfigurationSavePending,
  preferenceConfigurationCreated,
  preferenceConfigurationError,
  null,
  createPreferenceConfigurationLog
)

export const updatePreferenceConfiguration = actionCreator(
  updatePreferenceConfigurationAjax,
  preferenceConfigurationSavePending,
  preferenceConfigurationUpdated,
  preferenceConfigurationError,
  null,
  editPreferenceConfigurationLog
)

export const activateDeactivatePreferenceConfiguration = actionCreator(
  activateDeactivatePreferenceConfigurationAjax,
  preferenceConfigurationSavePending,
  preferenceConfigurationUpdated,
  preferenceConfigurationError,
  null,
  editPreferenceConfigurationLog
)

export const deletePreferenceConfiguration = actionCreator(
  deletePreferenceConfigurationAjax,
  preferenceConfigurationPending,
  preferenceConfigurationDeleted,
  preferenceConfigurationError,
  null,
  deletePreferenceConfigurationLog
)
export const getAllVendors = actionCreator(
  getAllVendorsAjax,
  vendorsPending,
  vendorsLoaded,
  vendorsError,
  preferencesCached.bind(null, "vendors")
);

export const createVendorConnection = actionCreator(
  createVendorConnectionAjax,
  vendorConnectionSavePending,
  vendorConnectionCreated,
  vendorConnectionError,
  null,
  createVendorConnectionLog
);

export const updateVendorConnection = actionCreator(
  updateVendorConnectionAjax,
  vendorConnectionSavePending,
  vendorConnectionUpdated,
  vendorConnectionError,
  null,
  editVendorConnectionLog
);

export const deleteVendorConnection = actionCreator(
  deleteVendorConnectionAjax,
  vendorConnectionSavePending,
  vendorConnectionDeleted,
  vendorConnectionError,
  null,
  deleteVendorConnectionLog
);


export const getPreferenceCampaignTypes = actionCreator(
  getPreferenceCampaignTypesAjax,
  getPreferenceCampaignTypesPending,
  getPreferenceCampaignTypesLoaded,
  getPreferenceCampaignTypesError
)


export const getAllLatestLegalDocuments = actionCreator(
  getAllLatestLegalDocumentsAjax,
  legalDocsPending,
  latestLegalDocuments,
  legalDocsError,
)
function vendorConnectionPending(){
  return {
    type: types.VENDOR_CONNECTION_PENDING
  }
}

function vendorConnectionsLoaded(vendorConnections){
  return{
    type: types.VENDOR_CONNECTIONS_LOADED,
    vendorConnections
  }
}

function vendorConnectionsError(error){
  return {
    type: types.VENDOR_CONNECTION_ERROR,
    error
  }
}

function preferencesCached(property){
  return {
    type: types.PREFERENCES_CACHED,
    property
  }
}

function preferenceConfigurationPending(){
  return {
    type: types.PREFERENCE_CONFIGURATION_PENDING
  }
}

function allPreferenceConfigurationsLoaded(preferenceConfigurationList){
  return {
    type: types.PREFERENCE_CONFIGURATION_LIST_LOADED,
    list: preferenceConfigurationList
  }
}

function preferenceConfigurationError(error) {
  return {
    type: types.PREFERENCE_CONFIGURATION_ERROR,
    error: error
  }
}

function preferenceConfigurationLoaded(prefConfig){
  return {
    type: types.PREFERENCE_CONFIGURATION_LOADED,
    prefConfig: prefConfig
  }
}

function preferenceConfigurationSavePending(){
  return {
    type: types.PREFERENCE_CONFIGURATION_SAVE_PENDING
  }
}

function preferenceConfigurationCreated(prefConfig){
  return {
    type: types.PREFERENCE_CONFIGURATION_CREATED,
    prefConfig: prefConfig
  }
}

function preferenceConfigurationUpdated(prefConfig){
  return {
    type: types.PREFERENCE_CONFIGURATION_UPDATED,
    prefConfig: prefConfig
  }
}

function preferenceConfigurationDeleted(prefConfig){
  return {
    type: types.PREFERENCE_CONFIGURATION_DELETED,
    id: prefConfig.id
  }
}

export function clearPreferenceConfigurationError(error) {
  return {
    type: types.CLEAR_PREFERENCE_CONFIGURATION_ERROR,
    error: error
  }
}

export function clearVendorConnectionError(error){
  return {
    type: types.CLEAR_VENDOR_CONNECTION_ERROR,
    error: error
  }
}
function vendorsPending() {
  return {
    type: types.VENDORS_PENDING
  }
}

function vendorsLoaded(vendors) {
  return {
    type: types.VENDORS_LOADED,
    vendors
  }
}

function vendorsError(error) {
  return {
    type: types.VENDORS_ERROR,
    error
  }
}

function vendorConnectionSavePending() {
  return {
    type: types.VENDOR_CONNECTION_SAVE_PENDING,
  };
}

export function vendorConnectionCreated(connection) {
  return {
    type: types.VENDOR_CONNECTION_CREATED,
    connection,
  };
}

function vendorConnectionUpdated(connection) {
  return {
    type: types.VENDOR_CONNECTION_UPDATED,
    connection,
  };
}

function vendorConnectionDeleted(connection) {
  return {
    type: types.VENDOR_CONNECTION_DELETED,
    id: connection.connectionId,
  };
}

function vendorConnectionError(error) {
  return {
    type: types.VENDORS_CONNECTION_SAVE_ERROR,
    error,
  };
}

function latestLegalDocuments(latestDocs) {
  return {
    type: types.LATEST_LEGAL_DOCS_LIST,
    latestDocs
  }
}

function legalDocsPending() {
  return {
    type : types.LEGAL_DOCS_PENDING,
  }
}

function legalDocsError(error) {
  return {
    type: types.LEGAL_DOCS_ERROR
  }
}

function getPreferenceCampaignTypesPending(){
  return {
    type: types.GET_PREFERENCE_CAMPAIGN_TYPE_PENDING
  }
}

function getPreferenceCampaignTypesLoaded(typeList){
  return {
    type: types.GET_PREFERENCE_CAMPAIGN_TYPE_LOADED,
    typeList
  }
}

function getPreferenceCampaignTypesError(error){
  return {
    type: types.GET_PREFERENCE_CAMPAIGN_TYPE_ERROR,
    error:error
  }
}
