import React, { Component } from 'react';
import { Modal, Input, Radio, Button } from '../../../../../styleguide';
import { NOTICE_URL } from '../../../../../constants';

const msgBuilderMap = new Map(
  [
    ['adblock', 'notice'],
    ['ios14-ios', 'ios'],
    ['custom-custom', 'custom'],
    ['ccpa-ccpa_pm', 'ccpa_pm'],
    ['consent', 'notice'],
    ['gdpr-tcfv2-notice', 'notice'],
    ['ccpa', 'notice'],
    ['usnat_notice', 'notice'],
    ['sar', 'sar'],
    ['dsar', 'dsarRetrieval'],
    ['gdpr-tcfv2-privacy-manager', 'privacy-manager'],
    ['usnat-us_pm', 'us_pm'],
    ['usnat_notice', 'us_pm'],
    ['gdpr-tcfv2-privacy-manager-ott', 'privacy-manager-ott'],
    ['native-ott', 'native-ott'],
    ['gdpr-notice_non_iab', 'notice_non_iab'],
    ['gdpr-privacy_manager_non_iab', 'privacy_manager_non_iab'],
    ['ccpa_ott', 'ccpa_ott'],
    ['preferences', 'preferences-app']
  ]
)

class Templates extends Component {
  state = {
    term: '',
    type: 'all',
    reload: true,
    selectedTemplate: {},
  }

  selectTemplate = (message, name) => {
    Modal.confirm({
      title: 'Are you sure you want to use this template?',
      content: 'Warning: this will replace any work you have done to the current template of this notice.',
      wrapClassName: 'warning-message',
      onOk: () => {
        const messageData = message[0].siblings || message;
        messageData[0].name = name;
        if (messageData[0].settings.selected_privacy_manager){
          messageData[0].settings.selected_privacy_manager = [];
        }
        if (messageData[0].settings.vendorList){
          messageData[0].settings.vendorList = null;
        }
        this.props.setFromTemplate(messageData);
        this.props.handleCancel();
      },
    });
  }

  handleDelete = () => {
    this.props.deleteTemplate(this.state.selectedTemplate.id);
    this.setState({ showDeleteModal: false, deleteInputVal: '', selectedTemplate: {} });
  }

  reload = () => {
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  }

  updateIframe = (i, noticeJSON) => {
    const myIframe = document.getElementById('templates-preview-' + i);
    myIframe.contentWindow.postMessage(JSON.stringify({
      components: noticeJSON,
      current: [0],
      siteId: this.props.siteId,
      preferencesList: this.props.preferencesList,
    }), '*');
  }

  selectTemplateToDelete = (e, template) => {
    e.stopPropagation();
    this.setState({ showDeleteModal: true, selectedTemplate: template })
  }

  createRadioOptionsNew = () => {
    switch (this.props.messageSubcategory) {
      case 'sar':
      case 'dsarRetrieval':
        return;
      case 'notice':
        return (this.props.messageCategory === 'ccpa')
          ? (
            <Radio.Button value="ccpa">
              CCPA
            </Radio.Button>
          )
          : (
            <React.Fragment>
              <Radio.Button value="all">
                All
              </Radio.Button>
              <Radio.Button value="System">
                System
              </Radio.Button>
              <Radio.Button value="Account">
                Account
              </Radio.Button>
            </React.Fragment>
          );
    }
  }

  get allTypes() {
    switch (this.props.messageSubcategory) {
      case 'notice':
        if (this.props.messageCategory === 'ccpa') {
          return ['ccpa']
        } else if (this.props.messageCategory === 'usnat') {
          return ['usnat_notice']
        } else {
          return ['adblock', 'ios', 'custom', 'consent'];
        }
      case 'sar':
        return ['sar'];
      case 'dsarRetrieval':
        return ['dsar'];
      case 'notice-tcf-v2':
        return ['gdpr-tcfv2-notice'];
      case 'ios':
        return ['ios14-ios'];
      case 'custom':
        return ['custom-custom'];
      case 'ccpa_pm':
        return ['ccpa-ccpa_pm'];
      case 'privacy-manager':
        return ['gdpr-tcfv2-privacy-manager'];
      case 'privacy-manager-ott':
        return ['gdpr-tcfv2-privacy-manager-ott'];
      case 'notice_non_iab':
        return ['gdpr-notice_non_iab'];
      case 'privacy_manager_non_iab':
        return ['gdpr-privacy_manager_non_iab'];
      case 'ccpa_ott':
        return ['ccpa_ott'];
      case 'native-ott':
        return ['native-ott'];
      case 'native-ott-ccpa':
        return ['native-ott-ccpa'];
      case 'us_pm':
        return ['usnat-us_pm'];
      case 'usnat_notice':
        return ['usnat_notice']
      case 'preferences':
        return 'preferences'
    }
  }

  getModalTitle = (cat,subCat) => {
    switch(subCat){
      case 'notice':
      if(cat === 'gdpr'){
        return 'GDPR TCF v1';
      }else if(cat === 'usnat'){
        return 'U.S. MultiState Privacy First Layer';
      }else if(cat === 'ccpa'){
        return 'U.S. Privacy First Layer (legacy)';
      }else if(cat === 'adblock'){
        return 'Ad-block'
        break;
      }else if(cat === 'ios14'){
        return 'iOS Tracking'
        break;
      }else if(cat === 'custom'){
        return 'Custom Message'
        break;
      }
      case 'notice-tcf-v2':
        return 'GDPR TCF v2 First layer';
      case 'privacy-manager':
        if (cat === 'gdpr') return 'GDPR TCF v2 Privacy Manager';
        if (cat === 'usnat') return 'U.S. MultiState Privacy Manager';
      case 'privacy-manager-ott':
        return 'Legacy OTT';
      case 'sar':
        return 'U.S. Privacy Subject Access Request Form';
      case 'dsarRetrieval':
        return 'U.S. Privacy DSAR Retrieval Form';
      case 'notice_non_iab':
        return 'GDPR Non IAB First Layer';
      case 'privacy_manager_non_iab':
        return 'GDPR Non IAB Privacy Manager';
      case 'ios':
        return 'iOS Tracking';
      case 'custom':
        return 'Custom Message';
      case 'ccpa_pm':
        return 'U.S. Privacy Manager (legacy)';
      case 'ccpa_ott':
        return 'U.S. Privacy OTT Web';
      case 'native-ott':
        return 'OTT';
      case 'native-ott-ccpa':
        return 'OTT-U.S.Privacy';
      case 'us_pm':
        return 'U.S. MultiState Privacy Manager';
      case 'usnat_notice':
        return 'U.S. MultiState First Layer';
      case 'preferences':
        return 'Universal Consent and Preferences'
      default:
        return cat;
    }
  }

  render() {   
    const { currentUser: { account_id } } = this.props;
    const { 
      type, 
      term,
      reload,
      showDeleteModal,
    } = this.state;

    const filteredTemplates = this.props.templates.filter((t) => {
      try {
        const searchText = t.template_name.toLowerCase().includes(term.toLowerCase());
        const searchType = (type === 'all')
          ? this.allTypes.includes(t.type)
          : t.template_type === type;
        return (searchText && searchType);
      } catch (err) {
        return false;
      };
    });

    const modalTitle = this.getModalTitle(this.props.messageCategory,this.props.messageSubcategory)
    return (
      <Modal
        title={`Select from Template (${modalTitle})`}
        className="templates-modal"
        visible={this.props.visible}
        onCancel={this.props.handleCancel}
        width={800}
        footer={null}
      >
        <div className="templates-box">
          <div className="templates-options">
            <Input
              type="search"
              className="templates-search"
              size="large"
              value={term}
              onChange={(e) => this.setState({ term: e.target.value }, () => this.reload())}
              placeholder="Search templates..."
              style={{ width: '100%' }}
            />
            <div className="template-types">
              <Radio.Group
                value={type}
                onChange={e => this.setState({ type: e.target.value }, () => this.reload())}
                size="large"
              >
                <React.Fragment>
              <Radio.Button value="all">
                All
              </Radio.Button>
              <Radio.Button value="System">
                System
              </Radio.Button>
              <Radio.Button value="Account">
                Account
              </Radio.Button>
            </React.Fragment>
              </Radio.Group>
            </div>
          </div>
          {filteredTemplates.map((template, i) => {
            const jsonData = (template.template_json instanceof Array)
              ? template.template_json
              : JSON.parse(template.template_json);
            return (
              <div
                key={'template' + i}
                className="template-option"
                onClick={() => this.selectTemplate(jsonData, template.template_name)}
              >
                <div className="frame" style={{ position: 'relative' }}>
                {((!template.accountBased && account_id === 22 && this.props.isTemplate)|| (template.accountBased && this.props.isTemplate)) && (
                    <span className="delete-btn">
                      <Button
                        shape="circle"
                        size="small"
                        onClick={e => this.selectTemplateToDelete(e, template)}
                      >
                        {'\u2715' /* unicode for X symbol */}
                      </Button>
                    </span>
                  )}
                  <div className="preview">
                    <div className="cover" />
                    <div className="typeContainer">{template.template_type}</div>
                    {reload ? (
                      <iframe
                        title="privacy-manager-iframe"
                        onLoad={() => this.updateIframe(i, jsonData)}
                        id={'templates-preview-' + i}
                        src={`${NOTICE_URL}/${msgBuilderMap.get(template.type)}/index.html?type=mini${this.props.messageSubcategory==='usnat_notice'?'&is_usnat_notice=true':''}`}
                      />
                    ) : null}
                  </div>
                  <div className="name">
                    {template.template_name}&nbsp;{(type === 'all' && this.allTypes.length > 1) && `(${template.type.toUpperCase()})`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Modal
          title="Delete Template"
          visible={showDeleteModal}
          onOk={this.props.deleteTemplate}
          onCancel={() => 
          this.setState({ showDeleteModal: false, 
          deleteInputVal: '', selectedTemplate: {}  })}
          footer={[(
            <Button onClick={() => this.setState({ showDeleteModal: false, deleteInputVal: '', selectedTemplate: {}  })}>
              Cancel
            </Button>
          ),
          (
            <Button
              type="Primary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
          )
          ]}
        >
          Are you sure you want to delete the selected template?
        </Modal>
      </Modal>
    );
  }
}

export default Templates
